import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { Card } from '@qwealth/qcore';
import { TitlePrimary } from 'components/common/Title';

import InconsistencyItem from './InconsistencyItem';

import { ProposalInfoSelectors } from 'data/selectors';

const LossAversionCertainty: FC = (): ReactElement => {
  const isLoading = useSelector(ProposalInfoSelectors.isGettingInconsistencies);
  const data = useSelector(ProposalInfoSelectors.inconsistencies);
  const owners = useSelector(ProposalInfoSelectors.owners);

  return (
    <Card loading={isLoading} display="flex" flexDirection="column" gap="default">
      <TitlePrimary>Loss Aversion & Certainty</TitlePrimary>

      {owners.map(o => {
        const clientQbias = data?.clientQbias[o.QID];
        return (
          <InconsistencyItem consistent={clientQbias?.consistent} header={o.name} bodyText={clientQbias?.text ?? ''} />
        );
      })}
    </Card>
  );
};

export default LossAversionCertainty;
