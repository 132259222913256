import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import { lighten } from 'polished';

import { Box, Button, styled, Modal } from '@qwealth/qcore';

import { getLabel } from 'data/translations/defaultLabels';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */

export const TitlePrimary = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: -5px;
`;

const StyledButton = styled(Button)`
  background-color: ${COLORS.primary};
  &:before {
    background-color: ${lighten(0.05, COLORS.primary)};
  }
`;

const StyledCancelButton = styled(Button)`
  background-color: ${COLORS.secondaryButtonBackground};
  border: none;
  color: ${COLORS.secondaryButton};
  &:before {
    background-color: ${lighten(0.05, COLORS.primary)};
  }
`;

type Props = {
  title: string;
  body: string;
  showConfirmation: boolean;
  setShowConfirmation: Dispatch<SetStateAction<boolean>>;
  confirmationAction?: () => void;
};

const Confirmation: React.FC<Props> = ({
  title,
  body,
  showConfirmation,
  setShowConfirmation,
  confirmationAction,
}): ReactElement => {
  const onClose = () => setShowConfirmation(false);

  const onConfirm = () => {
    confirmationAction && confirmationAction();
    onClose();
  };

  return (
    <Modal title={title} onClose={onClose} show={showConfirmation}>
      <Modal.Footer>
        <Modal.Body>
          <p>{body}</p>
        </Modal.Body>
        <Box flexDirection="row" display="flex" gap="large">
          <StyledButton onClick={onConfirm}>{getLabel('common.yes')}</StyledButton>
          <StyledCancelButton variant="outline" onClick={onClose}>
            {getLabel('common.no')}
          </StyledCancelButton>
        </Box>
      </Modal.Footer>
    </Modal>
  );
};

export default Confirmation;
