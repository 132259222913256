import axios from 'axios';
import { Dispatch } from 'redux';
import { apiUrl } from '@qwealth/qdata';
import { responseListMapper, responseEntityMapper } from '@qwealth/qcore';
import {
  EntitySummary,
  EntityAccount,
  EntityInconsistencies,
  ProposalOwner,
  ProposalAccount,
  EntityMandate,
  EntityAssetRange,
  ModelAccountParam,
  AccountSecurityParam,
} from '../types';
import { AggregatedAnalyticsData } from 'components/QFolioReport/chunks/interfaces/AggregatedAnalyticsData';
import { errorHandler } from '../../services/axiosService';

export const loadProposalSummary = async (householdId: string) =>
  await axios
    .get(`${apiUrl}/qfolio/householdAggregate/${householdId}`)
    .then(response => responseEntityMapper<EntitySummary>(response));

export const loadProposalOwners = async (rtqId: string) =>
  await axios
    .get(`${apiUrl}/qfolio/ownersInformation/${rtqId}`)
    .then(response => responseListMapper<ProposalOwner>(response));

export const loadProposalAccounts = async (rtqId: string) =>
  await axios
    .get(`${apiUrl}/qfolio/accountsUnderRtq/${rtqId}`)
    .then(response => responseListMapper<EntityAccount>(response));

export const loadProposalMandate = async (rtqId: string) =>
  await axios
    .get(`${apiUrl}/qfolio/mandateInformation/${rtqId}`)
    .then(response => responseEntityMapper<EntityMandate>(response));

export const loadProposalInconsistencies = async (rtqId: string) =>
  await axios
    .get(`${apiUrl}/qfolio/inconsistencies/${rtqId}`)
    .then(response => responseEntityMapper<EntityInconsistencies>(response));
  
export const loadProposalAssetRanges = async (rtqId: string) =>
  await axios
    .get(`${apiUrl}/qfolio/assetRanges/${rtqId}`)
    .then(response => responseListMapper<EntityAssetRange>(response));

export const saveProposalModels = async (rtqId: string, models: ModelAccountParam[]) =>
  await axios
    .post(`${apiUrl}/qfolio/rtqModelAccountAllocation/${rtqId}`, models)
    .then(response => responseListMapper<ProposalAccount>(response));

export const saveProposalAllocations = async (models: AccountSecurityParam[]) =>
  await axios
    .put(`${apiUrl}/qfolio/modelsAccountsSecurities`, models)
    .then(response => responseListMapper<ProposalAccount>(response));

export const loadPortfolioAnalytics = async (
  payload: Array<any>,
  dispatch: Dispatch,
): Promise<AggregatedAnalyticsData | undefined> => {
  try {
    return await axios
      .post(`${apiUrl}/portfolio/analytics/`, JSON.stringify(payload), {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => responseEntityMapper<AggregatedAnalyticsData>(response));
  } catch (e) {
    errorHandler(dispatch)(e);
    return undefined;
  }
};
