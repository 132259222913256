import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';

const styles = StyleSheet.create({
  Title: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    fontWeight: 'bold',
  },
  Description: {
    fontSize: 12,
    marginTop: 20,
  },
  HeaderCell: {
    display: 'flex',
    flexDirection: 'row',
    height: 35,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    flex: 1,
  },
  ValueCell: {
    fontSize: 10,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    flex: 1,
  },
});

const generateChartData = (
  labels: string[],
  legend1: string,
  legend2: string,
  dataSet1: number[],
  dataSet2: number[],
) => ({
  labels,
  datasets: [
    {
      label: legend1,
      data: dataSet1,
      fill: false,
      borderColor: 'rgb(75, 192, 192)',
      tension: 0.1,
    },
    {
      label: legend2,
      data: dataSet2,
      fill: false,
      borderColor: COLORS.PRIMARY_LIGHT,
      tension: 0.1,
    },
  ],
});

const crateChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets'],
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets,
  },
});

interface Props {
  data?: object;
}

const ReportPerformance = ({ data }: Props) => {
  const chartData =
    data &&
    generateChartData(
      // @ts-ignore
      Object.keys(data?.benchmark.global),
      // @ts-ignore
      data?.benchmark.globalName,
      'Recommended Portfolio',
      // @ts-ignore
      Object.values(data?.benchmark.global),
      // @ts-ignore
      Object.values(data?.portfolio),
    );

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>PERFORMANCE</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View
        style={[
          commonStyles.Content,
          { flex: 1, paddingHorizontal: 60, justifyContent: 'space-between' },
        ]}
      >
        <Text style={styles.Title}>Benchmark Comparison</Text>
        <Text style={styles.Description}>
          Based on the proposed composition of your portfolio, here is how it would have performed
          in comparison to the benchmark it most resembles based on asset class and geographic
          distribution.
        </Text>
        <View style={{ marginTop: 30, height: 300 }}>
          {/* @ts-ignore */}
          {useChartJsAsImage(crateChartJsConfig('line', chartData?.labels, chartData?.datasets), {
            plugins: {
              legend: {
                labels: {
                  font: {
                    size: 24,
                  },
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  font: {
                    size: 24,
                  },
                },
              },
              y: {
                ticks: {
                  font: {
                    size: 24,
                  },
                },
              },
            },
          })}
        </View>
      </View>
    </Page>
  );
};

export default ReportPerformance;
