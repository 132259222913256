import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Box } from '@qwealth/qcore';

import { ReportSummary } from 'components/QFolioReport/Summary';
import HeaderSecondary from 'components/common/HeaderSecondary';

import { loadHouseholdMetadata } from 'data/actions/creators/householdMetadata';
import { selectHouseholdMetadata } from 'data/selectors/householdSelectors';
import { loadSummary } from 'data/actions/creators/proposalInfo';

const QfolioReport: React.FC = () => {
  const dispatch = useDispatch();
  const householdMetadata = useSelector(selectHouseholdMetadata);

  const { householdQID } = useParams<{ householdQID: string }>();

  useEffect(() => {
    if (householdQID) {
      dispatch(loadHouseholdMetadata(householdQID));
      dispatch(loadSummary(householdQID));
    }
  }, [dispatch, householdQID]);

  return (
    <Box flex="1" display="flex" flexDirection="column">
      <HeaderSecondary>
        <Box display="flex" alignItems="center">
          <Box as="span" fontWeight="normal">
            QFolio Proposal Summary
          </Box>
        </Box>
      </HeaderSecondary>
      {!householdMetadata.loading && householdMetadata.QID && <ReportSummary />}
    </Box>
  );
};

export default QfolioReport;
