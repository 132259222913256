import React, { ReactElement, useCallback } from 'react';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { Tooltip } from 'react-tooltip';

import { Box, Card, styled } from '@qwealth/qcore';
import Title from 'components/common/Title';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const StyledIcon = styled(Box)`
  background-color: ${COLORS.primaryBackgroundLight};
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleSecondary = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0.75rem;
`;

type Props = {
  identifier: string;
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  value?: string;
  value1?: string;
  value2?: string;
  imageSrc: string;
};

const MandateItem: React.FC<Props> = ({
  identifier,
  title,
  subtitle1,
  subtitle2,
  value,
  value1,
  value2,
  imageSrc,
}): ReactElement => {
  const getSubSection = useCallback(() => {
    return (
      subtitle1 &&
      subtitle1 && (
        <Box display="flex" flexDirection="row" marginTop="small" marginLeft="large">
          <Box flex={1}>
            <Title>{subtitle1}</Title>
            <TitleSecondary>{value1}</TitleSecondary>
          </Box>

          <Box flex={1}>
            <Title>{subtitle2}</Title>
            <TitleSecondary>{value2}</TitleSecondary>
          </Box>
        </Box>
      )
    );
  }, [subtitle1, subtitle2, value1, value2]);
  
  const dataTip = [
    'Your risk ability is your financial ability to endure portfolio volatility,',
    'and your risk willingness is your personal ability to take on risk.',
    'Your overall risk is the lower of these two values.',
  ].join('<br/>');

  return (
    <Card
      key={title}
      loading={false}
      display="flex"
      flexDirection="column"
      gap="default"
      paddingBottom="small"
      marginBottom="large"
    >
      <Box display="flex" flexDirection="row">
        <StyledIcon>
          <img src={`/images/${imageSrc}.png`} />
        </StyledIcon>
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box display="flex" flexDirection="row">
            <Title>{title}</Title>
            {identifier === 'risk' && 
              <Box data-tooltip-html={dataTip} data-tooltip-id="riskTooltip" paddingLeft="small" >
                <BsExclamationCircleFill style={{marginTop: -12}} size="16" color={COLORS.primary} />
                <Tooltip id="riskTooltip" />
              </Box>
            }
          </Box>
          {value && <TitleSecondary>{value}</TitleSecondary>}
        </Box>
      </Box>
      {getSubSection()}
    </Card>
  );
};

export default MandateItem;
