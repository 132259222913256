import React, { FC, ReactElement } from 'react';

import { Box, styled } from '@qwealth/qcore';

import Title from 'components/common/Title';
import { TitlePrimary } from 'components/common/Title';

import { getLabel } from 'data/translations/defaultLabels';
import { ProposalRtq } from 'data/types';
import { COLORS } from 'theme/colors';

const Avatar = styled.img`
  vertical-align: middle;
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
  border-radius: 50%;
`;

const StyledIcon = styled(Box)`
  background-color: ${COLORS.primaryBackgroundLight};
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleSecondary = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0.75rem;
`;

type Props = {
  idx: number;
  rtq: ProposalRtq;
};

const ProposalOverview: FC<Props> = ({ idx, rtq }): ReactElement => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
      <Box flex={1} marginLeft={'largest'}>
        <TitlePrimary>
          {getLabel('common.rtq')} {idx + 1}
        </TitlePrimary>
      </Box>

      <Box
        flex={5}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        marginRight="large"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="flex" flexDirection="row">
            <StyledIcon>
              <img src={'/images/mandate.png'} />
            </StyledIcon>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Title>{getLabel('common.mandate')}</Title>
              {rtq.mandate && <TitleSecondary>{rtq.mandate.mandate}</TitleSecondary>}
            </Box>
          </Box>
        </Box>
        {rtq.owners.map((owner, index) => (
          <Box key={index} marginLeft={'largest'}>
            <Avatar src={'/images/profileAvatar.svg'} />
            <TitleSecondary>{owner.name}</TitleSecondary>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ProposalOverview;
