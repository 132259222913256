import React, { useMemo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { ScoreTuple, Ticker } from '../interfaces/AggregatedAnalyticsData';
import { getScoreValueLabel } from '../utils';

const styles = StyleSheet.create({
  Title: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    fontWeight: 'bold',
  },
  Description: {
    fontSize: 10,
    marginTop: 20,
    lineHeight: '150%',
  },
  HeaderCell: {
    display: 'flex',
    height: 30,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 8,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'flex-end',
    fontWeight: 'bold',
    flex: 1,
    paddingHorizontal: 5,
  },
  ValueCell: {
    fontSize: 8,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 25,
    flex: 1,
    paddingHorizontal: 5,
  },
  Box: {
    backgroundColor: COLORS.GRAY1,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    width: '24%',
  },
  Score: {
    fontSize: 20,
    fontWeight: 'bold',
    color: COLORS.PRIMARY,
  },
  Label: {
    fontSize: 10,
    color: '#666',
    marginTop: 3,
  },
  FeesDate: {
    color: '#666',
    fontSize: 12,
  },
});

interface Props {
  score?: ScoreTuple;
  tickersValid?: object;
  tickersOriginal?: Array<Ticker>;
  portMER?: number;
}

const ReportFeesExpenses = ({ score, tickersValid, tickersOriginal, portMER }: Props) => {
  const { scoreValue, scoreLabel } = useMemo(() => getScoreValueLabel(score), [score]);

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>FEES/EXPENSES</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={[commonStyles.Content, { paddingHorizontal: 60 }]}>
        <View style={[commonStyles.Row, { justifyContent: 'space-between' }]}>
          <Text style={styles.Title}>
            {`Fees: ${scoreValue} `}
            <Text style={commonStyles.Score}>{scoreLabel}</Text>
          </Text>
        </View>
        <Text style={styles.Description}>
          In this segment, we show you your portfolio’s average Management Expense Ratio (MER), MER
          is a fee charged by ETFs and mutual funds. Depending on the series (e.g., A), it may
          include a trailing commission that compensates your advisor. Note that any additional
          advisory or trading fees you may incur are not included. MER is expressed as a percentage
          of your fund value.
        </Text>

        <Text style={[styles.Title, { fontSize: 12, marginTop: 20 }]}>Fees in This Portfolio</Text>

        <View style={[commonStyles.Row, commonStyles.Width100, { marginTop: 5 }]}>
          <View style={[styles.HeaderCell, { alignItems: 'flex-start', flex: 3 }]}>
            <Text>Asset</Text>
          </View>

          <View style={[styles.HeaderCell, { alignItems: 'center' }]}>
            <Text>Weight</Text>
          </View>

          <View style={[styles.HeaderCell, { alignItems: 'center' }]}>
            <Text>MER</Text>
          </View>
        </View>

        {tickersValid &&
          Object.keys(tickersValid).map((asset, index) => (
            <View
              key={`score-${index}`}
              style={[commonStyles.Row, commonStyles.Width100, { backgroundColor: COLORS.WHITE }]}
            >
              <View style={[styles.ValueCell, { flex: 3, justifyContent: 'flex-start' }]}>
                {/*@ts-ignore*/}
                <Text>{tickersValid[asset]['name']}</Text>
              </View>
              <View style={[styles.ValueCell, { flex: 1, justifyContent: 'center' }]}>
                {/*@ts-ignore*/}
                <Text>{(tickersValid[asset]['weight'] * 100).toFixed(2)}%</Text>
              </View>
              <View style={[styles.ValueCell, { flex: 1, justifyContent: 'center' }]}>
                {/*@ts-ignore*/}
                <Text>{(tickersOriginal[index].mer * 100).toFixed(2)}%</Text>
              </View>
            </View>
          ))}

        <View
          style={[
            commonStyles.Row,
            commonStyles.Width100,
            { marginTop: 20, justifyContent: 'center' },
          ]}
        >
          <View style={[styles.Box, { backgroundColor: COLORS.PRIMARY_LIGHT }]}>
            <View style={[styles.Score, { color: COLORS.WHITE }]}>
              <Text>{(portMER! * 100).toFixed(2)}%</Text>
            </View>
            <View style={[styles.Label, { color: COLORS.WHITE, fontWeight: 'semibold' }]}>
              <Text>WEIGHTED AVERAGE MER</Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ReportFeesExpenses;
