import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BsPlus, BsExclamationCircleFill } from 'react-icons/bs';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

import { Box, styled, Button, toPercent, toCurrency } from '@qwealth/qcore';

import AccountItemSummary from './AccountItemSummary';
import AccountItemRecord from './AccountItemRecord';

import { AccountSecurityRecord, ModelSecurityMappedRecord, ProposalAccount } from 'data/types';
import { addProposalAccountSecurity } from 'data/actions/creators/proposalInfo';
import { getLabel } from 'data/translations/defaultLabels';

import { COLORS } from 'theme/colors';

Chart.register([ArcElement, Tooltip, Legend]);

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const SecondaryButton = styled(Button)`
  min-width: 50px;
  color: ${COLORS.primary};
  background-color: transparent;
  &:before {
    background-color: ${COLORS.primaryBackground};
  }

  &:hover:not([disabled]) {
    color: ${COLORS.primary};
  }
`;

const PrimaryContainer = styled(Box)`
  background-color: ${COLORS.primaryBackgroundLight};
  margin-bottom: 10px;
  width: 100%;
  border-radius: 3px;
  padding: 1rem 1rem;
  font-size: 16px;
  font-weight: 600;
`;

const HeaderContainer = styled(Box)`
  background-color: ${COLORS.primaryBackgroundLight};
  color: ${COLORS.primary};
  margin-bottom: 10px;
  border-radius: 10px;
`;

const Header = styled(Box)`
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  word-break: break-word;
  padding: 1rem;
  text-transform: uppercase;
`;

const ExceptionContainer = styled(Box)`
  background-color: ${COLORS.secondaryButtonBackground};
  border-radius: 10px;
`;

const ExceptionRecord = styled(Box)`
  font-size: 16px !important;
  word-break: break-word;
  padding: 0.5rem 1rem;
`;

const RecordHighlightContainer = styled(Box)`
  background-color: ${COLORS.background};
`;

type Props = {
  modelSecurities: ModelSecurityMappedRecord[];
  account: ProposalAccount;
  displayPercent?: boolean;
  modelTotal: number;
};

const AccountItem: React.FC<Props> = ({
  modelSecurities,
  account,
  displayPercent,
  modelTotal,
}): ReactElement => {
  const dispatch = useDispatch();
  const [allocated, setAllocated] = useState(0);
  const [donutData, setDonutData] = useState<number[]>([]);
  const [securities, setSecurities] = useState<string[]>([]);
  const [securitiesMapped, setSecuritiesMapped] = useState<AccountSecurityRecord[]>([]);

  useEffect(() => {
    if (account.securities) {
      setSecuritiesMapped(
        account.securities.map(security => {
          return {
            ...security,
            allocated: security.allocatedPercent * account.value,
          };
        }),
      );
    } else {
      setSecuritiesMapped([]);
    }
  }, [account.securities]);

  useEffect(() => {
    setAllocated(securitiesMapped.reduce((n, { allocated }) => n + (allocated ?? 0), 0));
  }, [securitiesMapped]);

  useEffect(() => {
    setSecurities(['', ...modelSecurities.map(modelSecurity => modelSecurity.ticker)]);
  }, [modelSecurities]);

  useEffect(() => {
    const securityPercentOrDollar = displayPercent ? account.securityAllocated / account.value : account.securityAllocated;
    const allocatedPercentOrDollar = displayPercent ? allocated / account.value : allocated;
    const remainingPercentOrDollar = displayPercent
      ? (account.value - allocated - account.securityAllocated) / account.value
      : account.value - allocated - account.securityAllocated;

    setDonutData([allocatedPercentOrDollar, remainingPercentOrDollar, securityPercentOrDollar]);
  }, [account.value, modelTotal, allocated, displayPercent, account.securityAllocated]);

  const getRowRecord = useCallback(
    (security, index) => {
      if (index % 2) {
        return (
          <AccountItemRecord
            key={security.ticker}
            security={security}
            securities={securities}
            displayPercent={displayPercent}
            account={account}
          />
        );
      } else {
        return (
          <RecordHighlightContainer>
            <AccountItemRecord
              key={security.ticker}
              security={security}
              securities={securities}
              displayPercent={displayPercent}
              account={account}
            />
          </RecordHighlightContainer>
        );
      }
    },
    [displayPercent, securities],
  );

  const exceptionTransform = displayPercent ? toPercent : toCurrency;

  const getExceptionValue = useCallback(({allocatedPercent, allocated}) => {
    return displayPercent ? allocatedPercent : allocated;
  }, [displayPercent]);

  return (
    <Box
      display="grid"
      gap="large"
      style={{ paddingTop: '2rem' }}
      gridTemplateColumns={{
        desktop: '1.5fr 1fr',
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box flex={1} marginRight="small">
          <PrimaryContainer>{`${account.name} ${account.type}`}</PrimaryContainer>
          <Box marginY="large">
            <Doughnut
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: true,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (tti) {
                        return ` ${tti.label}: ${
                          displayPercent ? toPercent(tti.parsed) : toCurrency(tti.parsed)
                        }`;
                      },
                    },
                  },
                },
              }}
              data={{
                labels: ['Allocated', 'Remaining', 'Security Exception'],
                datasets: [
                  {
                    label: 'Account allocation',
                    data: donutData,
                    backgroundColor: [
                      'rgb(255, 205, 86)',
                      'rgb(255, 99, 132)',
                      'rgb(202, 212, 226)',
                    ],
                    hoverOffset: 4,
                  },
                ],
              }}
            />
          </Box>
        </Box>
        <Box flex={2}>
          <Box>
            <HeaderContainer display="flex" flexDirection="row">
              <Header flex={3}>{getLabel('proposal.allocation.symbol.header')}</Header>
              <Header flex={3}>
                {getLabel('proposal.allocation.allocation.header')} {displayPercent ? '%' : '$'}
              </Header>
              <Header flex={1}></Header>
            </HeaderContainer>
            {securitiesMapped.map((security, index) => getRowRecord(security, index))}

            {account.securityExceptions.map(s => {
              const exceptionValue = getExceptionValue(s);
              return <ExceptionContainer display='flex' flexDirection='row'>
                <ExceptionRecord textAlign='left' flex={3}>{s.ticker}</ExceptionRecord>
                <ExceptionRecord textAlign='right' flex={3} >{exceptionValue ? exceptionTransform(exceptionValue) : '-'}</ExceptionRecord>
                <ExceptionRecord textAlign='right' display="flex" justifyContent="flex-end" flex={2}>
                  <BsExclamationCircleFill size="24" color={COLORS.red} />
                </ExceptionRecord>
              </ExceptionContainer>
            })}
            
            <SecondaryButton
              variant="secondary"
              justifyContent="flex-start"
              onClick={() => {
                dispatch(
                  addProposalAccountSecurity({
                    accountNumber: account.accountNumber,
                  }),
                );
              }}
            >
              <BsPlus size="24" color={COLORS.primary} />
              <Box paddingLeft="small" paddingRight="small">
                {getLabel('proposal.allocation.add.security.button')}
              </Box>
            </SecondaryButton>
          </Box>
        </Box>
      </Box>

      <AccountItemSummary
        securityAllocated={account.securityAllocated}
        total={account.value}
        accountValue={account.value}
        allocated={allocated}
        displayPercent={displayPercent}
      />
    </Box>
  );
};

export default AccountItem;
