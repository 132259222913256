import React, { useMemo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { ScoreTuple } from '../interfaces/AggregatedAnalyticsData';
import { getScoreValueLabel } from '../utils';

const styles = StyleSheet.create({
  Title: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    fontWeight: 'bold',
  },
  Description: {
    fontSize: 10,
    marginTop: 20,
    lineHeight: '150%',
  },
  HeaderCell: {
    display: 'flex',
    height: 35,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 8,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'flex-end',
    fontWeight: 'bold',
    flex: 1,
    paddingHorizontal: 5,
  },
  ValueCell: {
    fontSize: 8,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    flex: 1,
    paddingHorizontal: 5,
  },
  Score: {
    fontSize: 20,
    fontWeight: 'bold',
    color: COLORS.PRIMARY,
  },
  Box: {
    backgroundColor: COLORS.GRAY1,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    width: '24%',
  },
  Label: {
    fontSize: 10,
    color: '#666',
  },
});

interface Props {
  score?: ScoreTuple;
  tickersOriginal?: Array<{
    name?: string;
    weight?: number;
    dist_yield?: number;
    ticker?: string;
  }>;
  portYield?: number;
}

const ReportFixedIncomeStyle = ({ score, tickersOriginal, portYield }: Props) => {
  const { scoreValue, scoreLabel } = useMemo(() => getScoreValueLabel(score), [score]);

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>INCOME</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={[commonStyles.Content, { paddingHorizontal: 60 }]}>
        <Text style={styles.Title}>
          {`Income Distribution: ${scoreValue} `}
          <Text style={commonStyles.Score}>{scoreLabel}</Text>
        </Text>
        <Text style={styles.Description}>
          Income refers to cash dividends from a stock, or cash distributions from an ETF or a
          mutual fund. Whether you desire a high income score depends on your saving objective. If
          you are investing for the long term, or if you are not dependent on your portfolio for
          everyday expenses, a high income portfolio is not necessary
        </Text>

        <View style={[commonStyles.Row, commonStyles.Width100, { marginTop: 20 }]}>
          <View style={{ flex: 1 }}>
            <View style={[commonStyles.Row, commonStyles.Width100]}>
              <View style={[styles.HeaderCell, { alignItems: 'flex-start', flex: 2 }]}>
                <Text>Asset</Text>
              </View>
              <View style={[styles.HeaderCell, { alignItems: 'flex-start', flex: 1 }]}>
                <Text>Symbol</Text>
              </View>
              <View style={[styles.HeaderCell, { alignItems: 'flex-end' }]}>
                <Text>Weight</Text>
              </View>
              <View style={[styles.HeaderCell, { alignItems: 'flex-end' }]}>
                <Text>Yield</Text>
              </View>
            </View>
            {tickersOriginal?.map(ticker => (
              <View
                key={ticker.name}
                style={[commonStyles.Row, commonStyles.Width100, { paddingHorizontal: 5 }]}
              >
                <View style={[styles.ValueCell, { flex: 2, justifyContent: 'flex-start' }]}>
                  <Text>{ticker.name!}</Text>
                </View>
                <View style={[styles.ValueCell, { flex: 1, justifyContent: 'flex-start' }]}>
                  <Text>{ticker.ticker!}</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  <Text>{(ticker.weight! * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  <Text>{(ticker.dist_yield! * 100).toFixed(2)}%</Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <View
          style={[
            commonStyles.Row,
            commonStyles.Width100,
            { marginTop: 20, justifyContent: 'center' },
          ]}
        >
          <View style={[styles.Box, { backgroundColor: COLORS.PRIMARY_LIGHT }]}>
            <View style={[styles.Score, { color: COLORS.WHITE }]}>
              {/* @ts-ignore */}
              <Text>{(portYield * 100).toFixed(2)}%</Text>
            </View>
            <View style={[styles.Label, { color: COLORS.WHITE, fontWeight: 'semibold' }]}>
              <Text>WEIGHTED AVERAGE YIELD</Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ReportFixedIncomeStyle;
