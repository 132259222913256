import React, { ReactElement } from 'react';
import { useParams } from 'react-router';
import { Box, useFetchOnMount } from '@qwealth/qcore';
import { getDigitExportData } from '../../data/data-layer/digitExport';

const DigitExport = (): ReactElement => {
  const { householdQID } = useParams<{ householdQID: string }>();

  const digitData = useFetchOnMount(getDigitExportData, [])(householdQID);

  return (
    <Box>
      test {JSON.stringify(digitData)}
      {householdQID}
    </Box>
  );
};

export default DigitExport;
