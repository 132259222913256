import { errorHandler } from '../../../services/axiosService';

import {
  GET_SECURITY_EXCPETION_REQUEST,
  GET_SECURITY_EXCPETION_SUCCESS,
  GET_SECURITY_EXCPETION_FAILURE,
  CREATE_SECURITY_EXCPETION_REQUEST,
  CREATE_SECURITY_EXCPETION_SUCCESS,
  CREATE_SECURITY_EXCPETION_FAILURE,
  UPDATE_SECURITY_EXCPETION_REQUEST,
  UPDATE_SECURITY_EXCPETION_SUCCESS,
  UPDATE_SECURITY_EXCPETION_FAILURE,
  DELETE_SECURITY_EXCPETION_REQUEST,
  DELETE_SECURITY_EXCPETION_SUCCESS,
  DELETE_SECURITY_EXCPETION_FAILURE,
} from '../types';

import {
  getSecurityExceptionCall,
  createSecurityExceptionCall,
  updateSecurityExceptionCall,
  deleteSecurityExceptionCall,
} from '../../data-layer';
import { loadAccounts } from './proposalInfo';
import { BaseEntitySecurityException } from 'data/types';

const getSecurityExceptionRequest = () => ({ type: GET_SECURITY_EXCPETION_REQUEST });
const getSecurityExceptionSuccess = data => ({
  type: GET_SECURITY_EXCPETION_SUCCESS,
  payload: data,
});
const getSecurityExceptionFailure = () => ({ type: GET_SECURITY_EXCPETION_FAILURE });

const createSecurityExceptionRequest = () => ({ type: CREATE_SECURITY_EXCPETION_REQUEST });
const createSecurityExceptionSuccess = data => ({
  type: CREATE_SECURITY_EXCPETION_SUCCESS,
  payload: data,
});
const createSecurityExceptionFailure = () => ({ type: CREATE_SECURITY_EXCPETION_FAILURE });

const updateSecurityExceptionRequest = () => ({ type: UPDATE_SECURITY_EXCPETION_REQUEST });
const updateSecurityExceptionSuccess = data => ({
  type: UPDATE_SECURITY_EXCPETION_SUCCESS,
  payload: data,
});
const updateSecurityExceptionFailure = () => ({ type: UPDATE_SECURITY_EXCPETION_FAILURE });

const deleteSecurityExceptionRequest = () => ({ type: DELETE_SECURITY_EXCPETION_REQUEST });
const deleteSecurityExceptionSuccess = () => ({ type: DELETE_SECURITY_EXCPETION_SUCCESS });
const deleteSecurityExceptionFailure = () => ({ type: DELETE_SECURITY_EXCPETION_FAILURE });

export const loadSecurityException = securityExceptionId => async dispatch => {
  try {
    dispatch(getSecurityExceptionRequest());
    const data = await getSecurityExceptionCall(securityExceptionId);
    if (data) {
      dispatch(getSecurityExceptionSuccess(data));
    } else {
      dispatch(getSecurityExceptionFailure());
    }
  } catch (e) {
    dispatch(updateSecurityExceptionFailure());
    errorHandler(dispatch, 'Fetching security exception failed')(e);
  }
};

export const createSecurityException = (exception: BaseEntitySecurityException) => async dispatch => {
  try {
    dispatch(createSecurityExceptionRequest());
    const data = await createSecurityExceptionCall(exception);
    if (data) {
      dispatch(createSecurityExceptionSuccess(data));
      dispatch(loadAccounts());
    } else {
      dispatch(createSecurityExceptionFailure());
    }
  } catch (e) {
    dispatch(createSecurityExceptionFailure());
    errorHandler(dispatch, 'Creating security exception failed')(e);
  }
};

export const updateSecurityException = (exception: BaseEntitySecurityException) => async dispatch => {
  try {
    dispatch(updateSecurityExceptionRequest());
    const data = await updateSecurityExceptionCall(exception);
    if (data) {
      dispatch(updateSecurityExceptionSuccess(data));
      dispatch(loadAccounts());
    } else {
      dispatch(updateSecurityExceptionFailure());
    }
  } catch (e) {
    dispatch(updateSecurityExceptionFailure());
    errorHandler(dispatch, 'Updating security exception failed')(e);
  }
};

export const deleteSecurityException = securityExceptionId => async dispatch => {
  try {
    dispatch(deleteSecurityExceptionRequest());
    
    await deleteSecurityExceptionCall(securityExceptionId);
    dispatch(deleteSecurityExceptionSuccess());
    dispatch(loadAccounts());
  } catch (e) {
    dispatch(deleteSecurityExceptionFailure());
    errorHandler(dispatch, 'Deleting security exception failed')(e);
  }
};
