import React, { ReactElement, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Card } from '@qwealth/qcore';
import MandateItem from './MandateItem';

import { ProposalInfoSelectors } from '../../../data/selectors';
import { getLabel } from 'data/translations/defaultLabels';

const recordsBaselineTop = [
  {
    key: 'mandate',
    title: getLabel('common.mandate'),
    imageSrc: 'mandate',
    subtitle1: '',
    subtitle2: '',
  },
  {
    key: 'objective',
    title: getLabel('mandate.objective'),
    imageSrc: 'objective',
    subtitle1: 'Primary',
    subtitle2: 'Secondary',
  },
  {
    key: 'risk',
    title: getLabel('mandate.risk'),
    imageSrc: 'risk',
    subtitle1: 'Ability',
    subtitle2: 'Willingness',
  },
  {
    key: 'timeHorizon',
    title: getLabel('mandate.time.horizon'),
    imageSrc: 'timeHorizon',
    subtitle1: '',
    subtitle2: '',
  },
];

const recordsBaselineBottom = [
  {
    key: 'liquidity',
    title: getLabel('mandate.liquidity'),
    imageSrc: 'liquidity',
    subtitle1: '',
    subtitle2: '',
  },
  {
    key: 'uniqueCircumstances',
    title: getLabel('mandate.unique.circumstances'),
    imageSrc: 'uniqueCircumstance',
    subtitle1: '',
    subtitle2: '',
  }
];

type RecordType = {
  key: string;
  title: string;
  imageSrc: string;
  subtitle1?: string;
  subtitle2?: string;
  value?: string;
  value1?: string;
  value2?: string;
};

type Props = {
  compress?: boolean;
};

const Mandate: React.FC<Props> = ({ compress }): ReactElement => {
  const isLoading = useSelector(ProposalInfoSelectors.isGettingMandate);
  const data = useSelector(ProposalInfoSelectors.mandate);
  const recordsBaselneExtend = compress ? [] : recordsBaselineBottom;
  const recordsBaseline = [...recordsBaselineTop, ...recordsBaselneExtend];

  const [records, setRecords] = useState<RecordType[]>([]);

  useEffect(() => {
    // Mapping from data to records
    if(data){
      setRecords(
        recordsBaseline.map(rec => {
          const key = rec.key;

          return {
            ...rec,
            value: data[rec.key],
            value1: data[key + rec.subtitle1] ?? '',
            value2: data[key + rec.subtitle2] ?? '',
          };
        }),
      );
    }
  }, [data]);

  if (isLoading) {
    return <Card loading={isLoading} gap="default" />;
  }

  return (
    <Box>
      {records.map((record) => (
        <MandateItem
          key={record.key}
          identifier={record.key}
          title={record.title}
          subtitle1={record.subtitle1}
          subtitle2={record.subtitle2}
          value={record.value}
          value1={record.value1}
          value2={record.value2}
          imageSrc={record.imageSrc}
        />
      ))}
    </Box>
  );
};

export default Mandate;
