import { PUT_HOUSEHOLD_METADATA, SET_LOADING } from 'data/actions/types/householdMetadata';

export const initialState = {
  notes: [],
  members: [],
  legalEntities: [],
  isLoading: false,
  progress: 0,
  iaCode: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PUT_HOUSEHOLD_METADATA: {
      return { ...state, ...action.householdMetadata };
    }
    case SET_LOADING: {
      const { isLoading } = action;
      return { ...state, isLoading };
    }
    default:
      return state;
  }
}
