// TODO:LEVERAGE: Temporary LABELS constant to make integration of translation framework easier in future
export const DEFAULT_LABELS = {
  // Common
  'common.account': 'Account',
  'common.accounts': 'Accounts',
  'common.actual': 'Actual',
  'common.age': 'Age',
  'common.income': 'Income',
  'common.mandate': 'Mandate',
  'common.max.short': 'Max',
  'common.min.short': 'Min',
  'common.name': 'Name',
  'common.net.worth': 'Net Worth',
  'common.ownership': 'Ownership',
  'common.qbias': 'QBias',
  'common.risk': 'Risk',
  'common.target': 'Target',
  'common.total': 'Total',
  'common.value': 'Value',
  'common.qscore.allocation': 'QScore Allocation',
  'common.save': 'Save',
  'common.yes': 'Yes',
  'common.no': 'No',
  'common.reset': 'Reset',
  'common.left': 'left',
  'common.equity': 'Equity',
  'common.fixedIncome': 'Fixed Income',
  'common.cash': 'Cash',
  'common.alternatives': 'Alternatives',
  'common.others': 'Others',
  'common.rtq': 'RTQ',

  // Account
  'account.number.short': 'Account #',
  'account.type': 'Account Type',

  // Mandate
  'mandate.liquidity': 'Liquidity',
  'mandate.objective': 'Objective',
  'mandate.qscore': 'QScore',
  'mandate.risk': 'Risk',
  'mandate.time.horizon': 'Time Horizon',
  'mandate.unique.circumstances': 'Unique Circumstances',

  // Asset Classes
  'asset.class.equity': 'Equity',
  'asset.class.fixed.income': 'Fixed Income',
  'asset.class.cash': 'Cash',
  'asset.class.alternatives': 'Alternatives',
  'asset.class.others': 'Others',

  // Proposal - Summary
  'proposal.summary.create.proposal': 'Create Proposal',
  'proposal.summary.noRtq': 'Household has no active RTQ records.',
  'proposal.summary.rebalance.rtq': 'Rebalance RTQ',
  'proposal.summary.account.allocation': 'Account Allocation',

  // Proposal - Information
  'proposal.accounts.under.rtq': 'Accounts under RTQ',
  'proposal.asset.ranges': 'Asset Ranges',
  'proposal.owners.of.accounts': 'Owners of accounts under RTQ',

  // Proposal - Behavioral Consistency
  'proposal.qscore.allocation.mising.text': 'The Asset Allocation for this account is missing',
  'proposal.qscore.allocation.high.text':
    'The Asset Allocation used for this account in the financial plan<br /> is more aggressive than the account’s RTQ risk score. <br />Consider revisiting the financial plan.',
  'proposal.qscore.allocation.no.risk.text':
    'The Asset Allocation used for this account in the financial plan<br /> is equivalent to the account’s RTQ risk score.',
  'proposal.qscore.allocation.low.text':
    'The Asset Allocation used for this account in the financial plan<br /> less aggressive than the account’s RTQ risk score. <br />Consider revisiting the financial plan.',

  'proposal.inconsistencies.weighted.average.text1': 'You have indicated that you have a',
  'proposal.inconsistencies.weighted.average.text2': ' Moderate ',
  'proposal.inconsistencies.weighted.average.text3':
    'willingness/ability to take on risk, but also have a',
  'proposal.inconsistencies.weighted.average.text4': ' high loss aversion score',
  'proposal.inconsistencies.weighted.average.text5':
    '. Consider the impact of these items on your portfolio recommendation',
  'proposal.inconsistencies.risk.score.text1': 'Your QScore is',
  'proposal.inconsistencies.risk.score.text2': ' above ',
  'proposal.inconsistencies.risk.score.text3':
    'the ideal range. Consider taking on less risk while still being able to accomplish your financial objectives',
  'proposal.inconsistencies.success.text':
    'The information that has been provided to QW so far remains consistent',

  // Proposal - Investment strategy
  'proposal.investment.strategies': 'Investment Strategies',
  'proposal.investment.strategy': 'Strategy',
  'proposal.investment.strategy.button': 'Select this Strategy',
  'proposal.investment.strategy.select': 'Select an Investment Strategy',
  'proposal.investment.strategy.show.button': 'Show Investment Strategies',
  'proposal.investment.strategy.override.confirmation.title': 'Overwrite existing model setup?',
  'proposal.investment.strategy.override.confirmation.text':
    'This will overwrite any existing selections. Continue?',
  'proposal.investment.strategy.intro': 'I\'m',
  'proposal.investment.strategy.body':
    'CIO of QWealth; An investment strategy are a set of rules, factors, or processes that aim to guide an investor’s selection of an investment portfolio; a set of holdings that aim to accomplish an investor’s unique risk and return objectives, as well as accommodate any unique circumstances they may have. An investor (or a subset of their accounts) will have a mandate that consists of a risk and return constraints, which can be achieved through most investment strategies.',

  'proposal.investment.strategy.unconstrained': 'Unconstrained',
  'proposal.investment.strategy.unconstrained.body':
    'The unconstrained strategy is focused on maximizing the risk and reward trade off in investing. Other factors like taxation, cost, and ESG are still considered, but the main objective of the strategy is maximizing return per unit of risk.',
  'proposal.investment.strategy.yield.focused': 'Yield Focused',
  'proposal.investment.strategy.yield.focused.body':
    'Many investors are more concerned with generating stable and reliable income than achieving maximum growth returns. This means that returns of yield and income focused strategies may lag those of an unconstrained portfolio. However, for investors that need a stable income, especially through market turbulence, this is a worthwhile tradeoff.',
  'proposal.investment.strategy.tax.optimized': 'Tax Optimized',
  'proposal.investment.strategy.tax.optimized.body':
    'The tax minimization strategy is focused on both tax efficient and tax deferrable investments wherever possible. Maximizing return per unit of risk is a secondary goal to tax minimization in this strategy. Therefore, returns may lag compared to an unconstrained portfolio. Clients that have high tax rates or large amounts of non-registered assets will often find that a lower more tax efficient return will benefit them more than higher returns that are less tax efficient. It is important to note that this strategy is only applicable to non-registered investments.',
  'proposal.investment.strategy.low.cost': 'Low Cost',
  'proposal.investment.strategy.low.cost.body':
    'This type of strategy typically prioritizes simple index based low cost investing over more complicated and costly strategies. This can mean some more advanced risk mitigation and income strategies aren’t available to low-cost investors. Low-cost investing also tends to be more tax efficient due to the low turnover of investments in the portfolio.',
  'proposal.investment.strategy.esg': 'ESG',
  'proposal.investment.strategy.esg.body':
    'ESG (Environmental, Social, Governance) style investing prioritizes choosing companies based on their Environmental, Social, and Governance characteristics. This can mean lower returns when compared to unconstrained investing due to fewer investing choices. For clients who value good corporate character slightly ahead of maximum risk reward tradeoff, ESG investing is the right choice.',

  // Proposal - Models
  'proposal.models.title': 'Models',
  'proposal.models.model.item.title': 'MODEL',
  'proposal.models.guidance': 'Guidance',
  'proposal.models.add.exception': 'Add Exception',
  'proposal.models.drag.and.drop.text': 'Drag & drop accounts to models.',
  'proposal.models.reset.confirmation.title': 'Reset Model Confirmation',
  'proposal.models.reset.confirmation.text':
    'Are you sure you want to remove all accounts from their current models?',
  'proposal.models.delete.confirmation.title': 'Delete Model Confirmation',
  'proposal.models.delete.confirmation.text': 'Are you sure you want to delete this model?',
  'proposal.models.security.exception.confirmation.title': 'Delete Security Exception',
  'proposal.models.security.exception.confirmation.text':
    'Are you sure you want to delete this security exception?',

  'proposal.models.strategy': 'Strategy',
  'proposal.models.asset.allocation': 'Mandate',
  'proposal.models.model': 'Model',
  'proposal.models.return': 'Return',
  'proposal.models.risk': 'Risk',
  'proposal.models.add.model': 'Add Model',

  // Proposal - Allocations
  'proposal.allocation.title': 'Allocations',
  'proposal.allocation.security.header': 'Security',
  'proposal.allocation.symbol.header': 'Symbol',
  'proposal.allocation.allocation.header': 'Allocation',
  'proposal.allocation.total.weight.header': 'Target Weight',
  'proposal.allocation.allocated.header': 'Allocated',
  'proposal.allocation.remaining.header': 'Remaining',
  'proposal.allocation.account.value.header': 'Account Value',

  'proposal.allocation.not.complete': 'Allocation not complete',
  'proposal.allocation.autocomplete.button': 'Auto Complete',
  'proposal.allocation.add.security.button': 'Add Security',
  'proposal.allocation.autocomplete.prompt.title': 'Autocomplete security allocation',
  'proposal.allocation.autocomplete.prompt.body':
    'Would you like to autocomplete security allocation?',
  'proposal.allocation.autocomplete.overwrite.title': 'Overwrite existing allocations',
  'proposal.allocation.autocomplete.overwrite.body':
    'This will overwrite all existing allocations. Continue?',

  'proposal.security.exception.text': 'Security exceptions exist for this account!',
  'proposal.security.small.account.text': 'Can only be assigned to small account models',
};

export const getLabel = (key: string) => {
  return DEFAULT_LABELS[key] ?? key;
};
