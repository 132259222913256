import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';

const styles = StyleSheet.create({
  Root: {
    position: 'relative',
  },
  BackImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  MainTitle: {
    fontSize: 32,
    color: COLORS.WHITE,
  },
});

interface Props {
  rtqNum: number;
}

const ReportCover1 = ({ rtqNum }: Props) => (
  <Page size="A4" orientation="landscape" style={styles.Root}>
    <Image style={styles.BackImage} src="/images/qw-cover.jpg" />
    <View style={styles.wrapper}>
      <Text style={styles.MainTitle}>RTQ #{rtqNum}</Text>
    </View>
  </Page>
);

export default ReportCover1;
