import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  loadProposalSummary,
  loadProposalAccounts,
  loadProposalOwners,
  loadProposalMandate,
  loadProposalAssetRanges,
  loadProposalInconsistencies,
} from 'data/data-layer';

// Types
import { useAppDispatch } from 'data/store';
import { AggregatedQFolioData } from '../interfaces/AggregatedQFolioData';
import {
  EntityAccount,
  ProposalOwner,
  EntityMandate,
  EntityAssetRange,
  ModelRecord,
  EntityInconsistencies,
} from 'data/types';
import {
  selectHouseholdMetadata,
  selectHouseholdPartnerID,
} from 'data/selectors/householdSelectors';
import { mapModelsFromAccounts } from 'data/mappers';

export const useAggregateQFolioData = (): AggregatedQFolioData | null => {
  const [ready, setReady] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [rtqCount, setRtqCount] = useState(0);
  const [rtqDataLoaded, setRtqDataLoaded] = useState(0);

  const [proposalOwners, setProposalOwners] = useState<{ [rtqID: string]: Array<ProposalOwner> }>();
  const [isLegalEntityOwners, setLegalEntityOwners] = useState<boolean>(false);
  const [proposalAccounts, setProposalAccounts] = useState<{
    [rtqID: string]: Array<EntityAccount>;
  }>();
  const [proposalMandate, setProposalMandate] = useState<{ [rtqID: string]: EntityMandate }>();
  const [proposalAssetRanges, setProposalAssetRanges] = useState<{
    [rtqID: string]: Array<EntityAssetRange>;
  }>();

  const [proposalInconsistencies, setProposalInconsistencies] = useState<{
    [rtqID: string]: EntityInconsistencies;
  }>();

  const [proposalModels, setProposalModels] = useState<{ [rtqID: string]: Array<ModelRecord> }>();
  const [rtqIDs, setRtqIDs] = useState<Array<string>>([]);

  const { QID: householdId } = useSelector(selectHouseholdMetadata);
  const partnerID = useSelector(selectHouseholdPartnerID);

  useEffect(() => {
    loadProposalSummary(householdId).then(summary => {
      if (summary) {
        setRtqCount(summary.rtq.length);
        setRtqDataLoaded(0);
        setRtqIDs(summary.rtq?.map(r => r.rtqID.toString()));

        summary.rtq.forEach(rtq => {
          const rtqID = rtq.rtqID.toString();

          Promise.all([
            loadProposalOwners(rtqID),
            loadProposalMandate(rtqID),
            loadProposalAccounts(rtqID),
            loadProposalAssetRanges(rtqID),
            loadProposalInconsistencies(rtqID),
          ])
            .then(([pOwners, pMandate, pAccounts, pAssetRanges, pInconsistencies]) => {
              setProposalOwners(pval => {return {...pval, [rtqID]: pOwners }});
              setProposalMandate(pval => {return {...pval, [rtqID]: pMandate }});
              setProposalAccounts(pval => {return {...pval, [rtqID]: pAccounts }});
              setProposalAssetRanges(pval => {return {...pval, [rtqID]: pAssetRanges }});
              setProposalInconsistencies(pval => {return {...pval, [rtqID]: pInconsistencies }});
              setProposalModels(pval => {return {...pval, [rtqID]: mapModelsFromAccounts(pAccounts) }});
            })
            .catch((err) => console.error(err))
            .finally(() => {
              setRtqDataLoaded(pval => pval + 1);
            });
        });
      }
    });
  }, [householdId, partnerID, dispatch]);

  useEffect(() => {
    // if all have been loaded rtqCount
    if (rtqCount > 0 && rtqDataLoaded === rtqCount) {
      setReady(true);
    }
  }, [rtqCount, rtqDataLoaded]);

  if (!ready) return null;

  return {
    proposalOwners,
    proposalAccounts,
    proposalAssetRanges,
    proposalMandate,
    proposalInconsistencies,
    proposalModels,
    rtqIDs,
  };
};
