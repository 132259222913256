import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { loadPortfolioAnalytics } from 'data/data-layer';
import { AggregatedAnalyticsData } from '../interfaces/AggregatedAnalyticsData';

export const useAggregateAnalyticsData = (): AggregatedAnalyticsData | undefined => {
  const [data, setData] = useState<AggregatedAnalyticsData | undefined>(undefined);
  const [ready, setReady] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const analyticsData = await loadPortfolioAnalytics(
        [
          {
            ticker: 'XBB:CA',
            weight: '0.10',
          },
          {
            ticker: 'XCB:CA',
            weight: '0.10',
          },
          {
            ticker: 'ACWV',
            weight: '0.10',
          },
          {
            ticker: 'EFA',
            weight: '0.20',
          },
          {
            ticker: 'IVV',
            weight: '0.20',
          },
          {
            ticker: 'XFN:CA',
            weight: '0.20',
          },
          {
            ticker: 'CGL.C:CA',
            weight: '0.10',
          },
        ],
        dispatch,
      );

      setData(analyticsData);
      setReady(true);
    })();
  }, [dispatch]);

  if (!ready) return undefined;

  return data;
};
