import React, { ReactElement } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { VscGripper } from 'react-icons/vsc';

import { Box, styled } from '@qwealth/qcore';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
export const TitlePrimary = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: -5px;
`;

type Props = {
  name: string;
  accountNumber: string;
  disabled: boolean;
  index: number;
};

const AccountsDraggable: React.FC<Props> = ({
  accountNumber,
  name,
  disabled,
  index,
}): ReactElement => {
   return (
    <Draggable
      key={accountNumber}
      draggableId={'account|' + accountNumber}
      index={index}
      isDragDisabled={disabled}
    >
      {provided => {
        return (
          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <Box
              opacity={disabled ? 0.4 : 1}
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <VscGripper size="24" color="gray" />
              <Box flex={1} paddingLeft="small">
                {name}
              </Box>
            </Box>
          </div>
        );
      }}
    </Draggable>
  );
};

export default AccountsDraggable;
