import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Button, styled } from '@qwealth/qcore';

import Model from './Model';
import Confirmation from '../Common/Confirmation';

import { ProposalInfoSelectors } from 'data/selectors';
import {
  autoCompleteProposalAllocation,
  saveProposalAllocationsSetup,
} from 'data/actions/creators/proposalInfo';
import { getLabel } from 'data/translations/defaultLabels';
import { selectHouseholdPartnerID } from 'data/selectors/householdSelectors';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const StyledButton = styled(Button)`
  background-color: ${COLORS.primary};
`;

const SecondaryButton = styled(Button)`
  color: ${COLORS.primary};
  border-width: 2px;
  border-color: ${COLORS.primaryBorder};
  background-color: transparent;
  &:before {
    background-color: ${COLORS.primaryBackground};
  }

  &:hover:not([disabled]) {
    color: ${COLORS.primary};
  }
`;

const Allocations: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  const models = useSelector(ProposalInfoSelectors.models);
  const accounts = useSelector(ProposalInfoSelectors.accounts);

  const accountCount = useSelector(ProposalInfoSelectors.accountCount);
  const modelModified = useSelector(ProposalInfoSelectors.modelModified);
  const allocationModified = useSelector(ProposalInfoSelectors.allocationModified);
  const allocationComplete = useSelector(ProposalInfoSelectors.allocationComplete);

  const partnerID = useSelector(selectHouseholdPartnerID);

  const [showAutoCompletePrompt, setShowAutoCompletePrompt] = useState(false);

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const onClose = () => setShowConfirmation(false);

  const confirmationAction = () => {
    // Dispatch autocomplete actions
    dispatch(autoCompleteProposalAllocation(partnerID));
    onClose();
  };

  useEffect(() => {
    // Figure out if save should be enabled
    let allocationExist = false;

    models.forEach(model => {
      model.accounts?.forEach(account => {
        account.securities?.forEach(security => {
          if (security) {
            allocationExist = true;
          }
        });
      });
    });

    if (!allocationExist) {
      setShowAutoCompletePrompt(true);
    }

    setSaveDisabled(!allocationModified || !allocationComplete || accounts.length !== accountCount);
  }, [models, accounts, accountCount, allocationComplete]);

  useEffect(() => {
    if (showAutoCompletePrompt) {
      setShowConfirmation(true);
    }
  }, [showAutoCompletePrompt]);

  return (
    <Box>
      <Confirmation
        showConfirmation={showConfirmation}
        title={
          showAutoCompletePrompt
            ? getLabel('proposal.allocation.autocomplete.prompt.title')
            : getLabel('proposal.allocation.autocomplete.overwrite.title')
        }
        body={
          showAutoCompletePrompt
            ? getLabel('proposal.allocation.autocomplete.prompt.body')
            : getLabel('proposal.allocation.autocomplete.overwrite.body')
        }
        setShowConfirmation={setShowConfirmation}
        confirmationAction={confirmationAction}
      />

      <Box
        display="grid"
        gap="large"
        gridTemplateColumns={{
          desktop: '1fr',
        }}
      >
        {models.map(model => (
          <Model key={model.id} model={model} />
        ))}
      </Box>

      <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
        <StyledButton
          style={{ marginTop: '2rem' }}
          color="primary"
          disabled={saveDisabled}
          onClick={() => {
            dispatch(saveProposalAllocationsSetup(modelModified));
          }}
        >
          {getLabel('common.save')}
        </StyledButton>
        <SecondaryButton
          style={{ marginTop: '2rem' }}
          color="primary"
          onClick={() => {
            setShowConfirmation(true);
          }}
        >
          {getLabel('proposal.allocation.autocomplete.button')}
        </SecondaryButton>
      </Box>
    </Box>
  );
};

export default Allocations;
