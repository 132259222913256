import { reducerFactory } from '@qwealth/qdata';
import { SET_USER } from '../../actions/types';

const defaultState = {
  displayName: '',
  timeZone: '',
};

export default reducerFactory(
  {
    [SET_USER]: (state, { user }) => ({
      ...state,
      ...user,
    }),
  },
  defaultState,
);
