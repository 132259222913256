export default function buildUrl(params) {
  const urlParams = new URLSearchParams(window.location.search);
  ['qlifeTab', 'householdTab', 'kycTab', 'feesTab'].forEach(p => urlParams.delete(p));
  if (params) {
    Object.keys(params).forEach(field => {
      urlParams.set(field, params[field]);
    });
  }
  return urlParams.toString();
}

export const getFileExtensionFromFileName = fileName =>
  fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2);
