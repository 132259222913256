import { IFinancialModelsState } from '../reducers/financialModels';
import { createSelector } from 'reselect';

type FinancialModelsState = {
  financialModels: IFinancialModelsState;
};

const selectFinancialModels = (state: FinancialModelsState) => state.financialModels;

export const FinancialModelsSelectors = {
  isGettingFinancialModels: createSelector(
    selectFinancialModels,
    financialModels => financialModels.isGettingFinancialModels,
  ),
  models: createSelector(
    selectFinancialModels,
    financialModels => financialModels.financialModels || {},
  ),
};
