import React from 'react';
import { styled } from '@qwealth/qcore';

export const Link = styled.a`
  text-decoration: none;
  color: #fff;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: #93345d;
  }

  &:active {
    color: #fff;
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;