import React, { ReactElement, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BsPlus } from 'react-icons/bs';

import { Box, Card, Button, styled } from '@qwealth/qcore';

import { TitlePrimary } from 'components/common/Title';
import ModelSetupItem from './ModelSetupItem';
import ModelSetupTotal from './ModelSetupTotal';

import { addModelToProposal } from 'data/actions/creators/proposalInfo';
import { ProposalInfoSelectors } from 'data/selectors';
import { getLabel } from 'data/translations/defaultLabels';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const ExceptionButton = styled(Button)`
  min-width: 50px;
  color: ${COLORS.primary};
  background-color: transparent;
  &:before {
    background-color: ${COLORS.primaryBackground};
  }

  &:hover:not([disabled]) {
    color: ${COLORS.primary};
  }
`;

const ModelContainer = styled(Box)`
  max-width: 800px;
  min-width: 500px;
  overflow: auto;
  white-space: nowrap;
`;

type Props = {
  displayPercent: boolean;
};

const ModelSetup: React.FC<Props> = ({displayPercent}): ReactElement => {
  const dispatch = useDispatch();
  const models = useSelector(ProposalInfoSelectors.models);

  const addModel = () => {
    dispatch(addModelToProposal());
  };

  const getModelItems = useCallback(() => {
    return (
      <Box display="flex" flexDirection="row">
        {models.map((model, index) => (
          <ModelSetupItem key={model.id} index={index} model={model} displayPercent={displayPercent} />
        ))}
      </Box>
    );
  }, [models, displayPercent]);

  return (
    <Card loading={false} display="flex" flexDirection="column" gap="default">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <TitlePrimary>{getLabel('proposal.models.title')}</TitlePrimary>
        <ExceptionButton variant="secondary" justifyContent="flex-start" onClick={addModel}>
          <BsPlus size="24" color={COLORS.primary} />
          <Box paddingLeft="small" paddingRight="small">
            {getLabel('proposal.models.add.model')}
          </Box>
        </ExceptionButton>
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="flex-start">
        <Box padding="large" textStyle="default" color="gray.1" fontWeight="bolder" minWidth={150}>
          <Box paddingTop="large" paddingBottom="largest" />
          <Box paddingBottom="largest">{getLabel('proposal.models.strategy')}</Box>
          <Box paddingBottom="largest">{getLabel('proposal.models.asset.allocation')}</Box>
          <Box>{getLabel('proposal.models.model')}</Box>
        </Box>

        <ModelContainer>{getModelItems()}</ModelContainer>
      </Box>

      <ModelSetupTotal displayPercent={displayPercent} />
    </Card>
  );
};

export default ModelSetup;
