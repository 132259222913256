import React, { FC, ReactElement, useMemo, useState } from 'react';
import { Path, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@qwealth/qcore';
import { AggregateHousehold } from '@qwealth/qdata';
import {
  FilterModal,
  getHouseholdLabel,
  getInitialFilters,
  QGroupOption,
  QSearchComponent,
  QSearchOption,
  RecordType,
  ResultMappers,
  SearchFilterParam,
  SearchOption,
} from '@qwealth/qsearch';
import { selectRecentHistory } from 'data/selectors';
import { addRecentHistory } from '../data/actions/creators/recentHistory';

type QFolioSearchOption = SearchOption<Path> & {
  QID: string;
  name: string;
};

const mapper = (h: AggregateHousehold) => {
  const label = getHouseholdLabel(h);
  return {
    value: { pathname: `/proposal/${h.QID}`, search: '', hash: '' },
    label,
    QID: h.QID,
    name: h.name,
  };
};

const QFolioSearchMapper: ResultMappers<Path> = {
  householdMapper: mapper,
  prospectHouseholdMapper: mapper,
};

const householdAndProspectTypes: Array<RecordType> = ['Household', 'ProspectHousehold'];
const householdAndProspectOptions = householdAndProspectTypes.map(t => ({ label: t, value: t }));
const getQFolioInitialFilters = () => {
  const initialFilters = getInitialFilters();
  return {
    ...initialFilters,
    recordTypes: initialFilters.recordTypes.filter(val => householdAndProspectTypes.includes(val)),
  };
};

const QSearch: FC = (): ReactElement => {
  const navigate = useNavigate();

  const recentHistory = useSelector(selectRecentHistory);
  const dispatch = useDispatch();

  const [searchFilters, setSearchFilters] = useState<SearchFilterParam>(() =>
    getQFolioInitialFilters(),
  );
  const [cacheOptions, setCacheOptions] = useState<boolean>(true);

  const defaultOptions = useMemo(() => {
    const recentOptions: Array<QGroupOption> = [];
    if (recentHistory && recentHistory.length > 0) {
      const options: Array<QSearchOption> = recentHistory.map(h => ({
        ...h,
        label: h.name,
        value: { pathname: `/proposal/${h.QID}`, hash: '', search: '' },
      }));
      recentOptions.push({ label: 'Recent', options });
    }
    return recentOptions;
  }, [recentHistory]);

  return (
    <Box display="flex" flexDirection="row" style={{ zIndex: 999 }}>
      <Box width={600}>
        <QSearchComponent
          defaultOptions={defaultOptions}
          cacheOptions={cacheOptions}
          onChange={rawSelected => {
            const selected = rawSelected as QFolioSearchOption;
            dispatch(addRecentHistory(selected.QID, selected.name));
            navigate(selected.value);
          }}
          searchFilters={searchFilters}
          searchMapper={QFolioSearchMapper}
        />
      </Box>
      <FilterModal
        typeOptions={householdAndProspectOptions}
        searchFilters={searchFilters}
        setSearchFilters={filters => {
          setCacheOptions(false);
          setSearchFilters(filters);
        }}
      />
    </Box>
  );
};

export default QSearch;
