import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import AccountTable from '../Common/AccountTable';

import { ProposalInfoSelectors } from '../../../data/selectors';

const Accounts: React.FC = (): ReactElement => {
  const isLoading = useSelector(ProposalInfoSelectors.isGettingAccounts);
  const data = useSelector(ProposalInfoSelectors.accounts);

  return <AccountTable isLoading={isLoading} accounts={data} showTitle />;
};

export default Accounts;
