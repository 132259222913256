import axios from 'axios';
import { errorHandler, REACT_APP_QWEALTH_QC_API } from '../../../services/axiosService';

import { SET_LOADING, PUT_HOUSEHOLD_METADATA } from '../types';

import { initialState } from 'data/reducers/householdMetadata';

const setLoading = isLoading => ({ type: SET_LOADING, isLoading });

export const putHouseholdMetadata = householdMetadata => ({
  type: PUT_HOUSEHOLD_METADATA,
  householdMetadata,
});

export const loadHouseholdMetadata = householdId => dispatch => {
  dispatch(setLoading(true));
  return axios
    .get(`${REACT_APP_QWEALTH_QC_API}/qcentral/household/${householdId}/metadata`)
    .then(response => response.data)
    .then(householdMetadata => dispatch(putHouseholdMetadata(householdMetadata)))
    .catch(error => {
      errorHandler(dispatch)(error);
      dispatch(putHouseholdMetadata({ ...initialState, householdId, QID: householdId }));
    })
    .finally(() => dispatch(setLoading(false)));
};
