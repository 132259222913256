import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';
import { Domicile } from '../interfaces/AggregatedAnalyticsData';

const styles = StyleSheet.create({
  Title: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    fontWeight: 'bold',
  },
  Description: {
    fontSize: 12,
    marginTop: 20,
  },
  HeaderCell: {
    display: 'flex',
    flexDirection: 'row',
    height: 35,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    flex: 1,
  },
  ValueCell: {
    fontSize: 10,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    flex: 1,
  },
});

const crateChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets'],
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets,
  },
});

const ReportGeographicBreakdown = ({
  countries,
  domicile,
}: {
  countries?: Array<object>;
  domicile?: Domicile;
}) => (
  <Page size="A4" orientation="landscape" style={commonStyles.Root}>
    <View style={commonStyles.Header} fixed>
      <Text style={commonStyles.HeaderTitle}>GEOGRAPHIC BREAKDOWN</Text>
      <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
    </View>

    <View style={[commonStyles.Content, { paddingHorizontal: 60 }]}>
      <Text style={styles.Title}>Capital Distribution</Text>
      <Text style={styles.Description}>
        This infographic displays how your capital would be distributed across geographic regions,
        as defined by MSCI.
      </Text>

      <View style={[commonStyles.Row, commonStyles.Width100, { marginTop: 70 }]}>
        <View style={{ width: '60%' }}>
          {useChartJsAsImage(
            crateChartJsConfig(
              'choropleth',
              /* @ts-ignore */
              countries?.map(d => d.properties.name),
              [
                {
                  label: 'Countries',
                  /* @ts-ignore */
                  data: countries.map(d => {
                    let value;
                    /* @ts-ignore */
                    if (d.properties.name === 'Canada') {
                      value = domicile?.Canada;
                      /* @ts-ignore */
                    } else if (d.properties.name === 'United States of America') {
                      value = domicile?.US;
                    } else {
                      value = domicile?.Other;
                    }

                    return {
                      feature: d,
                      value: value! * 100,
                    };
                  }),
                },
              ],
            ),
            {
              showOutline: true,
              showGraticule: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                xy: {
                  projection: 'equalEarth',
                },
                color: {
                  legend: {
                    position: 'bottom-right',
                    align: 'top',
                  },
                  ticks: {
                    font: {
                      size: 24,
                    },
                  },
                },
              },
            },
          )}
        </View>
        <View style={{ flex: 1, paddingLeft: 30 }}>
          <View style={[commonStyles.Row, commonStyles.Width100]}>
            <View style={{ flex: 1 }}>
              <Text></Text>
            </View>
            <View style={styles.HeaderCell}>
              <Text>Portfolio(%)</Text>
            </View>
          </View>

          {domicile &&
            Object.keys(domicile).map(d => (
              <View key={d} style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={styles.ValueCell}>
                  <Text>{d}</Text>
                </View>
                <View style={styles.ValueCell}>
                  {/* @ts-ignore */}
                  <Text>{(domicile[d] * 100).toFixed(2)}%</Text>
                </View>
              </View>
            ))}
        </View>
      </View>
    </View>
  </Page>
);

export default ReportGeographicBreakdown;
