import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import { toCurrency } from '@qwealth/qcore';

import { EntityAccount } from 'data/types';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  MainText: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    textAlign: 'center',
    marginTop: 60,
    paddingHorizontal: 60,
  },
  Content: {
    diplay: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginVertical: 60,
    paddingHorizontal: 60,
    justifyContent: 'space-between',
  },
  LeftPane: {
    display: 'flex',
    width: '40%',
  },
  RightPane: {
    width: '55%',
    display: 'flex',
  },
  HeaderCell: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    height: 40,
    fontSize: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingHorizontal: 10,
    flex: 1,
  },
  ValueCell: {
    fontSize: 10,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    paddingHorizontal: 10,
    flex: 1,
  },
  Column: {
    display: 'flex',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  BoldFont: {
    fontWeight: 'bold',
  },
});

const ROWS = [
  {
    key: 'ownership',
    title: 'Ownership',
  },
  {
    key: 'accountType',
    title: 'Account Type',
  },
  {
    key: 'mandate',
    title: 'Mandate',
  },
  {
    key: 'value',
    title: 'Value',
  },
];

interface Props {
  rtqNum: number;
  proposalAccounts?: Array<EntityAccount>;
}

const ReportInvestmentObjectives2 = ({ rtqNum, proposalAccounts }: Props) => (
  <Page size="A4" orientation="landscape" style={commonStyles.Root}>
    <View style={commonStyles.Header} fixed>
      <Text style={commonStyles.HeaderTitle}>{`RTQ #${rtqNum}: INVESTMENT OBJECTIVES`}</Text>
      <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
    </View>

    <Text style={styles.MainText}>
      We have compared the risk/return profile used in your financial plan to the risk level you
      have indicated for these accounts and found them all to be consistent.
    </Text>

    <View style={styles.Content}>
      <View style={{ width: '100%' }}>
        <View style={[styles.Row, { width: '100%', backgroundColor: COLORS.PRIMARY_LIGHT }]}>
          {ROWS.map(row => (
            <View key={row.key} style={[styles.HeaderCell]}>
              <Text>{row.title}</Text>
            </View>
          ))}
        </View>
        {proposalAccounts?.map(proposalAccount => (
          <View key={proposalAccount.accountNumber} style={styles.Row}>
            <View style={styles.ValueCell}>
              <Text>{proposalAccount.accountOwnership}</Text>
            </View>
            <View style={styles.ValueCell}>
              <Text>{proposalAccount.accountType}</Text>
            </View>
            <View style={styles.ValueCell}>
              <Text>{proposalAccount?.modelPortfolio?.mandateName}</Text>
            </View>
            <View
              style={[
                styles.ValueCell,
                {
                  fontWeight: 'bold',
                },
              ]}
            >
              <Text>{toCurrency(proposalAccount.currentValue, true)}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  </Page>
);

export default ReportInvestmentObjectives2;
