import React, {
  ReactElement,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { Box, Button, styled } from '@qwealth/qcore';

import { TitlePrimary } from 'components/common/Title';
import AssetRanges from '../Common/AssetRanges';
import InvestmentStrategies from '../InvestmentStrategies';
import Accounts from './Accounts';
import ModelSetup from './ModelSetup';

import { ProposalInfoSelectors } from 'data/selectors';
import {
  performAccountToModelMapping,
  saveProposalModelsSetup,
} from 'data/actions/creators/proposalInfo';
import { getLabel } from 'data/translations/defaultLabels';
import { COLORS } from 'theme/colors';

const StyledButton = styled(Button)`
  background-color: ${COLORS.primary};
`;

const Models: React.FC = (): ReactElement => {
  const dispatch = useDispatch();
  const models = useSelector(ProposalInfoSelectors.models);
  const accounts = useSelector(ProposalInfoSelectors.accounts);

  const accountCount = useSelector(ProposalInfoSelectors.accountCount);
  const modelModified = useSelector(ProposalInfoSelectors.modelModified);

  const [displayPercent, setDisplayPercent] = useState(false);

  const [hasModelSetup, setHasModelSetup] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [showModal, setShowModal] = useState(!hasModelSetup);

  useEffect(() => {
    setHasModelSetup(models.length > 0);
    setShowModal(models.length === 0);

    setSaveDisabled(!modelModified || accounts.length !== accountCount);
  }, [models, accounts, modelModified, accountCount]);

  const onDragEnd = useCallback(result => {
    if (result?.destination?.droppableId && result?.source?.droppableId && result?.draggableId) {
      dispatch(
        performAccountToModelMapping(
          result.source.droppableId,
          result.destination.droppableId,
          result.draggableId,
        ),
      );
    }
  }, []);

  return (
    <>
      <InvestmentStrategies
        needConfirmation={hasModelSetup}
        showModal={showModal}
        setShowModal={setShowModal}
      />

      <Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <TitlePrimary>{''}</TitlePrimary>
              <StyledButton marginBottom="large" height={40} onClick={() => setShowModal(true)}>
                {getLabel('proposal.investment.strategy.show.button')}
              </StyledButton>
            </Box>
          </Box>
          <Box
            display="grid"
            gap="large"
            gridTemplateColumns={{
              desktop: '1.25fr 1fr',
            }}
          >
            <ModelSetup displayPercent={displayPercent} />
            <Droppable droppableId="accounts" type="ACCOUNT">
              {provided => {
                return (
                  <Box ref={provided.innerRef} {...provided.droppableProps}>
                    <Accounts displayPercent={displayPercent} setDisplayPercent={setDisplayPercent} />
                    {provided.placeholder}
                  </Box>
                );
              }}
            </Droppable>
          </Box>
        </DragDropContext>

        <Box
          display="grid"
          gap="large"
          marginTop="large"
          gridTemplateColumns={{ desktop: '1.25fr 1fr' }}
        >
          <AssetRanges displayActual />
        </Box>

        <Box display="flex" flexDirection="row-reverse">
          <StyledButton
            style={{ marginTop: '2rem' }}
            color="primary"
            disabled={saveDisabled}
            onClick={() => {
              dispatch(saveProposalModelsSetup());
            }}
          >
            {getLabel('common.save')}
          </StyledButton>
        </Box>
      </Box>
    </>
  );
};

export default Models;
