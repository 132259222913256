import React from 'react';
import { styled } from '@qwealth/qcore';
import { COLORS } from 'theme/colors';

const StyledTitle = styled.h5`
  font-weight: bold;
  font-size: 14px;
  color: ${COLORS.lightGray};
  text-transform: uppercase;
`;

export const TitlePrimary = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0.75rem;
`;

export default function Title(props) {
  return <StyledTitle>{props.children}</StyledTitle>;
}
