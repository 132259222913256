import React, { FC, ReactElement, SetStateAction, Dispatch, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { lighten } from 'polished';
import { Form, Field, useFormState, useForm } from 'react-final-form';

import {
  Box,
  Button,
  Modal,
  RadioInput,
  styled,
  TextAreaInput,
  RFFCurrencyInput,
  RFFReactSelect,
  RFFTextInput,
  RFFCheckInput,
  PercentInput,
  RFFErrorMessage,
} from '@qwealth/qcore';

import { ProposalInfoSelectors } from 'data/selectors';
import { BaseEntitySecurityException, EntitySecurityException, ProposalAccount } from 'data/types';
import { loadSecurityMaster } from 'data/data-layer/securityMaster';
import { QContext } from '@qwealth/qdata';
import {
  createSecurityException,
  updateSecurityException,
} from 'data/actions/creators/securityException';
import { COLORS } from 'theme/colors';

const animatedComponents = makeAnimated();

const FIELD_SUBSCRIPTION = { value: true };

const StyledHeader = styled.span`
  color: ${COLORS.primary};
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 7px;
`;

const StyledText = styled(StyledHeader)`
  color: ${COLORS.gray};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0px;
`;
const StyledRadioInput = styled(RadioInput)`
  z-index: 0;
`;

const StyledButton = styled(Button)`
  background-color: ${COLORS.primary};
  &:before {
    background-color: ${lighten(0.05, COLORS.primary)};
  }
`;

const StyledCancelButton = styled(Button)`
  background-color: ${COLORS.secondaryButtonBackground};
  border: none;
  color: ${COLORS.secondaryButton};
  &:before {
    background-color: ${lighten(0.05, COLORS.primary)};
  }
`;

type Props = {
  account?: ProposalAccount;
  securityException?: EntitySecurityException;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

type ValuationType = 'units' | 'dollars' | 'weight';

const SecurityModal: FC<Props> = ({
  account,
  securityException,
  showModal,
  setShowModal,
}): ReactElement => {
  const dispatch = useDispatch();

  const submitHandler = async data => {
    // check if account has security exception for the ticker, if yes then update that one
    const excep = account?.securityExceptions.find(e => e.ticker === data.ticker);
    const exceptionId = excep ? excep.exceptionId : data.exceptionId;
    const exception: BaseEntitySecurityException = {
      exceptionId,
      accountNumber: data.accountNumber,
      ticker: data.ticker,
      units: data.valuationType === 'units' ? data.units : exceptionId ? null : undefined,
      dollars: data.valuationType === 'dollars' ? data.dollars : exceptionId ? null : undefined,
      weight: data.valuationType === 'weight' ? data.weight : exceptionId ? null : undefined,
      includeInAssetAlloc: data.includeInAssetAlloc ? 1 : 0,
      note: data.note,
    };

    if (exception.exceptionId) {
      dispatch(updateSecurityException(exception));
    } else {
      dispatch(createSecurityException(exception));
    }

    closeModal();
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onClose={closeModal} title={'Security Exceptions'} size="l">
      <Modal.Body>
        <Form
          onSubmit={submitHandler}
          initialValues={{
            accountNumber: account?.accountNumber,
            exceptionId: securityException?.exceptionId,
            ticker: securityException?.ticker,
            units: securityException?.units,
            dollars: securityException?.dollars,
            weight: securityException?.weight,
            includeInAssetAlloc:
              securityException?.includeInAssetAlloc && securityException?.includeInAssetAlloc === 1
                ? true
                : false,
            note: securityException?.note,
            valuationType: securityException?.dollars
              ? 'dollars'
              : securityException?.weight
              ? 'weight'
              : 'units',
          }}
        >
          {({ handleSubmit, dirty, values }) => {
            const { ticker, accountNumber } = values;
            const disableSave = ticker === undefined || accountNumber === undefined;

            return (
              <form onSubmit={handleSubmit}>
                <Box gap="large">
                  <SecurityForm securityException={securityException} />

                  <Box display="flex" gap="default">
                    <StyledButton marginTop="small" type="submit" disabled={!dirty || disableSave}>
                      Save
                    </StyledButton>
                    <StyledCancelButton variant={'outline'} marginTop="small" onClick={closeModal}>
                      Cancel
                    </StyledCancelButton>
                  </Box>
                </Box>
              </form>
            );
          }}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

type SecurityFormProps = {
  securityException?: EntitySecurityException;
};

const SecurityForm: FC<SecurityFormProps> = ({ securityException }): ReactElement => {
  const dispatch = useDispatch();
  const accounts = useSelector(ProposalInfoSelectors.accounts);

  const { values } = useFormState();
  const { change } = useForm();

  const { accountNumber: currentAccountNumber, valuationType: currentValuationType } = values;

  const [valuationType, setValuationType] = useState<ValuationType>(currentValuationType);

  const currentHoldings = useMemo(() => {
    if (securityException) {
      return [
        {
          ticker: securityException.ticker,
          label: securityException.ticker,
          value: securityException.ticker,
        },
      ];
    }

    const aa = accounts.find(a => a.accountNumber === currentAccountNumber);

    return (
      aa?.currentHoldings.map(h => {
        return {
          ticker: h.ticker,
          label: h.ticker,
          value: h.ticker,
          isHolding: true,
          units: h.units,
          dollars: h.marketValue,
        };
      }) ?? []
    );
  }, [currentAccountNumber, securityException]);

  let previousQuery;
  const loadOptions = (query: string) =>
    new Promise(resolve => {
      if (query.length < 2) {
        resolve([]);
      } else {
        if (previousQuery) {
          clearTimeout(previousQuery);
        }
        previousQuery = setTimeout(() => {
          loadSecurityMaster(query)
            .then(results =>
              resolve(
                results.map(r => {
                  return {
                    ticker: r.ticker,
                    label: `${r.ticker} - ${r.description}`,
                    value: r.ticker,
                  };
                }),
              ),
            )
            .catch(err => {
              QContext.getErrorHandler()(dispatch, 'Search failed. Please contact support')(err);
              resolve([]);
            });
        }, 500);
      }
    });

  return (
    <>
      <Box marginBottom="large">
        <RFFReactSelect
          label="Account"
          name={'accountNumber'}
          subscription={FIELD_SUBSCRIPTION}
          inputFieldProps={{
            options: accounts,
            toLabel: item => `${item.name} (${item.type})`,
            toValue: item => item.accountNumber,
            isDisabled: !!securityException,
          }}
        />
      </Box>

      <Box marginBottom="large">
        <Field name="ticker" subscription={FIELD_SUBSCRIPTION}>
          {({ meta, input }) => (
            <Box flex={1}>
              <Box>Security</Box>
              <AsyncSelect
                cacheOptions
                components={animatedComponents}
                defaultOptions={currentHoldings}
                defaultValue={currentHoldings[0]}
                // @ts-ignore TODO: fix this crap...
                loadOptions={loadOptions}
                onChange={selected => {
                  // @ts-ignore TODO: fix this crap...
                  change('ticker', selected?.value);

                  // Update values if holding selected
                  // @ts-ignore TODO: fix this crap...
                  if (selected?.units) {
                    change('valuationType', 'units');
                    // @ts-ignore TODO: fix this crap...
                    change('units', selected?.units);
                    // @ts-ignore TODO: fix this crap...
                  } else if (selected?.dollars) {
                    change('valuationType', 'dollars');
                    // @ts-ignore TODO: fix this crap...
                    change('dollars', selected?.dollars);
                  }
                }}
                isDisabled={!!securityException}
              />
              {meta.error && <Box color="error">{meta.error}</Box>}
            </Box>
          )}
        </Field>
      </Box>

      <Box marginBottom="small">
        <StyledText>Valuation</StyledText>
      </Box>

      <Box display="flex">
        <StyledRadioInput
          name="quantity"
          id="quantity"
          value="quantity"
          label="Quantity"
          checked={valuationType === 'units'}
          onChange={() => {
            setValuationType('units');
            change('valuationType', 'units');
          }}
        />
        <StyledRadioInput
          name="dollars"
          id="dollars"
          value="dollars"
          label="Amount"
          checked={valuationType === 'dollars'}
          onChange={() => {
            setValuationType('dollars');
            change('valuationType', 'dollars');
          }}
        />
        <StyledRadioInput
          name="weight"
          id="weight"
          value="weight"
          label="Percentage"
          checked={valuationType === 'weight'}
          onChange={() => {
            setValuationType('weight');
            change('valuationType', 'weight');
          }}
        />
      </Box>

      <Box marginBottom="large">
        <RFFTextInput
          name="units"
          subscription={FIELD_SUBSCRIPTION}
          inputFieldProps={{
            hidden: valuationType !== 'units',
            type: 'number',
          }}
        />

        <RFFCurrencyInput
          name="dollars"
          subscription={FIELD_SUBSCRIPTION}
          inputFieldProps={{
            hidden: valuationType !== 'dollars',
          }}
        />

        <Field name="weight">
          {({ input, meta }) => (
            <>
              {/*@ts-ignore */}
              <PercentInput {...input} hidden={valuationType !== 'weight'} />
              {meta.touched && meta.error ? (
                <RFFErrorMessage errorNotificationUUID={input.name}>{meta.error}</RFFErrorMessage>
              ) : null}
            </>
          )}
        </Field>
      </Box>

      <Box marginBottom="large">
        <RFFCheckInput
          label="Consider in the portfolio asset allocation?"
          name="includeInAssetAlloc"
          subscription={FIELD_SUBSCRIPTION}
          inputFieldProps={{
            id: 'includeInAssetAlloc',
            type: 'switch',
          }}
        />
      </Box>

      <Box marginBottom="large">
        <Field name="note">
          {({ meta, input }) => (
            <Box flex={1}>
              <Box>Notes</Box>
              <TextAreaInput {...input} rows={3} width="100%" />
              {meta.error && <Box color="error">{meta.error}</Box>}
            </Box>
          )}
        </Field>
      </Box>
    </>
  );
};

export default SecurityModal;
