import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Tooltip } from 'react-tooltip';

import { Box, styled, AccordionSection, toCurrency, toPercent } from '@qwealth/qcore';
import { BsExclamationCircleFill, BsPencil, BsTrash } from 'react-icons/bs';

import { getLabel } from 'data/translations/defaultLabels';
import { EntitySecurityException, ProposalAccount } from 'data/types';
import { SMALL_ACCOUNT_THRESHOLD } from 'data/refData';
import { RecordType } from './Accounts';
import { COLORS } from 'theme/colors';
import { AccountContainer } from './AccountItem';

export const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  background-color: ${COLORS.primaryBackgroundLight} !important;
  color: ${COLORS.primary} !important;
  margin-bottom: 10px !important;
  border-radius: 10px !important;
  font-size: 14px !important;
  padding: 1rem 1rem !important;
  font-weight: 700;
`;

const SubHeaderContainer = styled(HeaderContainer)`
  background-color: ${COLORS.grayBackground} !important;
  color: ${COLORS.subheaderText} !important;
  padding: 0.5rem 0.5rem !important;
  margin: 0 1.5rem !important;
`;

const SubContainer = styled(Box)`
  padding: 0.5rem 0.5rem !important;
  margin: 0 1.5rem !important;
`;

const HeaderItem = styled(Box)`
  text-align: left;
  text-transform: uppercase;
`;

type Props = {
  data: RecordType;
  setExceptionId: Dispatch<SetStateAction<number | undefined>>;
  setShowConfirmation: Dispatch<SetStateAction<boolean>>;
  displayPercent: boolean;
  totalValue: number;
  light?: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setAccount: Dispatch<SetStateAction<ProposalAccount | undefined>>;
  setSecurityException: Dispatch<SetStateAction<EntitySecurityException | undefined>>;
};

const AccountSecurityItem: React.FC<Props> = ({
  data,
  setExceptionId,
  setShowConfirmation,
  displayPercent,
  totalValue,
  setShowModal,
  setAccount,
  setSecurityException,
}): ReactElement => {
  const isSmallAccount = data.value < SMALL_ACCOUNT_THRESHOLD;
  return (
    <AccordionSection
      bg="white"
      collapsed
      key={data.account.accountNumber}
      // @ts-ignore
      title={
        <AccountContainer>
          <Box flex={1}>
            {data.name}
            {isSmallAccount ? (
              <p
                data-tooltip-content={getLabel('proposal.security.small.account.text')}
                data-tooltip-id="securityItemTooltip"
              >
                *
              </p>
            ) : null}
          </Box>
          <Box flex={0.55}>{data.account.type}</Box>
          <Box flex={0.75}>
            {displayPercent ? toPercent(data.value / totalValue) : toCurrency(data.value)}
          </Box>
          {data.account.securityExceptions?.length ? (
            <Box
              flex={0.1}
              data-tooltip-content={getLabel('proposal.security.exception.text')}
              data-tooltip-id="securityItemTooltip"
              paddingRight="large"
            >
              <BsExclamationCircleFill size="24" color={COLORS.red} />
            </Box>
          ) : (
            <Box flex={0.1} paddingRight="large" />
          )}
          <Tooltip id="securityItemTooltip" />
        </AccountContainer>
      }
    >
      <SubHeaderContainer>
        <Box flex={1}>
          <HeaderItem>Security</HeaderItem>
        </Box>
        <Box flex={1}>
          <HeaderItem>Amount</HeaderItem>
        </Box>
        <Box flex={0.75}>
          <HeaderItem>Include</HeaderItem>
        </Box>
        <Box flex={0.25}>
          <HeaderItem></HeaderItem>
        </Box>
        <Box flex={0.25}>
          <HeaderItem></HeaderItem>
        </Box>
      </SubHeaderContainer>
      {data.account.securityExceptions?.map(s => {
        return (
          <SubContainer
            key={s.exceptionId}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box flex={1}>{s.ticker}</Box>
            <Box flex={1}>
              {s.units
                ? `${s.units} units`
                : s.weight
                ? toPercent(s.weight)
                : s.dollars
                ? toCurrency(s.dollars)
                : '-'}
            </Box>
            <Box flex={0.75}>{s.includeInAssetAlloc ? 'YES' : 'NO'}</Box>
            <Box
              flex={0.2}
              onClick={() => {
                setAccount(data.account);
                setSecurityException(s);
                setShowModal(true);
              }}
            >
              <BsPencil size={15} color={COLORS.lightGray} />
            </Box>
            <Box
              flex={0.2}
              onClick={() => {
                setExceptionId(s.exceptionId);
                setShowConfirmation(true);
              }}
            >
              <BsTrash size={15} color={COLORS.lightGray} />
            </Box>
          </SubContainer>
        );
      })}
    </AccordionSection>
  );
};

export default AccountSecurityItem;
