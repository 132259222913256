import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { matchPath } from 'react-router';
import { BsDownload, BsFillStickiesFill } from 'react-icons/bs';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { AppLayout } from '@qwealth/qcore';
import { Link } from 'components/common/Link';

import { selectHouseholdMetadata } from 'data/selectors/householdSelectors';
import { ProposalInfoSelectors } from 'data/selectors';

const Sidebar = () => {
  const summary = useSelector(ProposalInfoSelectors.summary);
  const { QID, type } = useSelector(selectHouseholdMetadata);
  const location = useLocation();

  const hasRtqs = (summary?.rtqs || []).length > 0;
  const matchPaths = useCallback(
    values => {
      let match = false;
      values.forEach(value => {
        const path = matchPath(
          {
            path: value,
            exact: true,
          },
          location.pathname,
        );
        if (path) {
          match = true;
        }
      });
      return match;
    },
    [location],
  );

  const navigate = useNavigate();

  const qcentralLink = useMemo(() => {
    let qcHouseholdParams = '';

    if (QID) {
      qcHouseholdParams =
        type === 'Prospect' ? `/prospect-households/${QID}` : `/household?household=${QID}`;
    }

    return `${process.env.REACT_APP_QWEALTH_QC}${qcHouseholdParams}`;
  }, [QID, type]);

  return (
    <AppLayout.SideNav title="QFolio">
      <AppLayout.SideNavLinks>
        <AppLayout.SideNavLinkItem
          activeClass={matchPaths(['/', '/proposal/*']) ? 'active' : undefined}
          title="Proposal"
          icon={BsFillStickiesFill}
          onClick={() => navigate({ pathname: '/' })}
        />
        {QID && hasRtqs && (
          <AppLayout.SideNavLinkItem
            activeClass={matchPaths(['/report/*']) ? 'active' : undefined}
            title="Report"
            icon={HiOutlineDocumentReport}
            onClick={() => navigate({ pathname: `/report/${QID}` })}
          />
        )}
        {QID && hasRtqs && (
          <AppLayout.SideNavLinkItem
            activeClass={matchPaths(['/digit-export/*']) ? 'active' : undefined}
            title="D1g1t Export"
            icon={BsDownload}
            onClick={() => navigate({ pathname: `/digit-export/${QID}` })}
          />
        )}
      </AppLayout.SideNavLinks>
      <AppLayout.SideNavCollapsedHidden>
        <Link href={qcentralLink}>Back to QCentral &gt;</Link>
      </AppLayout.SideNavCollapsedHidden>
    </AppLayout.SideNav>
  );
};

export default Sidebar;
