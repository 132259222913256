import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { BsExclamationCircleFill } from 'react-icons/bs';

import { Box, Card, styled, Button, AccordionSection } from '@qwealth/qcore';

import Securities from './Securities';
import Account from './Account';
import Confirmation from '../Common/Confirmation';
import DisplayToggleContainer from '../Common/DisplayToggleContainer';

import { autoCompleteProposalAllocationModel } from 'data/actions/creators/proposalInfo';
import { ModelRecord, ModelSecurityMappedRecord, ModelSecurityRecord } from 'data/types';
import { FinancialModelsSelectors } from 'data/selectors';
import { getLabel } from 'data/translations/defaultLabels';
import { selectHouseholdPartnerID } from 'data/selectors/householdSelectors';
import { loadModels } from 'data/actions/creators/financialModels';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const SecondaryButton = styled(Button)`
  min-width: 50px;
  color: ${COLORS.primary};
  border-width: 2px;
  border-color: ${COLORS.primaryBorder};
  background-color: transparent;
  &:before {
    background-color: ${COLORS.primaryBackground};
  }

  &:hover:not([disabled]) {
    color: ${COLORS.primary};
  }
`;

type Props = {
  model: ModelRecord;
};

const Model: React.FC<Props> = ({ model }): ReactElement => {
  const dispatch = useDispatch();

  const partnerID = useSelector(selectHouseholdPartnerID);
  const financialModels = useSelector(FinancialModelsSelectors.models);
  const [securities, setSecurities] = useState<ModelSecurityRecord[]>([]);
  const [displayPercent, setDisplayPercent] = useState(false);
  const [modelSecuritiesMapped, setModelSecuritiesMapped] = useState<ModelSecurityMappedRecord[]>(
    [],
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const onClose = () => setShowConfirmation(false);
  const [allocationComplete, setAllocationComplete] = useState(false);

  const confirmationAction = () => {
    // Dispatch autocomplete actions
    dispatch(
      autoCompleteProposalAllocationModel({
        securities: securities,
        modelId: model?.modelId,
      }),
    );

    onClose();
  };

  useEffect(() => {
    // Load securities needed by the models
    if (partnerID && model.strategy && model.assetAllocation) {
      const modelIdentifier = partnerID + model.strategy + model.assetAllocation;

      if (financialModels[modelIdentifier]?.length) {
        const fModels = financialModels[modelIdentifier].filter(f => f.modelId === model.modelId);
        if (fModels.length) {
          setSecurities(fModels[0].securities);
        }
      } else {
        dispatch(loadModels(partnerID, model.strategy, model.assetAllocation));
      }
    }
  }, [financialModels, model, partnerID]);

  useEffect(() => {
    const accountsTotal = model.accounts.reduce((n, { value, securityAllocated }) => n + value - securityAllocated, 0);

    const securitiesAllocation = {};

    // go through accounts and get net for each securities
    model.accounts.forEach(account => {
      account?.securities?.forEach(security => {
        const allocated = security.allocatedPercent * account.value;
        securitiesAllocation[security.ticker] =
          (allocated ?? 0.0) + (securitiesAllocation[security.ticker] ?? 0.0);
      });
    });

    const mappedModelSecurities = securities.map(security => {
      const total = security.totalPercent * accountsTotal;

      const allocated = securitiesAllocation[security.ticker] ?? 0.0;
      const allocatedPercent = allocated / accountsTotal;

      const remaining = total - allocated;
      const remainingPercent = (total - allocated) / accountsTotal;

      return {
        ...security,
        total,
        allocatedPercent,
        allocated,
        remainingPercent,
        remaining,
      };
    });

    setModelSecuritiesMapped(mappedModelSecurities);
  }, [securities, model.accounts]);

  useEffect(() => {
    const total = modelSecuritiesMapped.reduce(
      (n, { allocatedPercent }) => n + allocatedPercent,
      0,
    );
    setAllocationComplete(Math.round(total * 100) == 100);
  }, [modelSecuritiesMapped]);

  return (
    <Card loading={false} display="flex" flexDirection="column" gap="default">
      <Confirmation
        showConfirmation={showConfirmation}
        title={getLabel('proposal.allocation.autocomplete.overwrite.title')}
        body={getLabel('proposal.allocation.autocomplete.overwrite.body')}
        setShowConfirmation={setShowConfirmation}
        confirmationAction={confirmationAction}
      />
      <AccordionSection
        color="primary"
        collapsed
        key={model.modelId}
        title={`${model.strategy} - ${model.assetAllocation} - ${model.modelName}`}
        metadata={
          !allocationComplete && (
            <Box display="flex" flexDirection="row-reverse">
              <Box
                data-tooltip-content={getLabel('proposal.allocation.not.complete')}
                data-tooltip-id="allocationTooltip"
              >
                <BsExclamationCircleFill size="24" color={COLORS.red} />
                <Tooltip id="allocationTooltip" />
              </Box>
            </Box>
          )
        }
      >
        <Box marginX="large">
          <Box
            display="flex"
            flexDirection="row-reverse"
            marginTop="default"
            marginBottom="default"
          >
            <DisplayToggleContainer displayPercent={displayPercent} setDisplayPercent={setDisplayPercent} />
            {!allocationComplete && <SecondaryButton
              marginRight="default"
              variant="secondary"
              justifyContent="flex-start"
              onClick={() => {
                setShowConfirmation(true);
              }}
            >
              {getLabel('Auto Complete')}
            </SecondaryButton>}
          </Box>
          <Securities
            modelSecurities={modelSecuritiesMapped}
            accounts={model.accounts}
            displayPercent={displayPercent}
          />
          <Account
            modelSecurities={modelSecuritiesMapped}
            accounts={model.accounts}
            displayPercent={displayPercent}
          />
        </Box>
      </AccordionSection>
    </Card>
  );
};

export default Model;
