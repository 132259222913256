import React, { useEffect, useState } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { toCurrency, toPercent } from '@qwealth/qcore/utils';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { ModelRecord, ProposalAccount } from 'data/types';

const styles = StyleSheet.create({
  MainText: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    textAlign: 'center',
    fontWeight: 'medium',
  },
  HeaderCell: {
    display: 'flex',
    flexDirection: 'row',
    height: 35,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: 'bold',
    flex: 1,
    paddingLeft: 10,
  },
  SubHeaderCell: {
    display: 'flex',
    flexDirection: 'row',
    height: 25,
    backgroundColor: COLORS.GRAY1,
    color: COLORS.BLACK,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: 'bold',
    flex: 1,
    textTransform: 'uppercase',
    paddingLeft: 10,
  },
  ValueCell: {
    fontSize: 10,
    borderBottomWidth: 1,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    paddingLeft: 10,
    paddingVertical: 2,
  },
  ExceptionTextHighlight: {
    fontSize: 10,
    color: COLORS.PRIMARY_LIGHT
  },
  ExceptionText: {
    backgroundColor: COLORS.GRAY1,
    color: COLORS.BLACK,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingLeft: 10,
    paddingVertical: 5,
  },
});

const HeaderData = ['Holding Name', 'Symbol', 'Allocation %', 'Allocation $'];

interface Props {
  rtqNum: number;
  proposalModels?: Array<ModelRecord>;
}

const ReportSecurityAllocation = ({ rtqNum, proposalModels }: Props) => {
  const [accountWithSecurities, setAccountWithSecurities] = useState<ProposalAccount[]>([]);

  useEffect(() => {
    let securities: ProposalAccount[] = [];

    proposalModels?.forEach(m => {
      const accounts = m.accounts.map(a => {
        return {
          ...a,
        };
      });

      securities = [...securities, ...accounts];
    });

    setAccountWithSecurities(securities);
  }, [proposalModels]);

  return (
    <>
      {accountWithSecurities?.map((account, idx) => {
        return (
          <Page size="A4" orientation="landscape" style={commonStyles.Root}>
            <View style={commonStyles.Header} fixed>
              <Text style={commonStyles.HeaderTitle}>
                {`RTQ #${rtqNum}: SECURITIES ALLOCATIONS: ${account.name}`}
              </Text>
              <Text style={commonStyles.PageNumber} render={({ pageNumber }) => pageNumber} />
            </View>

            <View style={[commonStyles.JustifyContentCenter,{ flex: 1, paddingHorizontal: 60 }]}>
              <Text style={styles.MainText}>
                Here is how the securities from the chosen models will be allocated
              </Text>
              <Text style={[styles.MainText, { marginTop: 5 }]}>
                for {account.name} {account.type} within the RTQ:
              </Text>

              <View key={idx} style={[commonStyles.Row, commonStyles.Width100, { marginTop: 20 }]}>
                <View style={styles.HeaderCell}>
                  <Text>{account.name} {account.type}</Text>
                </View>
              </View>

              <View style={[commonStyles.Row, commonStyles.Width100]}>
                {HeaderData.map(value => (
                  <View key={value} style={styles.SubHeaderCell}>
                    <Text>{value}</Text>
                  </View>
                ))}
              </View>

              {account?.securities?.map((sec, index) => (
                <View key={index} style={[commonStyles.Row, commonStyles.Width100]}>
                  <View key={`${index}-${idx}`} style={styles.ValueCell}>
                    <Text>{sec.ticker}</Text>
                  </View>
                  <View key={`${index}-${idx}`} style={styles.ValueCell}>
                    <Text>{sec.ticker}</Text>
                  </View>
                  <View key={`${index}-${idx}`} style={styles.ValueCell}>
                    <Text>
                      {sec.allocatedPercent === 0 ? '-' : toPercent(sec.allocatedPercent)}
                    </Text>
                  </View>
                  <View key={`${index}-${idx}`} style={styles.ValueCell}>
                    <Text>
                      {sec.allocatedPercent === 0
                        ? '-'
                        : toCurrency(sec.allocatedPercent * account.value)}
                    </Text>
                  </View>
                </View>
              ))}
              {account?.securityExceptions?.map((sec, index) => (
                <View key={index} style={[commonStyles.Row, commonStyles.Width100]}>
                  <View key={`${index}-${idx}`} style={styles.ValueCell}>
                    <Text>{sec.ticker}<Text style={styles.ExceptionTextHighlight}>*</Text></Text>
                  </View>
                  <View key={`${index}-${idx}`} style={styles.ValueCell}>
                    <Text>{sec.ticker}</Text>
                  </View>
                  <View key={`${index}-${idx}`} style={styles.ValueCell}>
                    <Text>
                      {sec.allocatedPercent === 0 ? '-' : toPercent(sec.allocatedPercent)}
                    </Text>
                  </View>
                  <View key={`${index}-${idx}`} style={styles.ValueCell}>
                    <Text>
                      {sec.allocatedPercent === 0
                        ? '-'
                        : toCurrency(sec.allocatedPercent * account.value)}
                    </Text>
                  </View>
                </View>
              ))}
              
              { account?.securityExceptions.length ? <Text style={styles.ExceptionText}>
                <Text style={styles.ExceptionTextHighlight}>*</Text>This security has not been included in the portfolio proposal report
              </Text> : <></>}
              
            </View>
          </Page>
        );
      })}
    </>
  );
};

export default ReportSecurityAllocation;
