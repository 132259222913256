import { StyleSheet } from '@react-pdf/renderer';
import { COLORS } from '../constant';

const commonStyles = StyleSheet.create({
  Root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    fontFamily: 'Raleway',
  },
  Header: {
    width: '100%',
    height: 68,
    backgroundColor: COLORS.PRIMARY,
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  HeaderTitle: {
    color: COLORS.WHITE,
    fontSize: 22,
    textTransform: 'uppercase',
    fontWeight: 'semibold',
  },
  PageNumber: {
    color: COLORS.WHITE,
    fontSize: 16,
    fontWeight: 'semibold',
  },
  BackImage: {
    position: 'absolute',
    width: '100%',
    objectFit: 'cover',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
  FooterLogo: {
    position: 'absolute',
    bottom: 20,
    right: 24,
    width: 24,
  },
  Width100: {
    width: '100%',
  },
  Content: {
    padding: 30,
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
  },
  Column: {
    display: 'flex',
    flexDirection: 'column',
  },
  JustifyContentCenter: {
    justifyContent: 'center',
  },
  JustifyContentSpaceBetween: {
    justifyContent: 'space-between',
  },
  AlignItemsCenter: {
    alignItems: 'center',
  },
  BoldFont: {
    fontWeight: 'bold',
  },
  Score: {
    color: COLORS.GREEN, 
    marginLeft: 20, 
    fontSize: 24
  },
});

export default commonStyles;
