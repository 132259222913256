import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { getScoreTitle, getScoreMeaning } from 'utils/DataUtil';
import { EntityInconsistencies } from 'data/types';

const styles = StyleSheet.create({
  Content: {
    padding: 30,
  },
  BoldFont: {
    fontWeight: 'bold',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
  },
  Column: {
    flex: 1,
    height: 200,
  },
});

interface Props {
  rtqNum: number;
  proposalInconsistencies?: EntityInconsistencies;
}

const ReportYourQScore = ({rtqNum, proposalInconsistencies} : Props) => {
  const qScoreText = proposalInconsistencies?.householdQscore.text ?? '';

  const qScoreValue = proposalInconsistencies?.householdQscore.value ?? 0;
  
  const title = getScoreTitle(qScoreValue);
  const scoreMeaning = getScoreMeaning(qScoreValue);

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>{`RTQ #${rtqNum}: BEHAVIORAL CONSISTENCY`}</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={styles.Content}>
        <Text
          style={{
            fontSize: 16,
            fontWeight: 'medium',
            lineHeight: '130%',
            color: COLORS.PRIMARY_LIGHT,
            textAlign: 'center',
            marginBottom: 30,
            marginTop: 40,
            paddingHorizontal: 30,
          }}
        >
          {qScoreText}
        </Text>

        <View style={[styles.Row, { paddingHorizontal: 30 }]}>
          <View style={[styles.Column, { backgroundColor: COLORS.PRIMARY }]}>
            <Text
              style={{
                color: COLORS.WHITE,
                fontWeight: 'bold',
                fontSize: 76,
                textAlign: 'center',
                marginTop: 20,
              }}
            >
              {qScoreValue >= 150 ? '>150' : qScoreValue}
            </Text>
            <View style={[styles.Row, { paddingHorizontal: 20, marginTop: 30 }]}>
              <View
                style={{
                  flex: 1,
                  height: 15,
                  backgroundColor: '#D54441',
                  marginRight: 5,
                  position: 'relative',
                }}
              >
                <Text
                  style={{
                    color: COLORS.WHITE,
                    marginTop: 20,
                    fontWeight: 'bold',
                    fontSize: 14,
                    textAlign: 'right',
                  }}
                >
                  100
                </Text>
                {qScoreValue < 100 && (
                  <View
                    style={{
                      position: 'absolute',
                      bottom: -5,
                      top: -5,
                      width: 25,
                      height: 25,
                      left: `${(qScoreValue / 100) * 100 - 12}%`,
                      borderRadius: '50%',
                      borderColor: '#D54441',
                      borderWidth: 3,
                      backgroundColor: COLORS.WHITE,
                    }}
                  ></View>
                )}
              </View>
              <View
                style={{
                  flex: 1,
                  height: 15,
                  backgroundColor: '#6FB956',
                  marginHorizontal: 5,
                  position: 'relative',
                }}
              >
                <Text
                  style={{
                    color: COLORS.WHITE,
                    marginTop: 20,
                    fontWeight: 'bold',
                    fontSize: 14,
                    textAlign: 'center',
                  }}
                >
                  100-110
                </Text>
                {qScoreValue >= 100 && qScoreValue < 110 && (
                  <View
                    style={{
                      position: 'absolute',
                      bottom: -5,
                      top: -5,
                      width: 25,
                      height: 25,
                      left: `${((qScoreValue - 100) / 15) * 10 - 10}%`,
                      borderRadius: '50%',
                      borderColor: '#6FB956',
                      borderWidth: 3,
                      backgroundColor: COLORS.WHITE,
                    }}
                  ></View>
                )}
              </View>
              <View
                style={{
                  flex: 1,
                  height: 15,
                  backgroundColor: '#D99C52',
                  marginLeft: 5,
                  position: 'relative',
                }}
              >
                <Text
                  style={{
                    color: COLORS.WHITE,
                    marginTop: 20,
                    fontWeight: 'bold',
                    fontSize: 14,
                    textAlign: 'left',
                  }}
                >
                  110
                </Text>
                {qScoreValue >= 110 && (
                  <View
                    style={{
                      position: 'absolute',
                      bottom: -5,
                      top: -5,
                      width: 25,
                      height: 25,
                      left: `${((qScoreValue - 110) / 40) * 40 - 10}%`,
                      borderRadius: '50%',
                      borderColor: '#D99C52',
                      borderWidth: 3,
                      backgroundColor: COLORS.WHITE,
                    }}
                  ></View>
                )}
              </View>
            </View>
          </View>
          <View
            style={[
              styles.Column,
              {
                backgroundColor: COLORS.GRAY1,
                padding: 15,
                display: 'flex',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={{
                color: COLORS.PRIMARY,
                fontSize: 16,
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              {title}
            </Text>
            <Text style={{ color: COLORS.PRIMARY_LIGHT, fontSize: 18, marginTop: 5 }}>
              {scoreMeaning.split('"')[1]}
            </Text>
            <Text style={{ color: COLORS.BLACK, fontSize: 12, marginTop: 20 }}>
              {scoreMeaning.split('"')[2]}
            </Text>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ReportYourQScore;
