import React, { ReactElement, useMemo } from 'react';

import { Box } from '@qwealth/qcore';

import AccountsItem from './AccountItem';

import { ModelSecurityMappedRecord, ProposalAccount } from 'data/types';

type Props = {
  modelSecurities: ModelSecurityMappedRecord[];
  accounts: ProposalAccount[];
  displayPercent?: boolean;
};

const Accounts: React.FC<Props> = ({ modelSecurities, accounts, displayPercent }): ReactElement => {
  const modelTotal = useMemo(() => {
    return accounts.reduce((n, { value }) => n + (value ?? 0), 0);
  }, [accounts]);

  return (
    <Box
      display="grid"
      gap="large"
      gridTemplateColumns={{
        desktop: '1fr',
      }}
    >
      {accounts.map(account => (
        <AccountsItem
          key={account.accountNumber}
          modelTotal={modelTotal}
          modelSecurities={modelSecurities}
          account={account}
          displayPercent={displayPercent}
        />
      ))}
    </Box>
  );
};

export default Accounts;
