import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { FaCog, FaSignOutAlt } from 'react-icons/fa';
import { Box, Button, logout, styled } from '@qwealth/qcore';
import { getUserAvatar } from 'services/microsoft/graphService';
import QSearch from '../QSearch';

const Avatar = styled.img`
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

const url = window.URL || window.webkitURL;

export default function (props) {
  const navigate = useNavigate();
  const user = useSelector(state => state.microsoft.user);

  const [showPopup, setShowPopup] = useState(false);
  const [source, setSource] = useState(null);
  const [initials, setInitials] = useState(null);

  useEffect(() => {
    if (!source) {
      getUserAvatar()
        .then(data => url.createObjectURL(data))
        .then(blobUrl => setSource(blobUrl))
        .catch(err => console.log(err));
    }
  }, [source]);

  useEffect(() => {
    if (user.givenName && user.surname) {
      setInitials(`${user.givenName.substring(0, 1)}${user.surname.substring(0, 1)}`);
    }
  }, [user]);

  const DefaultAvatar = (
    <Box
      borderRadius="round"
      height="50px"
      width="50px"
      bg="primary"
      fontSize="18px"
      fontWeight="bold"
      color="white"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {initials}
    </Box>
  );

  const CustomAvatar = <Avatar alt="avatar" src={source} {...props} />;

  const handleClick = () => {
    setShowPopup(false);
    navigate({ pathname: '/profile' });
  };

  const logoutHandler = async () => {
    await Auth.signOut();
    await logout();
  };

  const popover = (
    <Popover id="profile">
      <Popover.Content>
        <Button variant="outline" mb="default" onClick={handleClick}>
          <FaCog size="15px" />
          &nbsp;Settings
        </Button>
        <Button color="primary" onClick={logoutHandler}>
          <FaSignOutAlt size="15px" />
          &nbsp;Logout
        </Button>
      </Popover.Content>
    </Popover>
  );

  return (
    <Box display="flex" justifyContent="space-between" mb="large">
      <QSearch />
      <OverlayTrigger
        trigger="click"
        show={showPopup}
        onToggle={() => setShowPopup(prevState => !prevState)}
        rootClose
        placement="bottom"
        overlay={popover}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          {source ? CustomAvatar : DefaultAvatar}
          <Box ml="default" fontWeight="bold">
            {user.displayName}
          </Box>
        </Box>
      </OverlayTrigger>
    </Box>
  );
}
