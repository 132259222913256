import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BsTrash } from 'react-icons/bs';

import { Box, styled, SelectInput2 } from '@qwealth/qcore';

import ModelSetupItemDraggable from './ModelSetupItemDraggable';
import Confirmation from '../Common/Confirmation';

import { FinancialModelRecord, ModelRecord } from 'data/types';
import { FinancialModelsSelectors } from 'data/selectors';
import { selectHouseholdPartnerID } from 'data/selectors/householdSelectors';
import { removeModelFromProposal, updateProposalModel } from 'data/actions/creators/proposalInfo';
import { getLabel } from 'data/translations/defaultLabels';
import { loadModels } from 'data/actions/creators/financialModels';
import { COLORS } from 'theme/colors';

const ModelContainer = styled(Box)`
  background-color: ${COLORS.background};
  color: ${COLORS.primary};
`;

type Props = {
  index: number;
  model: ModelRecord;
  displayPercent: boolean;
};

const strategies = [
  '',
  getLabel('proposal.investment.strategy.unconstrained'),
  getLabel('proposal.investment.strategy.yield.focused'),
  getLabel('proposal.investment.strategy.tax.optimized'),
  getLabel('proposal.investment.strategy.low.cost'),
  getLabel('proposal.investment.strategy.esg'),
];

/*
 * TODO: REFACTOR
 * EXTEND QDATA TO PROVIDE THIS STATIC DATA
 */
const assetAllocations = [
  '',
  'Defensive',
  'Defensive Balanced',
  'Balanced',
  'Balanced Growth',
  'Growth',
];

const ModelSetupItem: React.FC<Props> = ({ index, model, displayPercent }) => {
  const dispatch = useDispatch();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [modelIdToDelete, setModelIdToDelete] = useState<string | undefined>(undefined);
  const [models, setModels] = useState<FinancialModelRecord[]>([]);
  const [selectedModel, setSelectedModel] = useState<FinancialModelRecord>();
  const partnerID = useSelector(selectHouseholdPartnerID);

  const allModels = useSelector(FinancialModelsSelectors.models);

  useEffect(() => {
    if (partnerID && model.strategy && model.assetAllocation) {
      dispatch(loadModels(partnerID, model.strategy, model.assetAllocation));
    }
  }, [model.strategy, model.assetAllocation, partnerID]);

  useEffect(() => {
    if (partnerID && model.strategy && model.assetAllocation) {
      const modelIdentifier = partnerID + model.strategy + model.assetAllocation;
      setModels(allModels[modelIdentifier] ?? []);
    } else {
      setModels([]);
    }
  }, [allModels, model.strategy, model.assetAllocation, partnerID]);

  useEffect(() => {
    if (selectedModel) {
      dispatch(
        updateProposalModel({
          ...model,
          modelId: selectedModel.modelId,
          modelName: selectedModel.modelName,
          weights: selectedModel.weights,
        }),
      );
    }
  }, [selectedModel]);

  useEffect(() => {
    if (selectedModel === undefined && model.modelId === undefined && models.length > 1) {
      // models[0] is the default empty model
      setSelectedModel(models[1]);
    }
  }, [selectedModel, models, model.modelId]);

  const confirmationAction = () => {
    dispatch(removeModelFromProposal(modelIdToDelete));
  };

  return (
    <Box paddingBottom={'large'}>
      <Confirmation
        showConfirmation={showConfirmation}
        title={getLabel('proposal.models.delete.confirmation.title')}
        body={getLabel('proposal.models.delete.confirmation.text')}
        setShowConfirmation={setShowConfirmation}
        confirmationAction={confirmationAction}
      />
      <ModelContainer
        width="190px"
        padding="large"
        textStyle="default"
        fontWeight="semiBold"
        textAlign="center"
        marginBottom="small"
        marginRight="large"
      >
        <Box
          display="flex"
          flexDirection="row-reverse"
          paddingX="small"
          onClick={() => {
            setModelIdToDelete(model.id);
            setShowConfirmation(true);
          }}
        >
          <BsTrash size={15} color={COLORS.lightGray} />
        </Box>

        <Box textStyle="large" fontWeight="bolder" paddingBottom="small">
          {getLabel('proposal.models.model.item.title')} {index + 1}
        </Box>

        <Box display="flex" paddingTop="small" paddingBottom="large" justifyContent="center">
          <SelectInput2
            width="100%"
            data={strategies}
            value={model.strategy}
            onChange={value => {
              dispatch(
                updateProposalModel({
                  ...model,
                  strategy: value?.toString(),
                }),
              );
            }}
          />
        </Box>

        <Box display="flex" paddingBottom="large" justifyContent="center">
          <SelectInput2
            width="100%"
            data={assetAllocations}
            value={model.assetAllocation}
            onChange={value => {
              dispatch(
                updateProposalModel({
                  ...model,
                  assetAllocation: value?.toString(),
                }),
              );
            }}
          />
        </Box>

        <Box display="flex" justifyContent="center">
          <SelectInput2
            width="100%"
            data={models}
            toValue={item => item.modelId}
            toLabel={item => item.modelName}
            value={model.modelId}
            onChange={value => {
              const selModel = models.filter(m => m.modelId == value);
              if (selModel.length) {
                setSelectedModel(selModel[0]);
              }
            }}
          />
        </Box>
      </ModelContainer>

      <ModelSetupItemDraggable index={index} model={model} displayPercent={displayPercent} />
    </Box>
  );
};

export default ModelSetupItem;
