import React, { ReactElement } from 'react';
import { BsCurrencyDollar, BsPercent } from 'react-icons/bs';

import { Box, Button, styled } from '@qwealth/qcore';

import { getLabel } from 'data/translations/defaultLabels';
import { COLORS } from 'theme/colors';

const Container = styled(Box)`
  display: flex;
  min-width: 50px;
  background-color: transparent;
  align-items: center;
`;

const ToggleButton = styled(Button)`
  background: ${COLORS.primary};
  border-radius: 83px;
  padding: 4px 4px;
  margin-left: 16px;
  min-width: 10px;

  &:before {
    background-color: ${COLORS.primaryBackground};
  }

  &:hover:not([disabled]) {
    color: ${COLORS.primary};
  }
`;

const ToggleCurrencyIcon = styled(BsCurrencyDollar)`
  background: transparent;
  color: white;
  border-radius: 83px;
  padding: 2px;
  margin-right: 8px;
`;

const ToggleCurrencyIconSelected = styled(ToggleCurrencyIcon)`
  background: white;
  color: ${COLORS.primary};
`;

const TogglePercentIcon = styled(BsPercent)`
  background: transparent;
  color: white;
  border-radius: 83px;
  padding: 2px;
`;

const TogglePercentIconSelected = styled(TogglePercentIcon)`
  background: white;
  color: ${COLORS.primary};
`;

type Props = {
  displayPercent: boolean;
  setDisplayPercent: React.Dispatch<React.SetStateAction<boolean>>;
};

const DisplayToggleContainer: React.FC<Props> = ({ displayPercent, setDisplayPercent }): ReactElement => {
  return (
    <Container textStyle="formLabel">
      {getLabel('Display')}
      <ToggleButton
        onClick={() => {
          setDisplayPercent(!displayPercent);
        }}
      >
        {displayPercent ? (
          <ToggleCurrencyIcon size="24" />
        ) : (
          <ToggleCurrencyIconSelected size="24" />
        )}
        {displayPercent ? (
          <TogglePercentIconSelected size="24" />
        ) : (
          <TogglePercentIcon size="24" />
        )}
      </ToggleButton>
    </Container>
  );
};

export default DisplayToggleContainer;
