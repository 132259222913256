import React, { useMemo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';

import { EntityAssetRange, EntityMandate } from 'data/types';
import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';

const styles = StyleSheet.create({
  MainText: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    textAlign: 'center',
  },
  Content: {
    diplay: 'flex',
    width: '100%',
    marginVertical: 30,
    paddingHorizontal: 30,
    justifyContent: 'space-between',
  },
  LeftPane: {
    display: 'flex',
    width: '40%',
  },
  RightPane: {
    width: '55%',
    display: 'flex',
  },
  HeaderCell: {
    display: 'flex',
    flexDirection: 'row',
    height: 40,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  HeaderCell2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    height: 40,
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderBottomWidth: 2,
    borderBottomColor: COLORS.WHITE,
  },
  ValueCell: {
    fontSize: 10,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
  },
  Column: {
    display: 'flex',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
  },
  BoldFont: {
    fontWeight: 'bold',
  },
});

const crateChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets'],
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets,
  },
});

interface Props {
  rtqNum: number;
  proposalAssetRanges?: EntityAssetRange[];
  proposalMandate?: EntityMandate;
}

const ReportInvestmentObjectives1 = ({ rtqNum, proposalAssetRanges, proposalMandate }: Props) => {
  const chartData = useMemo(() => {
    return {
      datasets: [
        {
          data: proposalAssetRanges?.map(a => a.target) ?? [],
          backgroundColor: [COLORS.PRIMARY, '#c0d8d3', '#2371bb', '#21c9c3'],
        },
      ],
    };
  }, [proposalAssetRanges]);

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>{`RTQ #${rtqNum}: INVESTMENT OBJECTIVES`}</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={{ marginTop: 30 }}>
        <Text style={styles.MainText}>For these accounts, the following information</Text>
      </View>
      <View>
        <Text style={styles.MainText}>
          regarding their investment objectives has been provided:
        </Text>
      </View>
      <View style={{ marginTop: 10 }}>
        <Text style={{ fontSize: 14, textAlign: 'center' }}>
          <Text style={{ fontWeight: 'bold' }}>Mandate:</Text> {proposalMandate?.mandate}
        </Text>
      </View>

      <View style={styles.Content}>
        <View style={styles.Row}>
          <View
            style={[
              { width: '40%' },
              commonStyles.JustifyContentCenter,
              commonStyles.AlignItemsCenter,
            ]}
          >
            {useChartJsAsImage(crateChartJsConfig('doughnut', [], chartData.datasets))}
          </View>
          <View style={{ flex: 1 }}>
            <View style={styles.Row}>
              <View
                style={[styles.HeaderCell, { width: '45%', textAlign: 'left', paddingLeft: 10 }]}
              >
                <Text>Asset Class</Text>
              </View>
              <View style={[styles.HeaderCell, { width: '55%' }]}>
                <View style={{ width: '30%', textAlign: 'center' }}>
                  <Text>Target</Text>
                </View>
                <View style={{ width: '70%', display: 'flex', flexDirection: 'column' }}>
                  <View
                    style={{
                      textAlign: 'left',
                      height: '50%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Text>Asset Allocation Ranges</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', height: '50%' }}>
                    <View
                      style={{
                        flex: 1,
                        backgroundColor: COLORS.PRIMARY,
                        textAlign: 'center',
                        height: '100%',
                        justifyContent: 'center',
                        borderRightWidth: 2,
                        borderRightColor: COLORS.WHITE,
                      }}
                    >
                      <Text>Minimum</Text>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        backgroundColor: COLORS.PRIMARY,
                        textAlign: 'center',
                        height: '100%',
                        justifyContent: 'center',
                      }}
                    >
                      <Text>Maximum</Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            {proposalAssetRanges &&
              proposalAssetRanges.map((assetClass, index) => (
                <View key={index} style={styles.Row}>
                  <View
                    style={[styles.ValueCell, { width: '45%', textAlign: 'left', paddingLeft: 10 }]}
                  >
                    <Text>{assetClass.asset}</Text>
                  </View>
                  <View style={[styles.ValueCell, { width: '18%' }]}>
                    <Text>{Math.round(assetClass.target * 100)}%</Text>
                  </View>
                  <View style={[styles.ValueCell, { width: '18%' }]}>
                    <Text>{Math.round(assetClass.min * 100)}%</Text>
                  </View>
                  <View style={[styles.ValueCell, { width: '18%' }]}>
                    <Text>{Math.round(assetClass.max * 100)}%</Text>
                  </View>
                </View>
              ))}
          </View>
        </View>

        <View style={[styles.Row, { marginTop: 40 }]}>
          <View
            style={{
              flex: 1,
              backgroundColor: COLORS.GRAY1,
              marginRight: 10,
              paddingHorizontal: 10,
              paddingVertical: 20,
              fontSize: 12,
            }}
          >
            <Text style={{ color: COLORS.PRIMARY_LIGHT, fontWeight: 'bold' }}>OBJECTIVE</Text>
            <View style={[styles.Row, { fontSize: 10, marginTop: 15 }]}>
              <View style={{ flex: 1 }}>
                <View>
                  <Text style={{ fontWeight: 'bold' }}>PRIMARY</Text>
                </View>
                <View style={{ marginTop: 3 }}>
                  <Text>{proposalMandate?.objectivePrimaryChoice}</Text>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <View>
                  <Text style={{ fontWeight: 'bold' }}>SECONDARY</Text>
                </View>
                <View style={{ marginTop: 3 }}>
                  <Text>{proposalMandate?.objectiveSecondaryChoice}</Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              backgroundColor: COLORS.GRAY1,
              marginLeft: 10,
              paddingHorizontal: 10,
              paddingVertical: 20,
              fontSize: 12,
            }}
          >
            <Text>
              <Text style={{ fontWeight: 'bold' }}>RISK: </Text>
              {proposalMandate?.riskScore}
            </Text>
            <View style={[styles.Row, { fontSize: 10, marginTop: 15 }]}>
              <View style={{ flex: 1 }}>
                <View>
                  <Text style={{ fontWeight: 'bold' }}>ABILITY</Text>
                </View>
                <View style={{ marginTop: 3 }}>
                  <Text>{proposalMandate?.abilityScore}</Text>
                </View>
              </View>
              <View style={{ flex: 1 }}>
                <View>
                  <Text style={{ fontWeight: 'bold' }}>WILLINGNESS</Text>
                </View>
                <View style={{ marginTop: 3 }}>
                  <Text>{proposalMandate?.willingnessScore}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ReportInvestmentObjectives1;
