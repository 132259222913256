import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';

import { Box } from '@qwealth/qcore';

import HouseholdHeader from './Header';
import ProposalSetup from './Setup';
import ProposalOverview from '../Overview';

import { loadHouseholdMetadata } from 'data/actions/creators/householdMetadata';
import { selectHouseholdMetadata } from 'data/selectors/householdSelectors';
import { loadSummary } from 'data/actions/creators/proposalInfo';

const ProposalDetails: React.FC = (): ReactElement => {
  const householdMetadata = useSelector(selectHouseholdMetadata);
  const [householdId, setHouseholdId] = useState('');

  const [setupProposal, setSetupProposal] = useState(false);
  const [selected, setSelected] = useState('rtq1');
  const location = useLocation();
  const dispatch = useDispatch();
  const { householdQID } = useParams<{ householdQID: string }>();

  useEffect(() => {
    if (householdId && householdId !== householdMetadata.QID && !householdMetadata.isLoading) {
      dispatch(loadHouseholdMetadata(householdId));
      dispatch(loadSummary(householdId));
    }
  }, [dispatch, householdId, householdMetadata]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    if (!householdQID) {
      console.error('No household selected.');
      return;
    }

    setHouseholdId(householdQID);

    const tab = urlParams.get('householdTab');
    if (tab && tab !== selected) {
      setSelected(tab);
    }
  }, [location, dispatch, selected, householdQID]);

  return (
    <Box display="grid" gap="large">
      <HouseholdHeader />

      {setupProposal ? (
        <ProposalSetup householdId={householdId} setSetupProposal={setSetupProposal} />
      ) : (
        <ProposalOverview householdId={householdId} setSetupProposal={setSetupProposal} />
      )}
    </Box>
  );
};

export default ProposalDetails;
