import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { EntityInconsistencies, ProposalOwner } from 'data/types';

const styles = StyleSheet.create({
  Content: {
    padding: 30,
  },
  BoldFont: {
    fontWeight: 'bold',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
  },
  Column: {
    flex: 1,
    height: 200,
  },
});

interface Props {
  rtqNum: number;
  proposalInconsistencies?: EntityInconsistencies;
  proposalOwners?: Array<ProposalOwner>;
}

const ReportBehavioralConsistency = ({rtqNum, proposalInconsistencies, proposalOwners} : Props) => {
  const consistent = proposalOwners?.every(o => proposalInconsistencies?.clientQbias[o.QID].consistent);

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>{`RTQ #${rtqNum}: BEHAVIORAL CONSISTENCY`}</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={styles.Content}>
        <Text
          style={{
            fontSize: 16,
            fontWeight: 'medium',
            lineHeight: '130%',
            color: COLORS.PRIMARY_LIGHT,
            textAlign: 'center',
            marginBottom: 30,
            marginTop: 40,
            paddingHorizontal: 30,
          }}
        >
          {`We have compared the QBias scores of the owners of the accounts under the RTQ, and have
          found that the risk levels they have indicated for the RTQ are ${consistent ? 'consistent' : 'inconsistent'}
          with their inherent investment biases.`}
        </Text>

        <View style={[styles.Row, { paddingHorizontal: 30 }]}>
          <View
            style={[
              styles.Column,
              {
                backgroundColor: COLORS.GRAY1,
                padding: 15,
                display: 'flex',
                justifyContent: 'center',
              },
            ]}
          >
            <Text
              style={{
                color: COLORS.PRIMARY,
                fontSize: 16,
                fontWeight: 'bold',
                textTransform: 'uppercase',
              }}
            >
              LOSS AVERSION & CERTAINTY
            </Text>
            {proposalOwners?.map((o) => {
              const bias = proposalInconsistencies?.clientQbias[o.QID];
              return <>
                <Text style={{ color: COLORS.PRIMARY_LIGHT, fontSize: 14, marginTop: 10 }}>
                  {o.name}
                </Text>
                <Text style={{ color: COLORS.BLACK, fontSize: 12, marginTop: 5 }}>
                  {bias?.text}
                </Text>
              </>
            })}
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ReportBehavioralConsistency;
