import { camelCase } from 'lodash';

import {
  EntityFinancialModels,
  FinancialModelRecord,
  FinancialModels,
  WeightRecord,
} from '../types';

export const mapFinancialModelsData = (models: EntityFinancialModels): FinancialModels => {
  const financialModels: FinancialModels = {};
  const defaultModel: FinancialModelRecord = {
    modelId: 0,
    modelName: '',
    weights: {
      alternatives: 0,
      cash: 0,
      equity: 0,
      fixedIncome: 0,
    },
    securities: [],
  };

  for (const keyModel in models) {
    const model = models[keyModel];

    for (const mandate in model) {
      const assetAllocation = model[mandate].assetAllocation;
      const portfolio = model[mandate].portfolio;

      const weights = {
        ...defaultModel.weights,
      };

      for (const aaKeyModel in assetAllocation) {
        // TO DO: Remove once BE is returning it properly
        const label = camelCase(aaKeyModel);
        weights[label] = assetAllocation[aaKeyModel]['target'] * 100 ?? 0;
      }

      for (const strategy in portfolio) {
        const modelRecords = portfolio[strategy];

        modelRecords.forEach(modelRecord => {
          const financialModelIdentifier = keyModel + strategy + mandate;
          const { id, name, securities } = modelRecord;

          const mappedSecurities = securities.map(s => {
            return {
              id: s.id,
              security: s.name,
              ticker: s.ticker,
              totalPercent: s.weight,
            };
          });

          const fModelRec: FinancialModelRecord = {
            modelId: id,
            modelName: name,
            weights: weights as WeightRecord,
            securities: mappedSecurities,
          };

          if (financialModels[financialModelIdentifier]) {
            financialModels[financialModelIdentifier].push(fModelRec);
          } else {
            financialModels[financialModelIdentifier] = [defaultModel, fModelRec];
          }
        });
      }
    }
  }

  return financialModels;
};
