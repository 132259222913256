import React, { Dispatch, FC, ReactElement, SetStateAction, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from '@qwealth/qcore';

import Header from './Header';
import RtqContainer from './RtqContainer';

import { ProposalInfoSelectors } from 'data/selectors';
import { loadSummary } from 'data/actions/creators/proposalInfo';

type Props = {
  householdId: string;
  setSetupProposal: Dispatch<SetStateAction<boolean>>;
};

const ProposalOverview: FC<Props> = ({ householdId, setSetupProposal }): ReactElement => {
  const dispatch = useDispatch();
  const summary = useSelector(ProposalInfoSelectors.summary);

  useEffect(() => {
    if (householdId) {
      dispatch(loadSummary(householdId));
    }
  }, [householdId]);

  return (
    <Box>
      <Header setSetupProposal={setSetupProposal} />

      <Box
        display="grid"
        gap="large"
        gridTemplateColumns={{
          desktop: '0.75fr',
        }}
      >
        {summary?.rtqs.map((rtq, idx) => (
          <RtqContainer key={rtq.id} rtq={rtq} idx={idx} />
        ))}
      </Box>
    </Box>
  );
};

export default ProposalOverview;
