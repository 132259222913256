import React from 'react';
import { Button, Box, styled, themeGet } from '@qwealth/qcore';
import { COLORS } from 'theme/colors';

interface NavItem {
  label: string;
  id: string;
}

export interface TopNavigationProps {
  navItems: Array<NavItem>;
  selectedId: string;
  onSelect: (id: string) => void;
}

const getActiveStyles = props => {
  if (!props.isSelected) return '';

  return `
    background-color: ${COLORS.primaryBackground};
    color: ${COLORS.primary};
    font-weight: bold;
    border: 1px solid ${COLORS.primary};
    border-radius: 8px;
  `;
};

const StyledButton = styled(Button)`
  background-color: ${themeGet('colors.gray.3')};
  color: ${COLORS.lightGray};
  font-weight: 700;
  min-width: initial;
  flex: 1;
  ${getActiveStyles};

  :hover {
    font-weight: bold;
  }

  :before {
    background-color: #ffffff;
  }
`;

const TopNavigation: React.FC<TopNavigationProps> = ({
  selectedId,
  navItems = [],
  onSelect,
  ...props
}) => (
  <Box borderRadius="default" p="small" display="flex" gap="default" {...props}>
    {navItems.map(item => (
      <StyledButton
        key={item.id}
        isSelected={item.id === selectedId}
        onClick={() => onSelect(item.id)}
      >
        {item.label}
      </StyledButton>
    ))}
  </Box>
);

export default TopNavigation;
