import { ScoreTuple } from '../interfaces/AggregatedAnalyticsData';

export type ScoreValueLabel = {
  scoreValue: string;
  scoreLabel: string;
};

export const getScoreValueLabel = (score?: ScoreTuple): ScoreValueLabel => {
  return {
    scoreValue: score?.length ? `${score[0]}%` : '-',
    scoreLabel: score?.length == 2 ? score[1] : '',
  };
};
