import React, { ReactElement, useState, useEffect, useCallback } from 'react';

import { Box, styled } from '@qwealth/qcore';
import { toCurrency, toPercent } from '@qwealth/qcore/utils';

import StyledTable from '../Common/StyledTable';

import { ModelSecurityMappedRecord, ProposalAccount } from 'data/types';
import { getLabel } from 'data/translations/defaultLabels';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const TotalContainer = styled(Box)`
  display: flex;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
`;

const EmptyTextContainer = styled(Box)`
  flex: 1;
  padding: 1rem 1rem;
`;

const TextContainer = styled(EmptyTextContainer)`
  background-color: ${COLORS.grayBackground};
  color: ${COLORS.gray};
`;

const CellContainer = styled(Box)`
  text-align: center;
  padding: 0.5rem 0rem;
`;

const GreenContainer = styled(CellContainer)`
  background-color: ${COLORS.greenBackground};
`;

const YellowContainer = styled(CellContainer)`
  background-color: ${COLORS.primaryBackgroundLight};
  color: ${COLORS.primary};
`;

const RedContainer = styled(CellContainer)`
  background-color: ${COLORS.redBackground};
  color: ${COLORS.red};
`;

const securityColumnsBase = [
  { dataField: 'security', text: getLabel('proposal.allocation.security.header') },
  { dataField: 'ticker', text: getLabel('proposal.allocation.symbol.header') },
];

const RemainingCellRenderer = ({ tolerance, value, isPercent = false }) => {
  const magnitude = isPercent ? Math.abs(Math.round(value)) * 100 : Math.abs(Math.round(value));
  if (magnitude > 0 && magnitude <= tolerance) {
    return <YellowContainer>{isPercent ? toPercent(value) : toCurrency(value)}</YellowContainer>;
  } else if (magnitude > tolerance) {
    return <RedContainer>{isPercent ? toPercent(value) : toCurrency(value)}</RedContainer>;
  }

  return <GreenContainer>-</GreenContainer>;
};

const securityColumnsCurrency = [
  {
    dataField: 'total',
    text: `${getLabel('proposal.allocation.total.weight.header')} $`,
    formatter: v => (v === 0 ? '-' : toCurrency(v)),
  },
  {
    dataField: 'allocated',
    text: `${getLabel('proposal.allocation.allocated.header')} $`,
    formatter: v => (v === 0 ? '-' : toCurrency(v)),
  },
  {
    dataField: 'remaining',
    text: `${getLabel('proposal.allocation.remaining.header')} $`,
    formatter: (value, row) => {
      const tolerance = row.total * 0.01;
      return <RemainingCellRenderer tolerance={tolerance} value={value} />;
    },
  },
];

const securityColumnsPercent = [
  {
    dataField: 'totalPercent',
    text: `${getLabel('proposal.allocation.total.weight.header')} %`,
    formatter: v => (v === 0 ? '-' : toPercent(v)),
  },
  {
    dataField: 'allocatedPercent',
    text: `${getLabel('proposal.allocation.allocated.header')} %`,
    formatter: v => (v === 0 ? '-' : toPercent(v)),
  },
  {
    dataField: 'remainingPercent',
    text: `${getLabel('proposal.allocation.remaining.header')} %`,
    formatter: value => {
      return <RemainingCellRenderer tolerance={1} value={value} isPercent />;
    },
  },
];

type Props = {
  modelSecurities: ModelSecurityMappedRecord[];
  accounts: ProposalAccount[];
  displayPercent?: boolean;
};

const Securities: React.FC<Props> = ({
  modelSecurities,
  accounts,
  displayPercent,
}): ReactElement => {
  // use modelSecurities and accounts to build the required data set for Securities
  const [totalValue, setTotalValue] = useState(0);
  const [allocatedValue, setAllocatedValue] = useState(0);
  const [remainingValue, setRemainingValue] = useState(0);
  const [securityColumns, setSecurityColumns] = useState(securityColumnsBase);

  useEffect(() => {
    const summary = modelSecurities.reduce(
      (
        prevValue,
        { total, totalPercent, allocated, allocatedPercent, remaining, remainingPercent },
      ) => {
        return {
          totalValue: prevValue.totalValue + (displayPercent ? totalPercent : total),
          allocatedValue:
            prevValue.allocatedValue + (displayPercent ? allocatedPercent : allocated),
          remainingValue:
            prevValue.remainingValue + (displayPercent ? remainingPercent : remaining),
        };
      },
      {
        totalValue: 0,
        allocatedValue: 0,
        remainingValue: 0,
      },
    );

    setTotalValue(summary.totalValue);
    setAllocatedValue(summary.allocatedValue);
    setRemainingValue(summary.remainingValue);
  }, [modelSecurities, accounts, displayPercent]);

  useEffect(() => {
    const selectedColumns = displayPercent ? securityColumnsPercent : securityColumnsCurrency;
    setSecurityColumns([...securityColumnsBase, ...selectedColumns]);
  }, [displayPercent]);

  const formatValue = useCallback(
    val => {
      return val === 0 ? '-' : displayPercent ? toPercent(val) : toCurrency(val);
    },
    [displayPercent],
  );

  return (
    <Box display="flex" gap="small">
      <StyledTable accountColumns={securityColumns} records={modelSecurities} hideTotal light>
        <TotalContainer>
          <EmptyTextContainer />
          <EmptyTextContainer />
          <TextContainer>{formatValue(totalValue)}</TextContainer>
          <TextContainer>{formatValue(allocatedValue)}</TextContainer>
          <TextContainer textAlign="center">{formatValue(remainingValue)}</TextContainer>
        </TotalContainer>
      </StyledTable>
    </Box>
  );
};

export default Securities;
