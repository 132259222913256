import { IProposalInfoState } from '../reducers/proposalInfo';
import { createSelector } from 'reselect';

type ProposalInfoState = {
  proposalInfo: IProposalInfoState;
};

const selectProposalInfo = (state: ProposalInfoState) => state.proposalInfo;

export const ProposalInfoSelectors = {
  rtqId: createSelector(selectProposalInfo, proposalInfo => proposalInfo.rtqId),

  isGettingSummary: createSelector(
    selectProposalInfo,
    proposalInfo => proposalInfo.isGettingSummary,
  ),
  summary: createSelector(selectProposalInfo, proposalInfo => proposalInfo.summary || undefined),

  isGettingOwners: createSelector(selectProposalInfo, proposalInfo => proposalInfo.isGettingOwners),
  owners: createSelector(selectProposalInfo, proposalInfo => proposalInfo.owners || []),
  isLegalEntityOwners: createSelector(selectProposalInfo, proposalInfo => (proposalInfo.owners || []).every(o => o.QID.includes('-3-'))),

  isGettingAccounts: createSelector(
    selectProposalInfo,
    proposalInfo => proposalInfo.isGettingAccounts,
  ),
  accounts: createSelector(selectProposalInfo, proposalInfo => proposalInfo.accounts || []),

  isGettingMandate: createSelector(
    selectProposalInfo,
    proposalInfo => proposalInfo.isGettingMandate,
  ),
  mandate: createSelector(selectProposalInfo, proposalInfo => proposalInfo.mandate),
  
  isGettingInconsistencies: createSelector(
    selectProposalInfo,
    proposalInfo => proposalInfo.isGettingInconsistencies,
  ),
  inconsistencies: createSelector(selectProposalInfo, proposalInfo => proposalInfo.inconsistencies),

  isGettingAssetRanges: createSelector(
    selectProposalInfo,
    proposalInfo => proposalInfo.isGettingAssetRanges,
  ),
  assetRanges: createSelector(selectProposalInfo, proposalInfo => proposalInfo.assetRanges || []),

  models: createSelector(selectProposalInfo, proposalInfo => proposalInfo.models || []),
  defaultInvestmentStrategy: createSelector(
    selectProposalInfo,
    proposalInfo => proposalInfo.defaultInvestmentStrategy,
  ),

  // Some helper selector to consolidate logic computation in one place
  accountCount: createSelector(selectProposalInfo, proposalInfo => {
    return proposalInfo.models?.reduce((n, { accounts }) => n + accounts.length, 0) ?? 0;
  }),

  modelModified: createSelector(selectProposalInfo, proposalInfo => {
    const currentMap = {};
    proposalInfo.accounts.forEach(a => (currentMap[a.accountNumber] = a.modelId));

    return proposalInfo.models.some(m =>
      m.accounts?.some(a => currentMap[a.accountNumber] !== m.modelId),
    );
  }),

  allocationComplete: createSelector(selectProposalInfo, proposalInfo => {
    return proposalInfo.models.every(model => {
      return model.accounts?.every(account => {
        const allocated = (account.securities?.reduce((n, { allocatedPercent }) => n + allocatedPercent, 0) ?? 0) + account.securityAllocatedPercent;
        const allocatedP = Math.round(allocated * 100);
        return allocatedP === 100;
      });
    });
  }),

  allocationModified: createSelector(selectProposalInfo, proposalInfo => {
    const currentMap = {};
    proposalInfo.accounts.forEach(a => {
      a.securities?.forEach(
        s => (currentMap[a.modelId + a.accountNumber + s.ticker] = s.allocatedPercent),
      );
    });

    return proposalInfo.models.some(m =>
      m.accounts?.some(a =>
        a.securities?.some(
          s => currentMap[m.modelId + a.accountNumber + s.ticker] !== s.allocatedPercent,
        ),
      ),
    );
  }),
};
