import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from '@qwealth/qcore';
import { dismissNotification } from 'data/actions/creators/notifications';

export default function Notifcations() {
  const notifications = useSelector(state => state.notifications);
  const dispatch = useCallback(useDispatch(), []);
  return (
    <>
      {notifications.length > 0 && (
        <div style={{ position: 'absolute', right: 0, bottom: 0 }}>
          {notifications.map(n => (
            <Toast
              key={n.notificationUuid}
              variant={n.level}
              title={n.level}
              metadata={n.timeStamp}
              onClose={() => dispatch(dismissNotification(n.notificationUuid))}
            >
              {n.text}
            </Toast>
          ))}
        </div>
      )}
    </>
  );
}
