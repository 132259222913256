/* eslint-disable */
// @ts-nocheck

import { compose } from 'ramda';
import { createSelector } from 'reselect';

import { IPerson } from '@qwealth/qdata';

export const selectHouseholdList = ({ households }) => households.householdList || [];

export const selectHouseholdMetadata = state => state.householdMetadata || {};

export const selectHouseholdMembers = state => selectHouseholdMetadata(state).members || [];

export const selectHouseholdPartnerID = state =>
  selectHouseholdMetadata(state).partnerID || undefined;

export const selectHouseholdMembersInOrder = createSelector(selectHouseholdMembers, members =>
  members.sort((m1, m2) => {
    if (m1.memberType === m2.memberType) {
      return 0;
    }
    return m1.memberType === 'Primary' ? -1 : 1;
  }),
);

const findByType = (type: string) => (members: Array<IPerson>) =>
  members.find(({ memberType }) => memberType === type) || {};

export const selectPrimary = compose(findByType('Primary'), selectHouseholdMembers);

export const selectSecondary = createSelector(selectHouseholdMembers, findByType('Secondary'));
