import React, { FC, ReactElement } from 'react';

import { Box } from '@qwealth/qcore';

import Owners from './Owners';
import Accounts from './Accounts';
import AssetRanges from '../Common/AssetRanges';
import Mandate from '../Common/Mandate';

const Information: FC = (): ReactElement => {
  return (
    <Box
      display="grid"
      gap="large"
      gridTemplateColumns={{
        desktop: '1fr 1.75fr',
      }}
    >
      <Owners />
      <Accounts />
      <Mandate />
      <AssetRanges />
    </Box>
  );
};

export default Information;
