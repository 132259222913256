import React, { Dispatch, ReactElement, SetStateAction, useCallback, useState } from 'react';

import { Box, styled, toCurrency, toPercent } from '@qwealth/qcore';

import Confirmation from '../Common/Confirmation';
import AccountItem from './AccountItem';
import AccountSecurityItem, { HeaderContainer } from './AccountSecurityItem';

import { getLabel } from 'data/translations/defaultLabels';
import { EntitySecurityException, ProposalAccount } from 'data/types';
import { useDispatch } from 'react-redux';
import { deleteSecurityException } from 'data/actions/creators/securityException';
import { RecordType } from './Accounts';
import { COLORS } from 'theme/colors';

const HeaderItem = styled(Box)`
  text-align: left;
  text-transform: uppercase;
`;

const TotalContainer = styled(Box)`
  background-color: ${COLORS.primaryBackground};
  color: ${COLORS.primary};
`;

type Column = {
  dataField: string;
  text: string;
};

type Props = {
  accountColumns: Column[];
  records: RecordType[];
  displayPercent: boolean;
  totalValue: number;
  light?: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setAccount: Dispatch<SetStateAction<ProposalAccount | undefined>>;
  setSecurityException: Dispatch<SetStateAction<EntitySecurityException|undefined>>;
};


const AccountsTable: React.FC<Props> = ({
  records,
  displayPercent,
  totalValue,
  setShowModal,
  setAccount,
  setSecurityException,
}): ReactElement => {
  const dispatch = useDispatch();
  const [exceptionId, setExceptionId] = useState<number | undefined>();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const confirmationAction = useCallback(() => {
    dispatch(deleteSecurityException(exceptionId));
    setShowConfirmation(false);
  }, [exceptionId]);

  return (
    <>
      <Confirmation
        showConfirmation={showConfirmation}
        title={getLabel('proposal.models.security.exception.confirmation.title')}
        body={getLabel('proposal.models.security.exception.confirmation.text')}
        setShowConfirmation={setShowConfirmation}
        confirmationAction={confirmationAction}
      />
      
      <HeaderContainer>
        <Box flex={1}><HeaderItem>{getLabel('common.ownership')}</HeaderItem></Box>
        <Box flex={0.55}><HeaderItem>{getLabel('account.type')}</HeaderItem></Box>
        <Box flex={0.75}><HeaderItem>{getLabel('common.value')}</HeaderItem></Box>
        <Box flex={0.10}><HeaderItem></HeaderItem></Box>
        <Box flex={0.20}><HeaderItem></HeaderItem></Box>
      </HeaderContainer>

      <Box display='flex' flexDirection='column'>
        {records.map((r => {
          if(r.account.securityExceptions.length === 0) {
            return <AccountItem data={r} displayValue={displayPercent ? toPercent(r.value/totalValue) : toCurrency(r.value)} />
          } else {
            return <AccountSecurityItem 
              data={r} 
              setExceptionId={setExceptionId}
              setShowConfirmation={setShowConfirmation}
              displayPercent={displayPercent}
              totalValue={totalValue}
              setShowModal={setShowModal}
              setAccount={setAccount}
              setSecurityException={setSecurityException}
            />
          }
        }))}
        <TotalContainer
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="large"
          textStyle="sectionTitle"
          borderRadius="large"
        >
          <Box>{getLabel('common.total')}</Box>
          <Box marginRight="small">{displayPercent ? toPercent(1) : toCurrency(totalValue)}</Box>
        </TotalContainer>
      </Box>
    </>
  );
};

export default AccountsTable;
