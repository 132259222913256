import { PUT_CURRENT_USER, PUT_CURRENT_PARTNER } from '../actions/types';

const defaultState = {
  user: null,
  partner: null,
  initializedUser: false,
  initializedPartner: false,
};
export default function (state = defaultState, action) {
  switch (action.type) {
    case PUT_CURRENT_USER:
      return { ...state, user: action.payload, initializedUser: true };
    case PUT_CURRENT_PARTNER:
      return { ...state, partner: action.payload, initializedPartner: true };
    default:
      return state;
  }
}
