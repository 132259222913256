import React, { ReactElement } from 'react';
import { BsExclamationTriangle, BsCheck2 } from 'react-icons/bs';

import { Box, Card, styled } from '@qwealth/qcore';
import { COLORS } from 'theme/colors';

const StyledIcon = styled(Box)`
  background-color: ${COLORS.redBackground};
  border-radius: 50%;
  width: 68px;
  height: 68px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledGreenIcon = styled(StyledIcon)`
  background-color: ${COLORS.greenBackgroundLight};
`;

type Props = {
  header?: string;
  consistent?: boolean;
  bodyText?: string;
  value?: number;
};

const InconsistencyItem: React.FC<Props> = ({
  header,
  consistent,
  bodyText = '',
  value = 0,
}): ReactElement => {
  const getParsedBodyText = () => {
    const splitString = bodyText.split(String(value));
    return (
      <div>
        {splitString.map((s, index) => (
          <span>
            {s.replace('>', '')}
            {index === 0 && <b> {value >= 150 ? '>150' : value}</b>}
          </span>
        ))}
      </div>
    );
  };

  return (
    <>
      <Card
        display="flex"
        flexDirection="row"
        backgroundColor={consistent ? 'greenTransparent' : 'redTransparent'}
        marginBottom="small"
      >
        {consistent ? (
          <StyledGreenIcon>
            <BsCheck2 color={COLORS.green} size="32" />
          </StyledGreenIcon>
        ) : (
          <StyledIcon>
            <BsExclamationTriangle color={COLORS.red} size="28" />
          </StyledIcon>
        )}

        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          fontSize="sectionTitle"
        >
          {header && <Box textStyle="sectionTitle">{header}</Box>}
          {getParsedBodyText()}
        </Box>
      </Card>
    </>
  );
};

export default InconsistencyItem;
