import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { Box, Button, styled, Card } from '@qwealth/qcore';

import AccountTable from '../Common/AccountTable';
import RtqHeader from './RtqHeader';
import RtqDoughnut from './RtqDoughnut';

import { ProposalInfoSelectors } from 'data/selectors';
import { ProposalRtq } from 'data/types';
import { COLORS } from 'theme/colors';

const StyledButton = styled(Button)`
  background-color: ${COLORS.primary};
  min-width: 50px;
  max-height: 40px;
`;

type Props = {
  idx: number;
  rtq: ProposalRtq;
};

const ProposalOverview: FC<Props> = ({ idx, rtq }): ReactElement => {
  const isLoading = useSelector(ProposalInfoSelectors.isGettingSummary);

  return (
    <Card
      loading={isLoading}
      display="flex"
      flexDirection="row"
      marginBottom={'large'}
      paddingX={'large'}
      gap="default"
    >
      <Box flex={3}>
        <RtqHeader rtq={rtq} idx={idx} />
        <AccountTable isLoading={isLoading} accounts={rtq.accounts} />
      </Box>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingTop="small"
        height={275}
      >
        <RtqDoughnut rtq={rtq} />
      </Box>
    </Card>
  );
};

export default ProposalOverview;
