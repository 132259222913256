import axios from 'axios';
import { QWealthAuthorizationInterceptor } from '@qwealth/qdata';
import { AXIOS_INITIALIZED } from '../types';

const initializeAxios = () => ({ type: AXIOS_INITIALIZED });

export const configureAxios = () => async dispatch => {
  // interceptor to set the authorization only on QW API requests
  axios.interceptors.request.use(QWealthAuthorizationInterceptor);

  dispatch(initializeAxios());
  return Promise.resolve(true);
};
