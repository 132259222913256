import { errorHandler } from '../../../services/axiosService';

import {
  GET_FINANCIAL_MODELS_REQUEST,
  GET_FINANCIAL_MODELS_SUCCESS,
  GET_FINANCIAL_MODELS_FAILURE,
} from '../types';

import { loadFinancialModels } from '../../data-layer';

const getFinancialModels = () => ({ type: GET_FINANCIAL_MODELS_REQUEST });
const getFinancialModelsSuccess = data => ({ type: GET_FINANCIAL_MODELS_SUCCESS, payload: data });
const getFinancialModelsFailure = () => ({ type: GET_FINANCIAL_MODELS_FAILURE });

export const loadModels = (partnerId, strategy, mandate) => async dispatch => {
  try {
    dispatch(getFinancialModels());
    const data = await loadFinancialModels(partnerId, strategy, mandate);
    if (data) {
      dispatch(getFinancialModelsSuccess(data));
    } else {
      dispatch(getFinancialModelsFailure());
    }
  } catch (e) {
    dispatch(getFinancialModelsFailure());
    errorHandler(dispatch, 'Loading financial models failed')(e);
  }
};
