import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BsExclamationTriangleFill, BsCheckLg, BsExclamationCircleFill, BsFillQuestionCircleFill } from 'react-icons/bs';
import { Tooltip } from 'react-tooltip';

import { Box, Card } from '@qwealth/qcore';
import { toCurrency } from '@qwealth/qcore/utils';
import { TitlePrimary } from 'components/common/Title';

import { ProposalInfoSelectors } from '../../../data/selectors';
import { getLabel } from 'data/translations/defaultLabels';
import StyledTable from '../Common/StyledTable';
import { EntityQScoreAllocation, WeightRecord } from 'data/types';
import { COLORS } from 'theme/colors';

const accountColumns = [
  { dataField: 'name', text: getLabel('common.ownership') },
  { dataField: 'qscoreAllocation', text: getLabel('common.qscore.allocation') },
  { dataField: 'accountNumber', text: getLabel('account.number.short') },
  { dataField: 'value', text: getLabel('common.value') },
];

const ALLOWED_DIFFERENCE = 0.2;

type RecordType = {
  name: string;
  qscoreAllocation: JSX.Element;
  accountNumber: string;
  value: string;
};

const Accounts: React.FC = (): ReactElement => {
  const isLoading = useSelector(ProposalInfoSelectors.isGettingAccounts);
  const data = useSelector(ProposalInfoSelectors.accounts);

  const rtqId = useSelector(ProposalInfoSelectors.rtqId);
  const summary = useSelector(ProposalInfoSelectors.summary);

  const [records, setRecords] = useState<RecordType[]>([]);
  const totalValue = useMemo(() => {
    return data.reduce((n, { value }) => n + value, 0);
  }, [data])

  const mandateAllocation = useMemo(() => {
    const allocations = summary?.rtqs.filter((rtq) => rtq.id == rtqId);
    
    if(allocations?.length) {
      return allocations[0].weights;
    }
  }, [summary, rtqId]);

  const getQscoreAllocationElement = (qscoreAllocation?: EntityQScoreAllocation, mandateAllocation?: WeightRecord) => {
    let dataTip = 'proposal.qscore.allocation.mising.text';
    let icon = <BsFillQuestionCircleFill size="24" color={COLORS.primary} />;

    if(qscoreAllocation && mandateAllocation) {
      const qscoreAllocationEquity = qscoreAllocation.equity/(qscoreAllocation.equity + qscoreAllocation.fixedIncome);
      
      const diff = qscoreAllocationEquity - mandateAllocation.equity;
      if (diff >= -ALLOWED_DIFFERENCE && diff <= ALLOWED_DIFFERENCE) {
        dataTip = 'proposal.qscore.allocation.no.risk.text';
        icon = <BsCheckLg size="24" color={COLORS.green} />;
      } else if (diff < -ALLOWED_DIFFERENCE) {
        dataTip = 'proposal.qscore.allocation.low.text';
        icon = <BsExclamationTriangleFill size="24" color={COLORS.primary} />;
      } else if (diff > -ALLOWED_DIFFERENCE) {
        dataTip = 'proposal.qscore.allocation.high.text';
        icon = <BsExclamationCircleFill size="24" color={COLORS.red} />;
      }
    }

    return (
      <Box data-tooltip-html={getLabel(dataTip)} data-tooltip-id="qscoreTooltip">
        {icon}
        <Tooltip id="qscoreTooltip" />
      </Box>
    );
  };

  useEffect(() => {
    // Mapping from data to records
    setRecords(
      data.map(rec => {
        return {
          ...rec,
          value: toCurrency(rec.value),
          qscoreAllocation: getQscoreAllocationElement(rec.qscoreAllocation, mandateAllocation),
        };
      }),
    );
  }, [data, mandateAllocation]);

  return (
    <Card
      loading={isLoading}
      display="flex"
      flexDirection="column"
      gap="default"
      backgroundColor="background"
    >
      <TitlePrimary>{getLabel('common.accounts')}</TitlePrimary>
      <StyledTable accountColumns={accountColumns} records={records} totalValue={toCurrency(totalValue)} />
    </Card>
  );
};

export default Accounts;
