import React, { useEffect, useState } from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { AggregatedQFolioData } from '../interfaces/AggregatedQFolioData';

const styles = StyleSheet.create({
  Title: {
    fontSize: 20,
    color: COLORS.PRIMARY_LIGHT,
    marginBottom: 8,
  },
  Bold: {
    fontWeight: 'bold',
  },
  Contents: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30,
  },
  Menus: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '60%',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 14,
    height: 26,
    letterSpacing: 1,
  },
  BannerImage: {
    width: '40%',
    marginLeft: 32,
  },
});

type ContentType = {
  page: any;
  title: string;
  type: number;
};

interface Props {
  dataQFolio?: AggregatedQFolioData | null;
}

const ReportContent = ({ dataQFolio }: Props) => {
  const { rtqIDs, proposalAccounts, proposalOwners } = dataQFolio ?? {};

  const [contentsArray, setContentsArray] = useState<Array<ContentType[]>>([]);
  const [paContents, setPaContents] = useState<ContentType[]>([]);

  const getDefaultContents = (pageNum, index, isLegalEntityOwners) => {
    return [
      { page: pageNum, title: `RTQ #${index + 1}`, type: 1 },
      { page: pageNum + 1, title: 'ACCOUNTS & OWNERSHIP', type: 2 },
      { page: pageNum + 2, title: 'INVESTMENT OBJECTIVES', type: 2 },
      { page: pageNum + 4, title: 'BEHAVIORAL CONSISTENCY', type: 2 },
      { page: pageNum + 6, title: 'INVESTMENT STRATEGIES', type: 2 },
      { page: pageNum + 7, title: 'ACCOUNT ALLOCATIONS', type: 2 },
      { page: pageNum + 8, title: 'SECURITY ALLOCATIONS', type: 2 },
    ].filter(({ title }) => !isLegalEntityOwners || title !== 'BEHAVIORAL CONSISTENCY');
  };

  useEffect(() => {
    const contents: Array<ContentType[]> = [];
    let pageNum = 4 + (rtqIDs?.length ?? 1);
    let tocPageCount = 0;

    rtqIDs?.forEach((rtqID, index) => {
      const accts = proposalAccounts?.[rtqID];
      if (accts) {
        const isLegalEntityOwners = (proposalOwners?.[rtqID] ?? []).every(owner => owner.QID.includes('-3-'));
        contents.push(getDefaultContents(pageNum, index, isLegalEntityOwners));

        const accountCount = accts?.length ?? 0;
        pageNum = pageNum + 8 + accountCount;
        tocPageCount++;
      }
    });

    setContentsArray(contents);

    const portfolioAnalysisPageNum = pageNum + tocPageCount;

    setPaContents([
      { page: portfolioAnalysisPageNum, title: 'PORTFOLIO ANALYSIS', type: 1 },
      { page: portfolioAnalysisPageNum + 1, title: 'ASSET ALLOCATION', type: 2 },
      { page: portfolioAnalysisPageNum + 2, title: 'GEOGRAPHIC BREAKDOWN', type: 2 },
      { page: portfolioAnalysisPageNum + 3, title: 'PERFORMANCE', type: 2 },
      { page: portfolioAnalysisPageNum + 4, title: 'EQUITY STYLE', type: 2 },
      { page: portfolioAnalysisPageNum + 5, title: 'INCOME', type: 2 },
      { page: portfolioAnalysisPageNum + 6, title: 'PERFORMANCE', type: 2 },
      { page: portfolioAnalysisPageNum + 7, title: 'FEES/EXPENSES', type: 2 },
      { page: portfolioAnalysisPageNum + 8, title: 'CORRELATION MATRIX', type: 2 },
      { page: portfolioAnalysisPageNum + 9, title: 'RISK CONTRIBUTION ANALYSIS', type: 2 },
      { page: portfolioAnalysisPageNum + 10, title: 'MACROECONOMIC', type: 2 },
    ]);
  }, [rtqIDs, proposalAccounts]);

  return (
    <>
      {contentsArray.length ? (
        contentsArray.map(contents => (
          <Page size="A4" orientation="landscape" style={commonStyles.Root}>
            <Image style={commonStyles.FooterLogo} src="/images/q-logo.png" fixed />
            <View style={commonStyles.Header} fixed>
              <Text style={commonStyles.HeaderTitle}>TABLE OF CONTENTS</Text>
              <Text style={commonStyles.PageNumber} render={({ pageNumber }) => pageNumber} />
            </View>
            <View style={styles.Contents}>
              <View style={styles.Menus}>
                <Text style={styles.Title}>
                  <Text style={styles.Bold}>Your QFolio</Text>
                </Text>
                {contents.map(({ page, title, type }, index) => (
                  <View style={styles.Row} key={index}>
                    <Text
                      style={{
                        textAlign: 'right',
                        width: 32,
                        color: COLORS.PRIMARY,
                        fontWeight: 'bold',
                      }}
                    >
                      {page}
                    </Text>
                    <Text style={{ color: COLORS.GRAY1, paddingHorizontal: 8, fontWeight: 'bold' }}>
                      /
                    </Text>
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: 'semibold',
                        paddingLeft: type === 2 ? 10 : 0,
                        color: type === 2 ? '#aaaaaa' : COLORS.BLACK,
                      }}
                    >
                      {title}
                    </Text>
                  </View>
                ))}
              </View>
              <Image src="/images/qw-qscore-pdf-img2.jpeg" style={styles.BannerImage} />
            </View>
          </Page>
        ))
      ) : (
        <></>
      )}
      <Page size="A4" orientation="landscape" style={commonStyles.Root}>
        <Image style={commonStyles.FooterLogo} src="/images/q-logo.png" fixed />
        <View style={commonStyles.Header} fixed>
          <Text style={commonStyles.HeaderTitle}>TABLE OF CONTENTS</Text>
          <Text
            style={commonStyles.PageNumber}
            render={({ pageNumber, totalPages }) => pageNumber}
          />
        </View>
        <View style={styles.Contents}>
          <View style={styles.Menus}>
            <Text style={styles.Title}>
              <Text style={styles.Bold}>Your QFolio</Text>
            </Text>
            {paContents.map(({ page, title, type }, index) => (
              <View style={styles.Row} key={index}>
                <Text
                  style={{
                    textAlign: 'right',
                    width: 32,
                    color: COLORS.PRIMARY,
                    fontWeight: 'bold',
                  }}
                >
                  {page}
                </Text>
                <Text style={{ color: COLORS.GRAY1, paddingHorizontal: 8, fontWeight: 'bold' }}>
                  /
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 'semibold',
                    paddingLeft: type === 2 ? 10 : 0,
                    color: type === 2 ? '#aaaaaa' : COLORS.BLACK,
                  }}
                >
                  {title}
                </Text>
              </View>
            ))}
          </View>
          <Image src="/images/qw-qscore-pdf-img2.jpeg" style={styles.BannerImage} />
        </View>
      </Page>
    </>
  );
};

export default ReportContent;
