import { combineReducers } from 'redux';
import { partnersReducer as partners } from '@qwealth/qdata';
import auth from './auth';
import axios from './axios';
import financialModels from './financialModels';
import households from './households';
import householdMetadata from './householdMetadata';
import microsoft from './microsoft';
import notifications from './notifications';
import proposalInfo from './proposalInfo';
import recentHistory from './recentHistory';
import securityException from './securityException';

export default combineReducers({
  auth,
  axios,
  financialModels,
  households,
  householdMetadata,
  microsoft,
  notifications,
  partners,
  proposalInfo,
  recentHistory,
  securityException,
});
// this should be in alpha-order
