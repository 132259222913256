import React, { ReactElement, useState, useEffect, useMemo } from 'react';

import { Box, Card } from '@qwealth/qcore';
import { toCurrency } from '@qwealth/qcore/utils';
import { TitlePrimary } from 'components/common/Title';
import StyledTable from './StyledTable';

import { BaseProposalAccount } from 'data/types/proposalInfo';
import { getLabel } from 'data/translations/defaultLabels';

const accountColumns = [
  { dataField: 'name', text: getLabel('common.ownership') },
  { dataField: 'type', text: getLabel('account.type') },
  { dataField: 'accountNumber', text: getLabel('account.number.short') },
  { dataField: 'value', text: getLabel('common.value'), formatter: v => toCurrency(v) },
];

type Props = {
  isLoading: boolean;
  accounts: BaseProposalAccount[];
  showTitle?: boolean;
};

type RecordType = {
  name: string;
  type: string;
  accountNumber: string;
  value: string;
};

const AccountTable: React.FC<Props> = ({ isLoading, accounts, showTitle }): ReactElement => {
  const totalValue = useMemo(() => {
    return accounts.reduce((n, { value }) => n + value, 0);
  }, [accounts])

  return (
    <Box>
      <Card
        loading={isLoading}
        display="flex"
        flexDirection="column"
        gap="default"
        backgroundColor="background"
      >
        {showTitle && <TitlePrimary>{getLabel('proposal.accounts.under.rtq')}</TitlePrimary>}
        <StyledTable accountColumns={accountColumns} records={accounts} totalValue={toCurrency(totalValue)} />
      </Card>
    </Box>
  );
};

export default AccountTable;
