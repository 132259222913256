import { v4 as uuidv4 } from 'uuid';
import { camelCase, toNumber } from 'lodash';

import {
  EntityMandate,
  ProposalMandate,
  ModelRecord,
  BaseProposalAccount,
  EntityAccount,
  EntitySummary,
  EntityAssetRange,
  ProposalAssetRange,
} from '../types';

export const mapSummaryData = (aggregateData: EntitySummary) => {
  const summary = {};

  const rtqs = aggregateData?.rtq.map(r => {
    const accounts = r.accounts.map(a => {
      return {
        accountNumber: a.accountNumber,
        name: a.accountOwnersName,
        type: a.accountType,
        value: a.marketValue,
      };
    });

    const weights = {
      alternatives: 0,
      cash: 0,
      equity: 0,
      fixedIncome: 0,
    };

    const assetAllocations = r.mandate.assetClassAllocation;
    for (const key in assetAllocations) {
      // TO DO: Remove once BE is returning it properly
      const label = camelCase(key);
      weights[label] = assetAllocations[key]['target'];
    }

    const owners = r.owners.map(o => {
      return { name: o };
    });

    return {
      accounts,
      owners,
      mandate: r.mandate.name,
      id: r.rtqID,
      weights,
    };
  });

  summary['onModel'] = aggregateData?.onModel;
  summary['rtqs'] = rtqs;

  return summary;
};

export const mapAssetRanges = (assetRanges: EntityAssetRange[]): ProposalAssetRange[] => {
  return assetRanges.map(assetRange => {
    const { asset, min, max, target } = assetRange;

    return {
      name: asset,
      min: min * 100,
      max: max * 100,
      target: target * 100,
      actual: target * 100,
    };
  });
};

// The API was built after the component were already built and refactored
// The current component relies on the properties name in ProposalMandate to generate the component correctly.
// Removing this mapping would result in the need of updating the component and how it is already refactored
export const mapMandateInformation = (m: EntityMandate): ProposalMandate => {
  return {
    mandate: m.mandate,
    objectivePrimary: m.objectivePrimaryChoice,
    objectiveSecondary: m.objectiveSecondaryChoice,
    risk: m.riskScore,
    riskAbility: m.abilityScore,
    riskWillingness: m.willingnessScore,
    timeHorizon: m.timeHorizonChoice,
    liquidity: m.liquidiy,
    uniqueCircumstances: [m.uniqueCircumstancesDetails],
    qscore: '100',
  };
};

export const mapAccounts = (accounts: EntityAccount[]): BaseProposalAccount[] => {
  return accounts.map(mapAccount);
};

const mapAccount = (account: EntityAccount): BaseProposalAccount => {
  const accountSeurities = account.modelSecurityAllocation?.map(s => {
    return {
      ticker: s.ticker,
      allocatedPercent: s.weight
    };
  });

  const currentHoldings = account.currentHoldings.map(h => {
    return {
      assetClass: h.assetClass,
      ticker: h.ticker,
      marketValue: toNumber(h.marketValue),
      units: toNumber(h.units)
    }
  });

  const securityExceptions = mapSecurityExceptions(account.securityExceptions, account.currentValue);
  const securityAllocated = securityExceptions.reduce((n, { allocated }) => n + (allocated ?? 0), 0);
  const securityAllocatedPercent = securityExceptions.reduce((n, { allocatedPercent }) => n + (allocatedPercent ?? 0), 0);

  return {
    accountNumber: account.accountNumber,
    name: account.accountOwnership,
    type: account.accountType,
    value: account.currentValue,
    qscoreAllocation: account.qscoreAllocation,
    modelId: account.modelPortfolio?.id,
    securities: accountSeurities,
    securityExceptions,
    securityAllocated,
    securityAllocatedPercent,
    currentHoldings
  };
};

const mapSecurityExceptions = (securityExceptions, currentValue) => {
  return securityExceptions.map(s => {
    const value = getSecurityExceptionValuation(s, currentValue);

    return {
      ...s,
      allocatedPercent: value / currentValue,
      allocated: value
    }
  }); 
}

const getSecurityExceptionValuation = ({units, marketValue, dollars, weight}, accountValue) => {
  if(units) {
    return marketValue;
  } else if (dollars) {
    return dollars;
  } else if (weight) {
    return weight * accountValue;
  }

  return 0;
}

export const mapModelsFromAccounts = (accounts: EntityAccount[]): ModelRecord[] => {
  const mods = {};

  accounts.forEach(account => {
    if (account.modelPortfolio && account.modelPortfolio.id) {
      if (mods[account.modelPortfolio.id]) {
        mods[account.modelPortfolio.id].accounts = [
          ...mods[account.modelPortfolio.id].accounts,
          mapAccount(account),
        ];
      } else {
        const weights = {
          alternatives: 0,
          cash: 0,
          equity: 0,
          fixedIncome: 0,
        };

        const assetAllocations = account.modelPortfolio.mandateTargetAllocation;
        for (const key in assetAllocations) {
          // TO DO: Remove once BE is returning it properly
          const label = camelCase(key);
          weights[label] = assetAllocations[key] * 100;
        }

        mods[account.modelPortfolio.id] = {
          id: uuidv4(),
          modelId: account.modelPortfolio.id,
          modelName: account.modelPortfolio.modelName,
          strategy: account.modelPortfolio.strategy,
          assetAllocation: account.modelPortfolio.mandateName,
          weights: weights,
          accounts: [mapAccount(account)],
        };
      }
    }
  });

  const loadedMods: ModelRecord[] = [];
  for (const key in mods) {
    loadedMods.push(mods[key]);
  }

  return loadedMods;
};

export const getDefaultInvestmentStrategy = accounts => {
  let strategy = undefined;
  const accountsWithModels = accounts.filter(account => account.modelId);
  if (accountsWithModels.length) {
    strategy = accountsWithModels[0].strategy;
  }

  return strategy;
};
