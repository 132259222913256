import React, { useEffect, useMemo, useState } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';
import { ModelRecord } from 'data/types';
import { getLabel } from 'data/translations/defaultLabels';

const styles = StyleSheet.create({
  MainText: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    textAlign: 'center',
    fontWeight: 'medium',
  },
  HeaderCell: {
    display: 'flex',
    flexDirection: 'row',
    height: 35,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    flex: 1,
  },
  ValueCell: {
    fontSize: 10,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    flex: 1,
  },
});

const crateChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets'],
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets,
  },
});

interface Props {
  rtqNum: number;
  proposalModels?: Array<ModelRecord>;
}

const ReportAssetAllocations = ({ rtqNum, proposalModels }: Props) => {
  const [modelWeights, setModelWeights] = useState({});

  useEffect(() => {
    const weights = {};
    let accountTotal = 0;

    proposalModels?.forEach(model => {
      accountTotal += model.accounts.reduce((n, { value }) => n + (value ?? 0), 0);
    });

    proposalModels?.forEach(model => {
      model.accounts.map(account => {
        const accountPercent = account?.value / accountTotal;
        for (const key in model?.weights) {
          if (weights[key]) {
            weights[key] += model?.weights[key] * accountPercent;
          } else {
            weights[key] = model?.weights[key] * accountPercent;
          }
        }
      });
    });

    setModelWeights(weights);
  }, proposalModels);

  const assetAllocations = useMemo(() => {
    const allocations: any = [];

    for (const k in modelWeights) {
      const net = Math.round(modelWeights[k]);
      allocations.push({
        label: getLabel('common.' + k),
        value: net,
      });
    }

    return allocations;
  }, [modelWeights]);

  const chartData = useMemo(() => {
    const dataArray: any = [];

    for (const k in modelWeights) {
      const net = Math.round(modelWeights[k]);
      dataArray.push(net * 100);
    }

    const data = {
      datasets: [
        {
          data: dataArray,
          backgroundColor: [COLORS.PRIMARY, '#c0d8d3', '#2371bb', '#21c9c3', COLORS.PRIMARY_LIGHT],
        },
      ],
    };

    return data;
  }, [modelWeights]);

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>{`RTQ #${rtqNum}: ASSET ALLOCATIONS`}</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View
        style={[
          commonStyles.Content,
          commonStyles.JustifyContentCenter,
          { flex: 1, paddingHorizontal: 60 },
        ]}
      >
        <View style={[commonStyles.Row, commonStyles.Width100]}>
          <View
            style={[
              { width: '40%' },
              commonStyles.JustifyContentCenter,
              commonStyles.AlignItemsCenter,
            ]}
          >
            {useChartJsAsImage(crateChartJsConfig('doughnut', [], chartData.datasets))}
          </View>
          <View style={{ flex: 1 }}>
            <View style={[commonStyles.Row, commonStyles.Width100]}>
              <View style={styles.HeaderCell}>
                <Text>Asset Class</Text>
              </View>
              <View style={styles.HeaderCell}>
                <Text>Net %</Text>
              </View>
            </View>

            {assetAllocations?.map((data, index) => (
              <View key={index} style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={styles.ValueCell}>
                  <Text>{data.label}</Text>
                </View>
                <View style={styles.ValueCell}>
                  <Text>{data.value}</Text>
                </View>
              </View>
            ))}

            <View style={[commonStyles.Row, commonStyles.Width100]}>
              <View style={[styles.ValueCell, commonStyles.BoldFont, { border: 'none' }]}>
                <Text>Total</Text>
              </View>
              <View style={[styles.ValueCell, commonStyles.BoldFont, { border: 'none' }]}>
                <Text>{assetAllocations.reduce((n, { value }) => n + (value ?? 0), 0)}</Text>
              </View>
            </View>
          </View>
        </View>

        <Text style={[styles.MainText, { marginTop: 50 }]}>
          Average asset allocation from these weighted accounts is shown above and
        </Text>
        <Text style={[styles.MainText, { marginTop: 5 }]}>
          aligns with your RTQ asset allocation.
        </Text>
      </View>
    </Page>
  );
};

export default ReportAssetAllocations;
