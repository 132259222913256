import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { toCurrency } from '@qwealth/qcore';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { EntityAccount, ProposalOwner } from 'data/types';

const styles = StyleSheet.create({
  MainText: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    textAlign: 'center',
    marginTop: 40,
  },
  Content: {
    diplay: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginVertical: 60,
    paddingHorizontal: 30,
    justifyContent: 'space-between',
  },
  LeftPane: {
    display: 'flex',
    width: '40%',
  },
  RightPane: {
    width: '55%',
    display: 'flex',
  },
  HeaderCell1: {
    display: 'flex',
    flexDirection: 'row',
    height: 35,
    backgroundColor: COLORS.PRIMARY,
    color: COLORS.WHITE,
    fontSize: 10,
    borderRightWidth: 2,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
  HeaderCell2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    height: 40,
    fontSize: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderBottomWidth: 2,
    borderBottomColor: COLORS.WHITE,
  },
  ValueCell: {
    fontSize: 10,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    paddingHorizontal: 10,
    textAlign: 'center',
  },
  Column: {
    display: 'flex',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
  },
  BoldFont: {
    fontWeight: 'bold',
  },
});

const COLUMNS = [
  {
    key: 'age',
    title: 'Age',
  },
  {
    key: 'netWorth',
    title: 'Net Worth',
  },
  {
    key: 'income',
    title: 'Income',
  },
  {
    key: 'qbias',
    title: 'QBias',
  },
];

const ROWS = [
  {
    key: 'ownership',
    title: 'Ownership',
  },
  {
    key: 'accountType',
    title: 'Account Type',
  },
  {
    key: 'accountNumber',
    title: 'Account#',
  },
  {
    key: 'value',
    title: 'Value',
  },
];

interface Props {
  rtqNum: number;
  proposalAccounts?: Array<EntityAccount>;
  proposalOwners?: Array<ProposalOwner>;
  isLegalEntityOwners: boolean;
}

const ReportAccountOwnership = ({ rtqNum, proposalAccounts, proposalOwners, isLegalEntityOwners }: Props) => {
  const sum = proposalAccounts?.reduce((acc, o) => acc + o.currentValue, 0);

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>{`RTQ #${rtqNum}: ACCOUNTS & OWNERSHIP`}</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <Text style={styles.MainText}>
        The accounts below are owned by the following members of your household:
      </Text>

      <View style={styles.Content}>
        <View style={styles.LeftPane}>
          <View style={styles.Row}>
            <View
              style={[
                styles.HeaderCell1,
                { width: '40%', border: 'none', backgroundColor: COLORS.WHITE },
              ]}
            ></View>
            {proposalOwners?.map((proposalOwner, index) => (
              <View
                key={proposalOwner.QID}
                style={[
                  styles.HeaderCell1,
                  {
                    width: '30%',
                    borderRightWidth: proposalOwners?.length - 1 === index ? 0 : 2,
                  },
                ]}
              >
                <Text>{proposalOwner.name}</Text>
              </View>
            ))}
          </View>

          <View style={styles.Row}>
            <View style={{ width: '40%' }}>
              {COLUMNS
                .filter(({ key }) => !isLegalEntityOwners || !['age', 'qbias'].includes(key))
                .map((row, index) => (
                  <View
                    key={row.key}
                    style={[
                      styles.HeaderCell2,
                      { borderBottomWidth: index === COLUMNS.length - 1 ? 0 : 2 },
                    ]}
                  >
                    <Text>{row.title}</Text>
                  </View>
                ))}
            </View>

            {proposalOwners?.map(owner => (
              <View key={owner.QID} style={{ width: '30%' }}>
                {!isLegalEntityOwners && (
                  <View style={[styles.ValueCell]}>
                    <Text>{owner.age || 'N/A'}</Text>
                  </View>
                )}
                <View style={[styles.ValueCell]}>
                  <Text>{toCurrency(owner.netWorth || 0, true)}</Text>
                </View>
                <View style={[styles.ValueCell]}>
                  <Text>{toCurrency(owner.income || 0, true)}</Text>
                </View>
                {!isLegalEntityOwners && (owner.qbias ?? []).map(qbias => {
                  return (
                    <View key={qbias} style={[styles.ValueCell]}>
                      <Text>{qbias}</Text>
                    </View>
                  );
                })}
              </View>
            ))}
          </View>
        </View>

        <View style={styles.RightPane}>
          <View style={styles.Row}>
            {ROWS.map(row => (
              <View
                key={row.key}
                style={[
                  styles.HeaderCell2,
                  {
                    width: '25%',
                    borderBottom: 'none',
                    borderRightWidth: 1,
                    borderRightColor: COLORS.WHITE,
                    height: 35,
                  },
                ]}
              >
                <Text>{row.title}</Text>
              </View>
            ))}
          </View>
          {proposalAccounts?.map((proposalAccount, index) => (
            <View key={proposalAccount.accountNumber} style={styles.Row}>
              <View
                style={[
                  styles.ValueCell,
                  {
                    width: '25%',
                    borderRight: 'none',
                  },
                ]}
              >
                <Text>{proposalAccount.accountOwnership}</Text>
              </View>
              <View
                style={[
                  styles.ValueCell,
                  {
                    width: '25%',
                    borderRight: 'none',
                  },
                ]}
              >
                <Text>{proposalAccount.accountType}</Text>
              </View>
              <View
                style={[
                  styles.ValueCell,
                  {
                    width: '25%',
                    borderRight: 'none',
                  },
                ]}
              >
                <Text>{proposalAccount.accountNumber}</Text>
              </View>
              <View
                style={[
                  styles.ValueCell,
                  {
                    width: '25%',
                    borderRight: 'none',
                    fontWeight: 'bold',
                    justifyContent: 'flex-end',
                  },
                ]}
              >
                <Text>{toCurrency(proposalAccount.currentValue, true)}</Text>
              </View>
            </View>
          ))}

          <View
            style={[
              styles.Row,
              {
                backgroundColor: COLORS.GRAY2,
                justifyContent: 'space-between',
                fontWeight: 'bold',
              },
            ]}
          >
            <View style={styles.ValueCell}>
              <Text>TOTAL</Text>
            </View>
            <View style={styles.ValueCell}>
              <Text>{toCurrency(sum, true)}</Text>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ReportAccountOwnership;
