import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Button } from '@qwealth/qcore';

import Stages, { ActiveStage } from './Stages';
import Information from '../Information';
import BehavioralConsistency from '../BehavioralConsistency';
import Models from '../Models';
import Allocations from '../Allocations';

import {
  loadOwners,
  loadAccounts,
  loadMandate,
  loadInconsistencies,
  loadAssetRanges,
  setProposalActiveRtq,
} from '../../../data/actions/creators/proposalInfo';
import { ProposalInfoSelectors } from '../../../data/selectors';

type Props = {
  rtqId: string;
};

const Proposal: FC<Props> = ({ rtqId }): ReactElement => {
  const dispatch = useDispatch();

  // If models completed then set it
  const accounts = useSelector(ProposalInfoSelectors.accounts);
  const models = useSelector(ProposalInfoSelectors.models);

  const accountCount = useSelector(ProposalInfoSelectors.accountCount);
  const modelModified = useSelector(ProposalInfoSelectors.modelModified);
  const allocationModified = useSelector(ProposalInfoSelectors.allocationModified);
  const allocationComplete = useSelector(ProposalInfoSelectors.allocationComplete);
  const isLegalEntityOwners = useSelector(ProposalInfoSelectors.isLegalEntityOwners);

  const [modelsCompleted, setModelsCompleted] = useState<0 | 1>(0);
  const [allocationsCompleted, setAllocationsCompleted] = useState<0 | 1>(0);

  const activeFlowStages: ActiveStage[] = [
    { id: 1, stageName: 'Information', stageStatus: 1 },
    { id: 2, stageName: 'Behavioral Consistency', stageStatus: 1 },
    { id: 3, stageName: 'Models', stageStatus: modelsCompleted },
    { id: 4, stageName: 'Allocations', stageStatus: allocationsCompleted },
  ].filter(stage => !isLegalEntityOwners || stage.id !== 2) as ActiveStage[];

  const [selectedActiveStageIndex, setSelectedActiveStageIndex] = useState<number>(-1);

  useEffect(() => {
    const modelComplete =
      !modelModified && accounts.length > 0 && accounts.length === accountCount ? 1 : 0;
    setModelsCompleted(modelComplete);

    const allocationCompleted = !allocationModified && allocationComplete;
    setAllocationsCompleted(modelComplete && allocationCompleted ? 1 : 0);
  }, [models, accounts, modelModified, accountCount, allocationModified, allocationComplete]);

  useEffect(() => {
    if (rtqId) {
      dispatch(setProposalActiveRtq(rtqId));
      dispatch(loadOwners());
      dispatch(loadAccounts());
      dispatch(loadMandate());
      dispatch(loadInconsistencies());
      dispatch(loadAssetRanges());
    }
  }, [rtqId]);

  return (
    <Box display="grid" gap="large">
      <Stages
        stages={activeFlowStages}
        selectedActiveStageIndex={selectedActiveStageIndex}
        setSelectedActiveStageIndex={setSelectedActiveStageIndex}
      />

      {selectedActiveStageIndex === 1 && <Information />}
      {selectedActiveStageIndex === 2 && <BehavioralConsistency />}
      {selectedActiveStageIndex === 3 && <Models />}
      {selectedActiveStageIndex === 4 && <Allocations />}

      <Box display='flex' flexDirection='row' justifyContent="space-between">
        <Button
          color="primary"
          disabled={selectedActiveStageIndex === 1}
          variant="outline"
          onClick={() => {
            if (isLegalEntityOwners && selectedActiveStageIndex === 3) {
              setSelectedActiveStageIndex(selectedActiveStageIndex - 2);
            } else {
              setSelectedActiveStageIndex(selectedActiveStageIndex - 1);
            }
          }}
        >
          Previous
        </Button>
        <Button
          color="primary"
          disabled={selectedActiveStageIndex >= activeFlowStages.length - 1}
          variant='outline'
          onClick={() => {
            if (isLegalEntityOwners && selectedActiveStageIndex === 1) {
              setSelectedActiveStageIndex(selectedActiveStageIndex + 2);
            } else {
              setSelectedActiveStageIndex(selectedActiveStageIndex + 1);
            }
          }}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default Proposal;
