import React, { FC, ReactElement } from 'react';

import { Box } from '@qwealth/qcore';

import Accounts from './Accounts';

import QScore from './QScore';
import LossAversionCertainty from './LossAversionCertainty';

const Information: FC = (): ReactElement => {
  return (
    <Box
      display="grid"
      gap="large"
      gridTemplateColumns={{
        desktop: '1fr',
      }}
    >
      <Accounts />
      <Box
        display="grid"
        gap="large"
        gridTemplateColumns={{
          desktop: '1fr 1fr',
        }}
      >
        <QScore />
        <LossAversionCertainty />
      </Box>
    </Box>
  );
};

export default Information;
