import React, { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { Card } from '@qwealth/qcore';
import { TitlePrimary } from 'components/common/Title';

import InconsistencyItem from './InconsistencyItem';

import { ProposalInfoSelectors } from 'data/selectors';

const QScore: FC = (): ReactElement => {
  const isLoading = useSelector(ProposalInfoSelectors.isGettingInconsistencies);
  const data = useSelector(ProposalInfoSelectors.inconsistencies);

  return (
    <Card loading={isLoading} display="flex" flexDirection="column" gap="default">
      <TitlePrimary>QScore</TitlePrimary>

      <InconsistencyItem
        consistent={data?.householdQscore.consistent}
        bodyText={data?.householdQscore.text}
        value={data?.householdQscore.value}
      />
    </Card>
  );
};

export default QScore;
