import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { Tooltip } from 'react-tooltip';

import { Box, Card } from '@qwealth/qcore';

import { TitlePrimary } from 'components/common/Title';

import { ProposalInfoSelectors } from '../../../data/selectors';
import { getLabel } from 'data/translations/defaultLabels';
import AssetRangeRecord from './AssetRangeRecord';
import { COLORS } from 'theme/colors';

export type RecordType = {
  title: string;
  value: number[];
  actual: number;
  target: number;
  index: number;
  anchorColor: string;
};

interface Props {
  displayActual?: boolean;
}

const AssetRanges: React.FC<Props> = ({ displayActual }): ReactElement => {
  const isLoading = useSelector(ProposalInfoSelectors.isGettingAssetRanges);
  const data = useSelector(ProposalInfoSelectors.assetRanges);
  const models = useSelector(ProposalInfoSelectors.models);
  const mandate = useSelector(ProposalInfoSelectors.mandate);

  const [records, setRecords] = useState<RecordType[]>([]);

  useEffect(() => {
    const weights = {};

    if (displayActual) {
      let accountTotal = 0;

      // model1.assetAllocation * account.value / Account Total under Model + model2.assetAllocation * account.value / Account Total under Model
      models.forEach(model => {
        accountTotal += model.accounts.reduce((n, { value }) => n + (value ?? 0), 0);
      });

      models.forEach(model => {
        model.accounts.map(account => {
          const accountPercent = (account?.value - account.securityAllocated) / accountTotal;

          for (const key in model?.weights) {
            const useKey = key.toLowerCase().replaceAll(/\s/g, '');
            if (weights[useKey]) {
              weights[useKey] += model?.weights[key] * accountPercent;
            } else {
              weights[useKey] = model?.weights[key] * accountPercent;
            }
          }

          account.securityExceptions.forEach(({ assetClass, allocatedPercent, includeInAssetAlloc }) => {
            if(includeInAssetAlloc === 1) {
              const useKey = assetClass.toLowerCase().replaceAll(/\s/g, '');
              const securityPercent = 100 * (allocatedPercent * account.value / accountTotal);

              if (weights[useKey]) {
                weights[useKey] += securityPercent;
              } else {
                weights[useKey] = securityPercent;
              }
            }
          })

        });
      });
    }

    const records = data?.map(rec => {
      const assetClass = rec.name?.toLowerCase().split(' ').join('.');
      const index = assetClass === 'equity' ? 0 : assetClass === 'fixed.income' ? 1 : assetClass === 'alternatives' ? 2 : assetClass === 'cash' ? 3 : -1;

      const titleKey = 'asset.class.' + assetClass;
      const translatedTitle = getLabel(titleKey);
      const title = translatedTitle === titleKey ? rec.name : translatedTitle;
      const diff = Math.round((rec.max - rec.min) / 6);
      const targetMin = rec.target - diff;
      const targetMax = rec.target + diff;
      let actual = rec.actual;
      let anchorColor = COLORS.green;

      if (displayActual) {
        // Take case of asset class translation
        const localKey = rec.name.toLowerCase().replace(/\s/g, '');
        actual = Math.round(weights[localKey]);

        if (actual < rec.min || actual > rec.max) {
          anchorColor = COLORS.red;
        } else if ((actual < targetMin && actual > rec.min) || (actual > targetMax && actual < rec.max)) {
          anchorColor = COLORS.primary;
        } else if (actual >= targetMin && actual <= targetMax) {
          anchorColor = COLORS.green;
        }
      }

      // Take care of asset class translation
      return { title, value: [rec.min, (targetMin > 0.1 ? targetMin: 0), targetMax, rec.max], actual, target: rec.target, index, anchorColor };
    }).sort((a, b) => a.index < b.index ? -1 : 0);

    setRecords(records);
  }, [data, displayActual, models, data]);
  
  const dataTip = useMemo(() => {
    return [
      'These are the corresponding target, minimum, and maximum values',
      `for each asset class for your RTQ’s mandate - ${mandate?.mandate}`,
    ].join('<br/>');
  }, [mandate]);

  return (
    <Box>
      <Card loading={isLoading} display="flex" flexDirection="column" gap="default">
        <Box display="flex" flexDirection="row">
          <TitlePrimary>{getLabel('proposal.asset.ranges')}</TitlePrimary>
          <Box data-tooltip-html={dataTip} data-tooltip-id="assetRangeTooltip" paddingLeft="small" margin="none">
            <BsExclamationCircleFill size="16" color={COLORS.primary} />
            <Tooltip id="assetRangeTooltip" />
          </Box>

        </Box>
        
        {records.map((assetRange, index) => (
          <AssetRangeRecord
            key={assetRange.title}
            assetRange={assetRange}
            index={index}
            displayActual={displayActual}
          />
        ))}
      </Card>
    </Box>
  );
};

export default AssetRanges;
