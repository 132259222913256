import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  Title: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    fontWeight: 'bold',
  },
  Description: {
    fontSize: 10,
    marginTop: 20,
    lineHeight: '150%',
  },
  Cell: {
    display: 'flex',
    height: 40,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 12,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    flex: 1,
    margin: 2,
  },
});

interface Props {
  data?: object;
}

const ReportCorrelationMatrix = ({ data }: Props) => {
  const items = data ? Object.keys(data) : [];

  const colorMap = [
    '#f2994a',
    '#f2a45f',
    '#f2ad70',
    '#f3b783',
    '#f4c196',
    '#f4c59d',
    '#f4cfaf',
    '#f5d9c2',
    '#f5e6da',
    '#f5ede7',
    '#f6f8f9',
    '#e3f0f7',
    '#dfeef7',
    '#c6e3f5',
    '#add8f3',
    '#94cdf1',
    '#7ec3f0',
    '#73beef',
    '#63b7ee',
    '#4faded',
    '#36a2eb',
  ];

  const getGradient = value => {
    return value == null ? '#f6f8f9' : colorMap[Math.round(value * 10) + 10];
  };

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>CORRELATION MATRIX</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={[commonStyles.Content, { paddingHorizontal: 60 }]}>
        <Text style={styles.Title}>Securities Correlation</Text>
        <Text style={styles.Description}>
          This infographic compares each of the securities in the portfolio proposal, and describes
          their correlation to one another. A correlation close to 1 would indicate two securities
          move together in similar directions; a correlation of 0 would indicate two securities move
          independently of one another; a correlation of -1 would indicate two securities move
          together but in opposite directions of one another.
        </Text>

        <View style={[commonStyles.Row, commonStyles.Width100, { marginTop: 20 }]}>
          <View style={[styles.Cell, { backgroundColor: COLORS.WHITE }]}>
            <Text></Text>
          </View>
          {items.length > 0 &&
            items.map(item => (
              <View key={item} style={styles.Cell}>
                <Text>{item}</Text>
              </View>
            ))}
        </View>

        {data &&
          items.length > 0 &&
          items.map(item => (
            <View key={item} style={[commonStyles.Row, commonStyles.Width100]}>
              <View style={styles.Cell}>
                <Text>{item}</Text>
              </View>
              {Object.keys(data[item]).map(subItem => (
                <View
                  key={subItem}
                  style={[
                    styles.Cell,
                    {
                      backgroundColor: getGradient(data[item][subItem]),
                      color: COLORS.BLACK,
                    },
                  ]}
                >
                  <Text>{data[item][subItem]}</Text>
                </View>
              ))}
            </View>
          ))}
      </View>
    </Page>
  );
};

export default ReportCorrelationMatrix;
