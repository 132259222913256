import {
  PUT_ALL_HOUSEHOLDS,
  PUT_ACTIVE_HOUSEHOLD_ADDRESS,
  SET_HOUSEHOLDS_INITIALIZATION,
} from '../actions/types';
import { AggregateHousehold, Household } from '@qwealth/qdata';

export interface HouseholdMap {
  [key: string]: AggregateHousehold;
}

type State = {
  initialized: boolean;
  householdMap: HouseholdMap;
  household?: Household;
};

export default function (
  state: State = {
    initialized: false,
    householdMap: {},
  },
  action,
): State {
  switch (action.type) {
    case SET_HOUSEHOLDS_INITIALIZATION: {
      return { ...state, initialized: action.isInitialized };
    }
    case PUT_ALL_HOUSEHOLDS: {
      const householdMap = {};
      action.households.forEach(h => {
        householdMap[h.QID] = h;
      });
      return { ...state, initialized: true, householdMap };
    }
    case PUT_ACTIVE_HOUSEHOLD_ADDRESS: {
      const { address } = action;
      // @ts-ignore TODO: fix this
      return { ...state, household: { ...state.household, addresses: [address] } };
    }
    default:
      return state;
  }
}
