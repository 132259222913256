export const COLORS = {
  primary: 'rgba(242, 153, 74, 1)',
  primaryBackground: 'rgba(242, 153, 74, 0.12)',
  primaryBackgroundLight: 'rgba(242, 153, 74, 0.2)',
  primaryBorder: 'rgba(242, 153, 74, 0.3)',
  green: 'rgba(121, 185, 78, 1)',
  greenBackground: 'rgba(121, 185, 78, 0.2)',
  greenBackgroundLight: 'rgba(121, 185, 78, 0.12)',
  red: 'rgba(204, 67, 60, 1)',
  redBackground: 'rgba(204, 67, 60, 0.2)',
  gray: 'rgba(64, 67, 77, 1)',
  grayBackground: 'rgba(233, 237, 243, 1)',
  background: 'rgba(247, 248, 249, 1)',
  subheaderText: 'rgb(69, 64, 77, 0.8)',
  lightGray: 'rgba(132, 135, 143, 1)',
  secondaryButton: 'rgb(112, 112, 112)',
  secondaryButtonBackground: 'rgb(233, 237, 243)'
};
