import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BsPlus } from 'react-icons/bs';

import { Box, Card, Button, styled } from '@qwealth/qcore';

import AccountsTable from './AccountsTable';
import AccountsDraggable from './AccountsDraggable';
import Confirmation from '../Common/Confirmation';
import SecurityModal from './SecurityModal';
import DisplayToggleContainer from '../Common/DisplayToggleContainer';

import { EntitySecurityException, ProposalAccount } from 'data/types';
import { resetProposalModelAccountsSetup } from 'data/actions/creators/proposalInfo';
import { ProposalInfoSelectors } from 'data/selectors';
import { getLabel } from 'data/translations/defaultLabels';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const StyledButton = styled(Button)`
  background-color: ${COLORS.primary};
  min-width: 50px;
`;

const ExceptionButton = styled(Button)`
  min-width: 50px;
  color: ${COLORS.primary};
  background-color: transparent;
  &:before {
    background-color: ${COLORS.primaryBackground};
  }

  &:hover:not([disabled]) {
    color: ${COLORS.primary};
  }
`;

export const TitlePrimary = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-bottom: -5px;
`;

const TitleSecondary = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 0.75rem;
`;

const accountColumns = [
  { dataField: 'name', text: getLabel('common.ownership') },
  { dataField: 'type', text: getLabel('account.type') },
  { dataField: 'value', text: getLabel('common.value') },
];

export type RecordType = {
  account: ProposalAccount;
  name: JSX.Element;
  value: number;
};

type Props = {
  displayPercent: boolean;
  setDisplayPercent: React.Dispatch<React.SetStateAction<boolean>>;
};

const Accounts: React.FC<Props> = ({displayPercent, setDisplayPercent}): ReactElement => {
  const dispatch = useDispatch();

  const data = useSelector(ProposalInfoSelectors.accounts);
  const models = useSelector(ProposalInfoSelectors.models);

  const [records, setRecords] = useState<RecordType[]>([]);
  const [securityException, setSecurityException] = useState<EntitySecurityException|undefined>(undefined);
  const [account, setAccount] = useState<ProposalAccount>();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  const totalValue = useMemo(() => {
    return data.reduce((n, { value }) => n + value, 0)
  }, [data]);

  const confirmationAction = () => {
    dispatch(resetProposalModelAccountsSetup());
  };

  useEffect(() => {
    const accountsAssigned: string[] = [];

    models.forEach(m => {
      m.accounts?.forEach(acc => {
        accountsAssigned.push(acc.accountNumber);
      });
    });

    // Mapping from data to records
    setRecords(
      data.map((rec, index) => {                                                                                                                                 
        const disabled = accountsAssigned.indexOf(rec.accountNumber) !== -1;

        return {
          account: rec,
          value: rec.value,
          accountName: rec.name,
          name: (
            <AccountsDraggable
              key={index}
              accountNumber={rec.accountNumber}
              name={rec.name}
              disabled={disabled}
              index={index}
            />
          ),
        };
      }),
    );
  }, [data, models]);

  return (
    <>
      <Confirmation
        showConfirmation={showConfirmation}
        title={getLabel('proposal.models.reset.confirmation.title')}
        body={getLabel('proposal.models.reset.confirmation.text')}
        setShowConfirmation={setShowConfirmation}
        confirmationAction={confirmationAction}
      />
 
      <SecurityModal
        account={account} 
        securityException={securityException} 
        showModal={showModal} 
        setShowModal={setShowModal} 
      />

      <Card display="flex" flexDirection="column" gap="default" backgroundColor="background">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap="default">
            <TitlePrimary>{getLabel('common.accounts')}</TitlePrimary>
            <TitleSecondary>{getLabel('proposal.models.drag.and.drop.text')}</TitleSecondary>
          </Box>
          <DisplayToggleContainer displayPercent={displayPercent} setDisplayPercent={setDisplayPercent} />
        </Box>
        
        <AccountsTable 
          setShowModal={setShowModal}
          setAccount={setAccount}
          setSecurityException={setSecurityException}
          accountColumns={accountColumns} 
          records={records}
          displayPercent={displayPercent}
          totalValue={totalValue} />

        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <ExceptionButton
            variant="secondary"
            justifyContent="flex-start"
            onClick={() => {
              setSecurityException(undefined);
              setAccount(undefined);
              setShowModal(true);
            }}
          >
            <BsPlus size="24" color={COLORS.primary} />
            <Box paddingLeft="small" paddingRight="small">
              {getLabel('proposal.models.add.exception')}
            </Box>
          </ExceptionButton>
          <StyledButton width={100} onClick={() => setShowConfirmation(true)}>
            {getLabel('common.reset')}
          </StyledButton>
        </Box>
      </Card>
    </>
  );
};

export default Accounts;
