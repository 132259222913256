import React, { Dispatch, SetStateAction, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Button, styled } from '@qwealth/qcore';

import buildUrl from 'utils/UrlHelper';

import TopNavigation from 'components/common/TopNavigation';
import Proposal from './Proposal';

import { ProposalInfoSelectors } from 'data/selectors';
import { COLORS } from 'theme/colors';

const StyledButton = styled(Button)`
  background-color: ${COLORS.primary};
  min-width: 50px;
  max-height: 40px;
`;

type NavItemType = { label: string; id: string };

type Props = {
  householdId: string;
  setSetupProposal: Dispatch<SetStateAction<boolean>>;
};

const ProposalSetup: React.FC<Props> = ({ householdId, setSetupProposal }): ReactElement => {
  const isLoading = useSelector(ProposalInfoSelectors.isGettingSummary);
  const summary = useSelector(ProposalInfoSelectors.summary);
  const [selected, setSelected] = useState('');
  const navigate = useNavigate();

  // Fetch RTQ for household and create below array
  const [navItemsProposal, setNavItemsProposal] = useState<NavItemType[]>([]);

  useEffect(() => {
    let navItems: NavItemType[] = [];

    if (!isLoading && summary?.rtqs.length) {
      navItems = summary.rtqs.map((rtq, index) => {
        return {
          label: `RTQ ${index + 1}`,
          id: rtq.id.toString(),
        };
      });
    }

    setNavItemsProposal(navItems);

    if (navItems.length) {
      setSelected(navItems[0].id);
    }
  }, [summary, isLoading]);

  return (
    <Box display="grid" gap="large">
      <Box display="flex" alignItems="center">
        <StyledButton
          flex={0.1}
          marginRight="large"
          color="primary"
          onClick={() => {
            setSetupProposal(false);
          }}
        >
          Back to Summary
        </StyledButton>

        <Box flex={0.9}>
          <TopNavigation
            selectedId={selected}
            navItems={navItemsProposal}
            onSelect={id => {
              setSelected(id);
              navigate({
                pathname: `/proposal/${householdId}`,
                search: buildUrl({ householdTab: id }),
              });
            }}
          />
        </Box>
      </Box>

      <Proposal rtqId={selected} />
    </Box>
  );
};

export default ProposalSetup;
