import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@qwealth/qcore';

import HeaderSecondary from 'components/common/HeaderSecondary';

import { selectHouseholdMetadata } from 'data/selectors/householdSelectors';

const HouseholdHeader: React.FC = (): ReactElement => {
  const householdMetadata = useSelector(selectHouseholdMetadata);

  return (
    <HeaderSecondary>
      <Box display="flex" alignItems="center">
        {householdMetadata.name ?? '-'}
        &nbsp;
        <Box as="span" fontWeight="normal">
          Family
        </Box>
      </Box>
    </HeaderSecondary>
  );
};

export default HouseholdHeader;
