import React, { ReactElement, ReactNode } from 'react';

import { Box, Table, styled } from '@qwealth/qcore';

import { getLabel } from 'data/translations/defaultLabels';
import StyledTableContainer from './StyledTableContainer';
import { COLORS } from 'theme/colors';

const TotalContainer = styled(Box)`
  background-color: ${COLORS.primaryBackground};
  color: ${COLORS.primary};
`;

type Column = {
  dataField: string;
  text: string;
};

type Props = {
  accountColumns: Column[];
  records: any;
  totalValue?: string;
  hideTotal?: boolean;
  light?: boolean;
  children?: ReactNode;
};

const StyledTable: React.FC<Props> = ({
  accountColumns,
  records,
  totalValue,
  hideTotal,
  light,
  children,
}): ReactElement => {
  return (
    <StyledTableContainer light={light}>
      <Table
        // @ts-ignore
        columns={accountColumns}
        data={records}
        keyField="comment"
      />
      {children}
      {!hideTotal && (
        <TotalContainer
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="large"
          textStyle="sectionTitle"
          borderRadius="large"
        >
          <Box>{getLabel('common.total')}</Box>
          <Box marginRight="small">{totalValue}</Box>
        </TotalContainer>
      )}
    </StyledTableContainer>
  );
};

export default StyledTable;
