import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import { groupBy } from 'lodash';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { EntityAccount } from 'data/types';

const styles = StyleSheet.create({
  title: {
    fontSize: 16,
    fontWeight: 'medium',
    color: COLORS.PRIMARY_LIGHT,
    textAlign: 'center',
    marginVertical: 25,
    paddingHorizontal: 30,
  },
  column: {
    width: '49%',
  },
  box: {
    paddingHorizontal: 10,
    backgroundColor: '#79bb56',
    height: 200,
    justifyContent: 'center',
  },
  boxTitle: {
    color: 'white',
    fontSize: 18,
    textAlign: 'center',
    fontWeight: 'medium',
  },
  boxDescription: {
    color: 'white',
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 'medium',
  },
  account: {
    backgroundColor: COLORS.GRAY1,
    color: '#aaa',
    fontSize: 12,
    fontWeight: 'medium',
    width: '100%',
    paddingVertical: 10,
    textAlign: 'center',
    marginTop: 5,
  },
});

const colors = ['#79bb56', '#d39c4d'];

interface Props {
  rtqNum: number;
  proposalAccounts?: Array<EntityAccount>;
}

const ReportInvestmentStrategies = ({ rtqNum, proposalAccounts }: Props) => {
  const groupedAccounts = groupBy(proposalAccounts, 'modelPortfolio.modelName');

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>{`RTQ #${rtqNum}: INVESTMENT STRATEGIES`}</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={commonStyles.Content}>
        <View style={styles.title}>
          <Text>Based on consultation with your Portfolio Manager,</Text>
          <Text style={{ marginTop: 3 }}>
            collectively you have selected the following investment strategies
          </Text>
        </View>

        <Text style={{ fontSize: 14, textAlign: 'center', marginBottom: 8 }}>
          For this RTQ we have assigned accounts:
        </Text>

        <View
          style={[
            commonStyles.Row,
            commonStyles.JustifyContentSpaceBetween,
            { marginTop: 10, paddingHorizontal: 30 },
          ]}
        >
          {Object.keys(groupedAccounts).map((key, index) => (
            <View key={key} style={styles.column}>
              <View style={[styles.box, { height: 120, backgroundColor: colors[index] }]}>
                <Text style={[styles.boxTitle, commonStyles.BoldFont]}>{key}</Text>
                <Text style={[styles.boxTitle, { marginTop: 3 }]}>
                  {groupedAccounts[key][0]?.modelPortfolio?.mandateName}
                </Text>
              </View>
              {groupedAccounts[key].map(account => (
                <Text key={account.accountNumber} style={styles.account}>
                  {account.accountOwnership} - {account.accountType}
                </Text>
              ))}
            </View>
          ))}
        </View>
      </View>
    </Page>
  );
};

export default ReportInvestmentStrategies;
