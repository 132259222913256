import {
  GET_FINANCIAL_MODELS_REQUEST,
  GET_FINANCIAL_MODELS_SUCCESS,
  GET_FINANCIAL_MODELS_FAILURE,
} from '../actions/types';

import { FinancialModels } from '../types';

import { mapFinancialModelsData } from '../mappers';

export interface IFinancialModelsState {
  isGettingFinancialModels: boolean;
  financialModels: FinancialModels;
  isFinancialModelsError: boolean;
}

const DEFAULT_STATE: IFinancialModelsState = {
  isGettingFinancialModels: false,
  financialModels: {},
  isFinancialModelsError: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case GET_FINANCIAL_MODELS_REQUEST: {
      return {
        ...state,
        isGettingFinancialModels: true,
        isFinancialModelsError: false,
      };
    }
    case GET_FINANCIAL_MODELS_SUCCESS: {
      return {
        ...state,
        isGettingFinancialModels: false,
        financialModels: {
          ...state.financialModels,
          ...mapFinancialModelsData(action.payload),
        },
        isFinancialModelsError: false,
      };
    }
    case GET_FINANCIAL_MODELS_FAILURE: {
      return {
        ...state,
        isGettingFinancialModels: false,
        isFinancialModelsError: true,
      };
    }

    default:
      return state;
  }
}
