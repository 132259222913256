export const GET_SECURITY_EXCPETION_REQUEST = 'GET_SECURITY_EXCPETION_REQUEST';
export const GET_SECURITY_EXCPETION_SUCCESS = 'GET_SECURITY_EXCPETION_SUCCESS';
export const GET_SECURITY_EXCPETION_FAILURE = 'GET_SECURITY_EXCPETION_FAILURE';

export const CREATE_SECURITY_EXCPETION_REQUEST = 'CREATE_SECURITY_EXCPETION_REQUEST';
export const CREATE_SECURITY_EXCPETION_SUCCESS = 'CREATE_SECURITY_EXCPETION_SUCCESS';
export const CREATE_SECURITY_EXCPETION_FAILURE = 'CREATE_SECURITY_EXCPETION_FAILURE';

export const UPDATE_SECURITY_EXCPETION_REQUEST = 'UPDATE_SECURITY_EXCPETION_REQUEST';
export const UPDATE_SECURITY_EXCPETION_SUCCESS = 'UPDATE_SECURITY_EXCPETION_SUCCESS';
export const UPDATE_SECURITY_EXCPETION_FAILURE = 'UPDATE_SECURITY_EXCPETION_FAILURE';

export const DELETE_SECURITY_EXCPETION_REQUEST = 'DELETE_SECURITY_EXCPETION_REQUEST';
export const DELETE_SECURITY_EXCPETION_SUCCESS = 'DELETE_SECURITY_EXCPETION_SUCCESS';
export const DELETE_SECURITY_EXCPETION_FAILURE = 'DELETE_SECURITY_EXCPETION_FAILURE';
