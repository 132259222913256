import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, styled, themeGet } from '@qwealth/qcore';
import { AiFillCheckCircle } from 'react-icons/ai';

const arrowLength = '25px';
const adjustmentDisplacement = '40px';
const leftMostStageMargin = '15px';
const displacedStageMargin = '50px';

const StagesNavContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  width: calc(100% - ${adjustmentDisplacement});
`;

const StageTabContainer = styled.div`
  display: flex;
  filter: drop-shadow(15px 0px 15px rgb(0 0 0 / 10%));
  margin-right: -${adjustmentDisplacement};
  border-radius: 15px 0 0 15px;
  flex: 1;
  cursor: pointer;
`;

const StageTabContentContainerStyled = styled(Box)`
  display: flex;
  height: 60px;
  min-width: 200px;
  clip-path: polygon(
    0% 0%,
    calc(100% - ${arrowLength}) 0%,
    100% 50%,
    calc(100% - ${arrowLength}) 100%,
    0% 100%
  );
  flex: 1;
  align-items: center;
  border-radius: 15px 0 0 15px;
`;

const StageTabContentStyled = styled(Box)`
  display: flex;
  margin-left: ${displacedStageMargin};
  span {
    margin-left: 10px;
    align-self: center;
    padding-right: 15px;
    font-size: ${themeGet('fontSizes.medium')};
    font-weight: ${themeGet('fontWeights.bolder')};
  }
`;

const StageIcon = styled(Box)`
  background: #a9cb8b;
  display: flex;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
`;

interface StageColors {
  background: string;
  color: string;
}

const getStageColors = (stage, selectedActiveStageIndex): StageColors => {
  const colors = {
    background: 'none',
    color: '#84878f',
  };
  if (stage) {
    if (
      stage.status === 'complete' ||
      stage.status === 'in-process' ||
      stage.id === selectedActiveStageIndex
    ) {
      colors.background = '#DECFD8';
      if (stage.id === selectedActiveStageIndex) {
        colors.color = 'rgb(94,21,65,1)';
      } else {
        colors.color = 'rgb(94,21,65,0.5)';
      }
    } else if (stage.status === 'incomplete') {
      colors.background = '#fff';
      colors.color = '#84878f';
    }
  }
  return colors;
};

const defaultStyle = (): React.CSSProperties => ({
  marginLeft: displacedStageMargin,
});

interface StageTabProps extends React.HTMLAttributes<HTMLDivElement> {
  contentStyles: React.CSSProperties;
  contentContainerStyles: React.CSSProperties;
  showIcon: boolean;
  children: string;
}

const StageTab: React.FC<StageTabProps> = ({
  contentStyles,
  contentContainerStyles,
  showIcon = false,
  children,
  ...props
}) => (
  <StageTabContainer {...props} className="stage-tab-container">
    <StageTabContentContainerStyled style={contentContainerStyles}>
      <StageTabContentStyled style={contentStyles}>
        {showIcon && (
          <StageIcon>
            <AiFillCheckCircle />
          </StageIcon>
        )}
        <span>{children}</span>
      </StageTabContentStyled>
    </StageTabContentContainerStyled>
  </StageTabContainer>
);

interface StageItem {
  id: number;
  name: string;
  status: string;
}

export declare type ActiveStage = {
  id: number;
  stageName: string;
  stageStatus: 0 | 1;
};

const getStagesData = (
  stages: Array<ActiveStage>,
  selectedActiveStageIndex: number,
): { stagesData: Array<StageItem>; inProcessStageIndex: number } => {
  const stagesData: Array<StageItem> = [];

  // if id:4 has stageStatus 1 then selectedActiveStageIndex should be id
  // else if id:3 has stageStatus 1 then selectedActiveStageIndex should be id 3
  // else id should be 0
  const completedStages = stages.filter(stage => stage.stageStatus);

  const inProcessStageIndex =
    completedStages.length && completedStages[completedStages.length - 1].id;

  const stageVisited =
    selectedActiveStageIndex > inProcessStageIndex
      ? selectedActiveStageIndex + 1
      : inProcessStageIndex;

  stages.forEach(stage => {
    let stageStatus = 'complete';
    if (stage.stageStatus === 0 && stage.id < stageVisited) {
      stageStatus = 'in-process';
    } else if (stage.stageStatus === 0) {
      stageStatus = 'incomplete';
    }

    stagesData.push({
      id: stage.id,
      name: stage.stageName || '-',
      status: stageStatus,
    });
  });
  return { stagesData, inProcessStageIndex };
};

interface Props {
  stages: Array<ActiveStage>;
  selectedActiveStageIndex: number;
  setSelectedActiveStageIndex: (stage: number) => void;
}

const Stages: React.FC<Props> = ({
  stages,
  selectedActiveStageIndex,
  setSelectedActiveStageIndex,
}) => {
  const stagesRef = useRef<HTMLDivElement>(null);
  const [variableStyles, setVariableStyles] = useState<React.CSSProperties[]>([]);
  const [stagesData, setStagesData] = useState<Array<StageItem>>([]);

  useEffect(() => {
    const { stagesData, inProcessStageIndex } = getStagesData(stages, selectedActiveStageIndex);
    setStagesData(stagesData);
    if (selectedActiveStageIndex === -1) {
      setSelectedActiveStageIndex(inProcessStageIndex);
    }
  }, [stages, setStagesData, selectedActiveStageIndex, setSelectedActiveStageIndex]);

  const setStagesStyles = useCallback(() => {
    const stagesEl = stagesRef.current;
    const stageContentList = stagesEl ? stagesEl.querySelectorAll('.stage-tab-container') : [];
    const stageStyles: React.CSSProperties[] = [];
    stageContentList.forEach(el => {
      const style: React.CSSProperties = defaultStyle();
      if (stagesEl && stagesEl.offsetLeft === el.offsetLeft) {
        style.marginLeft = leftMostStageMargin;
      } else {
        style.marginLeft = displacedStageMargin;
      }
      stageStyles.push(style);
    });
    setVariableStyles(stageStyles);
  }, [setVariableStyles, stagesRef]);

  useEffect(() => {
    window.addEventListener('resize', setStagesStyles);
    setStagesStyles();
    return () => {
      window.removeEventListener('resize', setStagesStyles);
    };
  }, [setStagesStyles]);

  return (
    <StagesNavContainer ref={stagesRef}>
      {stagesData.map((stage, stageIndex) => (
        <StageTab
          key={stage.id}
          style={{ zIndex: stagesData.length - stageIndex }}
          contentContainerStyles={{
            ...getStageColors(stage, selectedActiveStageIndex),
          }}
          contentStyles={variableStyles[stageIndex] ? variableStyles[stageIndex] : defaultStyle()}
          showIcon={stage.status === 'complete'}
          onClick={() => setSelectedActiveStageIndex(stage.id)}
        >
          {stage.name}
        </StageTab>
      ))}
    </StagesNavContainer>
  );
};

export default Stages;
