import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Card } from '@qwealth/qcore';

import Title from 'components/common/Title';
import HeaderSecondary from 'components/common/HeaderSecondary';

import { RootState } from '../../data/store';

const Households: React.FC = () => {
  const user = useSelector(({ microsoft }: RootState) => microsoft.user);

  return (
    <Box flex="1" display="flex" flexDirection="column">
      <HeaderSecondary>
        <Box display="flex" alignItems="center">
          <Box as="span" fontWeight="normal">
            Welcome Back,&nbsp;
          </Box>
          {user.displayName}
        </Box>
      </HeaderSecondary>

      <Card flex="1" display="flex">
        <Box>
          <Title>QFolio Summary</Title>
          <Box>Find a household to begin exploring!</Box>
        </Box>
      </Card>
    </Box>
  );
};

export default Households;
