import React, { ReactElement, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, Card, styled, toCurrency } from '@qwealth/qcore';
import { TitlePrimary } from 'components/common/Title';

import { ProposalInfoSelectors } from '../../../data/selectors';
import { getLabel } from 'data/translations/defaultLabels';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const Avatar = styled.img`
  vertical-align: middle;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 50%;
`;

const TitleSecondary = styled.div`
  font-weight: 600;
  font-size: 16px;
`;

const recordsBaseline = [
  { key: 'name', title: getLabel('common.name'), header: true },
  { key: 'age', title: getLabel('common.age') },
  { key: 'netWorth', title: getLabel('common.net.worth') },
  { key: 'income', title: getLabel('common.income') },
  { key: 'qbias', title: getLabel('common.qbias') },
];

type RecordType = {
  title: string;
  details: string[];
  header?: boolean;
};

const Owners: React.FC = (): ReactElement => {
  const isLoading = useSelector(ProposalInfoSelectors.isGettingOwners);
  const proposalOwners = useSelector(ProposalInfoSelectors.owners);
  const isLegalEntity = useSelector(ProposalInfoSelectors.isLegalEntityOwners);

  const [records, setRecords] = useState<RecordType[]>([]);

  useEffect(() => {
    // Mapping from data to records
    const mappedData = proposalOwners.map(rec => ({
      name: rec.name,
      age: rec.age,
      netWorth: rec.netWorth > 0 ? toCurrency(rec.netWorth) : '-',
      income: rec.income > 0 ? toCurrency(rec.income) : '-',
      qbias: rec.qbias?.join('\n'),
      QID: rec.QID,
    }));

    setRecords(
      recordsBaseline
        .filter(r => !isLegalEntity || !['age', 'qbias'].includes(r.key))
        .map(rec => {
          const details: string[] = [];
          mappedData.forEach(item => {
            const val = item[rec.key];
            if (val && details.indexOf(val) === -1) {
              details.push(val);
            }
          });

          return {
            ...rec,
            details,
          };
        }),
    );
  }, [proposalOwners]);

  return (
    <Card
      loading={isLoading}
      display="flex"
      flexDirection="column"
      gap="default"
      backgroundColor="background"
    >
      <TitlePrimary>{getLabel('proposal.owners.of.accounts')}</TitlePrimary>

      {records.map((record, index) => (
        <Box key={index} display="flex" flexDirection="row" paddingBottom="small">
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            padding="large"
            width={125}
            borderTopLeftRadius="large"
            borderBottomLeftRadius="large"
            style={{ backgroundColor: record.header ? 'transparent' : COLORS.primaryBackground }}
          >
            {!record.header && (
              <Box
                textStyle="formLabel"
                textTransform="uppercase"
                paddingLeft="small"
                marginBottom="none"
                style={{ color: COLORS.primary }}
              >
                {record.title}
              </Box>
            )}
          </Box>

          {record.details.map(value => (
            <Box
              key={value}
              flexDirection={record.header ? 'column' : 'row'}
              flex={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding="large"
              paddingBottom={record.header ? 'none' : 'large'}
              paddingTop={record.header ? 'none' : 'large'}
              backgroundColor={record.header ? 'transparent' : 'white'}
            >
              {record.header && <Avatar src={'/images/profileAvatar.svg'} />}
              <TitleSecondary>{value}</TitleSecondary>
            </Box>
          ))}
        </Box>
      ))}
    </Card>
  );
};

export default Owners;
