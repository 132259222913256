import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PDFViewer, Document, Font } from '@react-pdf/renderer';
import * as ChartGeo from 'chartjs-chart-geo';

import { IPerson } from '@qwealth/qdata';
import {
  selectHouseholdMetadata,
  selectPrimary,
  selectSecondary,
} from 'data/selectors/householdSelectors';

// PdfReport Chunks
import ReportCover from '../chunks/ReportCover';
import ReportOverview from '../chunks/ReportOverview';
import ReportContent from '../chunks/ReportContent';
import ReportCover1 from '../chunks/ReportCover1';
import ReportAccountOwnership from '../chunks/ReportAccountOwnership';
import ReportInvestmentObjectives1 from '../chunks/ReportInvestmentObjectives1';
import ReportInvestmentObjectives2 from '../chunks/ReportInvestmentObjectives2';
import ReportYourQScore from '../chunks/ReportYourQScore';
import ReportBehavioralConsistency from '../chunks/ReportBehavioralConsistency';
import ReportInvestmentStrategies from '../chunks/ReportInvestmentStrategies';
import ReportAssetAllocations from '../chunks/ReportAssetAllocations';
import ReportSecurityAllocation from '../chunks/ReportSecurityAllocation';
import ReportPortfolioAnalysisCover from '../chunks/ReportPortfolioAnalysisCover';
import ReportAssetAllocations1 from '../chunks/ReportAssetAllocations1';
import ReportGeographicBreakdown from '../chunks/ReportGeographicBreakdown';
import ReportPerformance from '../chunks/ReportPerformance';
import ReportEquityStyle from '../chunks/ReportEquityStyle';
import ReportEquityStats from '../chunks/ReportEquityStats';
import ReportFixedIncomeStyle from '../chunks/ReportFixedIncomeStyle';
import ReportFeesExpenses from '../chunks/ReportFeesExpenses';
import ReportCorrelationMatrix from '../chunks/ReportCorrelationMatrix';
import ReportRiskScatter from '../chunks/ReportRiskScatter';
import ReportProprietary from '../chunks/ReportProprietary';

import './index.scss';

// TYPES
import { AggregatedAnalyticsData } from '../chunks/interfaces/AggregatedAnalyticsData';
import { useAggregateAnalyticsData } from '../chunks/hooks/useAggregateAnalyticsData';
import { useAggregateQFolioData } from '../chunks/hooks/useAggregateQFolioData';
import { AggregatedQFolioData } from '../chunks/interfaces/AggregatedQFolioData';

Font.registerHyphenationCallback((word: string) => word.split(''));
Font.register({
  family: 'Raleway',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/fonts-raleway@0.0.4/fonts/fonts-raleway/Raleway-Light.ttf',
      fontWeight: 300,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/fonts-raleway@0.0.4/fonts/fonts-raleway/Raleway-Regular.ttf',
      fontWeight: 400,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/fonts-raleway@0.0.4/fonts/fonts-raleway/Raleway-Medium.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/fonts-raleway@0.0.4/fonts/fonts-raleway/Raleway-SemiBold.ttf',
      fontWeight: 600,
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/fonts-raleway@0.0.4/fonts/fonts-raleway/Raleway-Bold.ttf',
      fontWeight: 700,
    },
  ],
});
Font.registerHyphenationCallback(word => [word]);

// Interfaces
type ReportBuildProps = {
  primary?: string;
  secondary?: string;
  dataAnalytics?: AggregatedAnalyticsData | null;
  dataQFolio?: AggregatedQFolioData | null;
  countries?: Array<object>;
};

const ReportPageBuilder = ({
  primary,
  secondary,
  dataAnalytics = null,
  dataQFolio = null,
  countries = [],
}: ReportBuildProps) => (
  <Document>
    <ReportCover primary={primary} secondary={secondary} />
    <ReportOverview rtqCount={dataQFolio?.rtqIDs?.length ?? 0} />

    <ReportContent dataQFolio={dataQFolio} />

    {dataQFolio?.rtqIDs?.map((rtqID, index) => {
      const proposalOwners = dataQFolio?.proposalOwners?.[rtqID] ?? [];
      const isLegalEntityOwners = proposalOwners.every(owner => owner.QID.includes('-3-'));
      const proposalMandate = dataQFolio?.proposalMandate?.[rtqID];
      const proposalAccounts = dataQFolio?.proposalAccounts?.[rtqID];
      const proposalAssetRanges = dataQFolio?.proposalAssetRanges?.[rtqID];
      const proposalModels = dataQFolio?.proposalModels?.[rtqID];
      const proposalInconsistencies = dataQFolio?.proposalInconsistencies?.[rtqID];
      const rtqNum = index + 1;

      return (
        proposalOwners &&
        proposalMandate &&
        proposalAssetRanges &&
        proposalAccounts &&
        proposalModels && (
          <>
            <ReportCover1 rtqNum={rtqNum} />
            <ReportAccountOwnership
              rtqNum={rtqNum}
              proposalAccounts={proposalAccounts}
              proposalOwners={proposalOwners}
              isLegalEntityOwners={isLegalEntityOwners}
            />
            <ReportInvestmentObjectives1
              rtqNum={rtqNum}
              proposalAssetRanges={proposalAssetRanges}
              proposalMandate={proposalMandate}
            />
            <ReportInvestmentObjectives2 rtqNum={rtqNum} proposalAccounts={proposalAccounts} />
            {!isLegalEntityOwners && (
              <ReportYourQScore rtqNum={rtqNum} proposalInconsistencies={proposalInconsistencies} />
            )}
            {!isLegalEntityOwners && (
              <ReportBehavioralConsistency
                rtqNum={rtqNum}
                proposalOwners={proposalOwners}
                proposalInconsistencies={proposalInconsistencies}
              />
            )}
            <ReportInvestmentStrategies rtqNum={rtqNum} proposalAccounts={proposalAccounts} />
            <ReportAssetAllocations rtqNum={rtqNum} proposalModels={proposalModels} />
            <ReportSecurityAllocation rtqNum={rtqNum} proposalModels={proposalModels} />
          </>
        )
      );
    })}

    <ReportPortfolioAnalysisCover />
    <ReportAssetAllocations1
      score={dataAnalytics?.score?.Diversification}
      assetAllocation={dataAnalytics?.assetAllocation}
    />
    <ReportGeographicBreakdown countries={countries} domicile={dataAnalytics?.domicile} />
    <ReportPerformance data={dataAnalytics?.cumrets} />
    <ReportEquityStyle data={dataAnalytics?.riskExp} />
    <ReportEquityStats
      score={dataAnalytics?.score?.Performance.global}
      fratios={dataAnalytics?.fratios}
      crisisRets={dataAnalytics?.crisisRets}
    />
    <ReportFixedIncomeStyle
      score={dataAnalytics?.score?.Income}
      tickersOriginal={dataAnalytics?.tickersOriginal}
      portYield={dataAnalytics?.portYield}
    />
    <ReportFeesExpenses
      score={dataAnalytics?.score?.Fees}
      tickersValid={dataAnalytics?.tickersValid}
      tickersOriginal={dataAnalytics?.tickersOriginal}
      portMER={dataAnalytics?.portMER}
    />
    <ReportCorrelationMatrix data={dataAnalytics?.corr} />
    <ReportRiskScatter
      tickersValid={dataAnalytics?.tickersValid}
      riskCont={dataAnalytics?.riskCont}
      retCont={dataAnalytics?.retCont}
    />
    <ReportProprietary data={dataAnalytics?.riskExp} />
  </Document>
);

export const ReportSummary = () => {
  const { isLoading, name } = useSelector(selectHouseholdMetadata);
  const primary = useSelector(selectPrimary) as IPerson;
  const secondary = useSelector(selectSecondary) as IPerson;

  const primaryText = primary?.firstName ? primary.firstName + ' ' + primary.lastName : name;
  const secondaryText = secondary?.firstName
    ? secondary.firstName + ' ' + secondary.lastName
    : undefined;

  const dataAnalytics: AggregatedAnalyticsData | undefined = useAggregateAnalyticsData();
  const dataQFolio: AggregatedQFolioData | null = useAggregateQFolioData();
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    (async () => {
      if (countries.length === 0) {
        const data = await fetch('https://unpkg.com/world-atlas/countries-50m.json').then(r =>
          r.json(),
        );

        // @ts-ignore
        const countries = ChartGeo.topojson.feature(data, data.objects.countries).features;
        setCountries(countries);
      }
    })();
  }, []);

  return (
    <div className="reports-summary">
      <PDFViewer className="pdf-viewer">
        {!isLoading && dataQFolio && dataAnalytics ? (
          <ReportPageBuilder
            primary={primaryText}
            secondary={secondaryText}
            dataAnalytics={dataAnalytics}
            dataQFolio={dataQFolio}
            countries={countries}
          />
        ) : (
          <></>
        )}
      </PDFViewer>
    </div>
  );
};
