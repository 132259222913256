import { ADD_RECENT_HISTORY, RecentHistoryAction } from '../actions/types';

import { RecentHistory } from '../types';

const RECENT_HISTORY_KEY = 'qfolio_recent_history';

function immutablySwapItems(items, firstIndex, secondIndex) {
  const results = [...items];
  const firstItem = items[firstIndex];
  results[firstIndex] = {...items[secondIndex]};
  if (firstIndex !== secondIndex) {
    results[secondIndex] = firstItem;
  }
  return results;
}

function updateLocalStorage(newState) {
  localStorage.setItem(RECENT_HISTORY_KEY, JSON.stringify(newState));
  return newState;
}

const recentlyViewed = localStorage.getItem(RECENT_HISTORY_KEY);
const initialState: Array<RecentHistory> = recentlyViewed ? JSON.parse(recentlyViewed) : [];

export default function (state = initialState, action: RecentHistoryAction): Array<RecentHistory> {
  switch (action.type) {
    case ADD_RECENT_HISTORY: {
      const { newRecent } = action;
      const index = state.findIndex(r => r.name.toLowerCase() === newRecent.name.toLowerCase());
      if (index > -1) {
        return updateLocalStorage(immutablySwapItems(state, 0, index));
      }
      return updateLocalStorage([newRecent, ...state.slice(0, 10)]);
    }

    default:
      return state;
  }
}
