import { fetchPartner, fetchUserByUsername } from '@qwealth/qdata';
import { errorHandler } from '../../../services/axiosService';
import { PUT_CURRENT_USER, PUT_CURRENT_PARTNER } from '../types';
import { range } from 'lodash';

const putCurrentUser = user => ({ type: PUT_CURRENT_USER, payload: user });
const putPartner = partner => ({ type: PUT_CURRENT_PARTNER, payload: partner });

const staffRoles = [...range(1, 6), ...range(7, 12)];

export const loadUserInfo = (username: string) => async dispatch => {
  try {
    const user = await fetchUserByUsername(username, staffRoles);
    dispatch(putCurrentUser(user));
    if (user.partnerID) {
      const partner = await fetchPartner(user.partnerID);
      dispatch(putPartner(partner));
    } else {
      dispatch(putPartner(null));
    }
  } catch (e) {
    errorHandler(dispatch)(e);
  }
};
