import React, { ReactElement, useEffect, useState } from 'react';
import { Image } from '@react-pdf/renderer';
import ChartJs from 'chart.js/auto';
import { Chart } from 'chart.js';
import type { ChartConfiguration } from 'chart.js';
import { ChoroplethController, GeoFeature, ColorScale, ProjectionScale } from 'chartjs-chart-geo';

const defaultOptions = {
  responsive: true,
  maintainAspectRatio: false,
};

Chart.register(ChoroplethController, GeoFeature, ColorScale, ProjectionScale);

export const useChartJsAsImage = (
  config: ChartConfiguration,
  options: object = {},
): ReactElement | null => {
  const [base64, setBase64] = useState<string | null>(null);

  useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.style.display = 'none';

    document.body.append(canvas);

    new ChartJs(
      canvas,
      Object.assign(config, {
        width: 1200,
        plugins: [
          ...(config?.plugins ?? []),
          {
            // Saves chart to base64 once it's rendered
            afterRender: (c: any) => {
              setBase64(c.toBase64Image());
            },
          },
        ],
        options: { ...defaultOptions, ...options },
      }),
    );

    return () => {
      if (canvas) document.body.removeChild(canvas);
    };
  }, [config]);

  if (!base64) return null;

  return <Image src={base64} />;
};
