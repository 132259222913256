import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';

const styles = StyleSheet.create({
  Title: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    fontWeight: 'bold',
  },
  Description: {
    fontSize: 10,
    marginTop: 20,
    lineHeight: '150%',
  },
  HeaderCell: {
    display: 'flex',
    flexDirection: 'row',
    height: 35,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    flex: 1,
  },
  ValueCell: {
    fontSize: 10,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    flex: 1,
  },
});

const createChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets'],
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets,
  },
});

interface Props {
  data?: object;
}

const ReportEquityStyle = ({ data }: Props) => {
  // @ts-ignore
  const styleFactors = data && data['G.EQ'];
  const values = data && Object.values(styleFactors);
  const datasets = [
    {
      data: values,
      backgroundColor: [COLORS.PRIMARY, '#c0d8d3', '#2371bb', '#21c9c3', COLORS.PRIMARY_LIGHT],
    },
  ];

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>EQUITY STYLE</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={[commonStyles.Content, { paddingHorizontal: 60 }]}>
        <Text style={styles.Title}>Risk Exposure Analysis</Text>
        <Text style={[styles.Description, { marginTop: 3 }]}>By Equity Style</Text>
        <Text style={styles.Description}>
          This section shows your portfolio’s risk attribution to the Fama-French-Carhart equity
          style factors: market size, value, and momentum. A negative style risk exposure means that
          your portfolio’s volatility can be explained by the opposite style. For example, if the
          exposure to value is negative, it means that your portfolio is exposed to growth stocks
          rather than value stocks. If the exposure to size is negative, it means that your
          portfolio is exposed to larger-cap stocks rather than smaller-cap stocks. If the exposure
          to momentum is negative, it means that your portfolio is exposed to short-term reversal
          rather than short-term momentum.
        </Text>

        <Text style={[styles.Title, { marginTop: 25, marginBottom: 10, fontSize: 12 }]}>
          Stock Portfolio Only
        </Text>
        <View style={[commonStyles.Row, commonStyles.Width100]}>
          <View
            style={[
              { width: '40%' },
              commonStyles.JustifyContentCenter,
              commonStyles.AlignItemsCenter,
            ]}
          >
            {/* @ts-ignore */}
            {useChartJsAsImage(createChartJsConfig('doughnut', [], values ? datasets : []))}
          </View>
          <View style={{ flex: 1 }}>
            <View style={[commonStyles.Row, commonStyles.Width100]}>
              <View style={styles.HeaderCell}>
                <Text>STYLE FACTOR</Text>
              </View>
              <View style={styles.HeaderCell}>
                <Text>STYLE RISK EXPOSURE</Text>
              </View>
            </View>

            {styleFactors &&
              Object.keys(styleFactors).map(key => (
                <View key={key} style={[commonStyles.Row, commonStyles.Width100]}>
                  <View style={styles.ValueCell}>
                    <Text>{key}</Text>
                  </View>
                  <View style={styles.ValueCell}>
                    <Text>{`${(styleFactors[key] * 100).toFixed(2)}%`}</Text>
                  </View>
                </View>
              ))}

            <View style={[commonStyles.Row, commonStyles.Width100, { borderBottom: 'none' }]}>
              <View style={[styles.ValueCell, commonStyles.BoldFont]}>
                <Text>Total</Text>
              </View>
              <View style={[styles.ValueCell, commonStyles.BoldFont]}>
                <Text>100%</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ReportEquityStyle;
