import React, { useMemo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';

import { toPercent } from '@qwealth/qcore';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';
import { AssetAllocation, ScoreTuple } from '../interfaces/AggregatedAnalyticsData';
import { getScoreValueLabel } from '../utils';

const styles = StyleSheet.create({
  Title: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    fontWeight: 'bold',
  },
  Description: {
    fontSize: 12,
    marginTop: 20,
  },
  HeaderCell: {
    display: 'flex',
    flexDirection: 'row',
    height: 35,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    flex: 1,
  },
  ValueCell: {
    fontSize: 10,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    flex: 1,
  },
});

const crateChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets'],
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets,
  },
});

interface Props {
  score?: ScoreTuple;
  assetAllocation?: AssetAllocation;
}

const ReportAssetAllocations1 = ({ score, assetAllocation }: Props) => {
  const labels = assetAllocation && Object.keys(assetAllocation);
  const values = assetAllocation && Object.values(assetAllocation).map(value => value * 100);

  const data = {
    labels: labels?.slice(0, 3),
    datasets: [
      {
        axis: 'y',
        label: 'Asset Allocation',
        data: values?.slice(0, 3),
        fill: false,
        backgroundColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(201, 203, 207)'],
        borderColor: ['rgb(255, 99, 132)', 'rgb(54, 162, 235)', 'rgb(201, 203, 207)'],
        borderWidth: 1,
      },
    ]
  };

  const {scoreValue, scoreLabel} =  useMemo(() => getScoreValueLabel(score), [score])
  
  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>ASSET ALLOCATION</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={[commonStyles.Content, { paddingHorizontal: 60 }]}>
        <Text style={styles.Title}>
          {`Risk, Return and Diversification: ${scoreValue} `}<Text style={commonStyles.Score}>{scoreLabel}</Text>
        </Text>
        <Text style={styles.Description}>
          This chart takes into account how your capital would be deployed across various asset
          classes, with each class providing its own unique risk, return, and diversification
          benefits.
        </Text>

        <View style={[commonStyles.Row, commonStyles.Width100, { marginTop: 70 }]}>
          <View style={{ width: '40%' }}>
            {/* @ts-ignore */}
            {useChartJsAsImage(crateChartJsConfig('bar', data.labels, data.datasets), {
              indexAxis: 'y',
              plugins: {
                legend: {
                  labels: {
                    font:{
                      size: 48
                    }
                  }
                }
              },
              scales: {
                x: {
                  ticks: {
                    font:{
                      size: 48
                    }
                  }
                },
                y: {
                  ticks: {
                    font:{
                      size: 48
                    }
                  }
                }
              }
            })}
          </View>
          <View style={{ flex: 1, paddingLeft: 50 }}>
            <View style={[commonStyles.Row, commonStyles.Width100]}>
              <View style={[styles.HeaderCell, { flex: 1.5 }]}>
                <Text>Asset Allocation</Text>
              </View>
              <View style={styles.HeaderCell}>
                <Text>Net %</Text>
              </View>
            </View>

            {assetAllocation &&
              Object.keys(assetAllocation).map(
                asset =>
                  asset !== 'shouldDisplay' && (
                    <View key={asset} style={[commonStyles.Row, commonStyles.Width100]}>
                      <View style={[styles.ValueCell, { flex: 1.5 }]}>
                        <Text>{asset}</Text>
                      </View>
                      <View style={styles.ValueCell}>
                        {/* @ts-ignore */}
                        <Text>{toPercent(assetAllocation[asset])}</Text>
                      </View>
                    </View>
                  ),
              )}
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ReportAssetAllocations1;
