import React, { FC, ReactElement, useEffect, useState, SetStateAction, Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { lighten } from 'polished';

import { Box, Button, Card, Modal, styled } from '@qwealth/qcore';

import { getLabel } from 'data/translations/defaultLabels';

import TopNavigation from 'components/common/TopNavigation';
import { TitlePrimary } from 'components/common/Title';
import Confirmation from '../Common/Confirmation';

import Banner from './Banner';
import { setDefaultInvestmentStrategy } from 'data/actions/creators/proposalInfo';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const StyledHeader = styled.span`
  color: ${COLORS.primary};
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 7px;
`;

const StyledPreHeader = styled(StyledHeader)`
  color: ${COLORS.lightGray};
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 0px;
`;

const StyledText = styled(StyledHeader)`
  color: ${COLORS.gray};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0px;
`;

const StyledButton = styled(Button)`
  background-color: ${COLORS.primary};
  &:before {
    background-color: ${lighten(0.05, COLORS.primary)};
  }
`;

const navItems = [
  {
    id: '0',
    videoId: '684320912',
    h: '2bd8cfb941',
    label: getLabel('proposal.investment.strategy.unconstrained'),
    bodyText: getLabel('proposal.investment.strategy.unconstrained.body'),
  },
  {
    id: '1',
    videoId: '684320544',
    h: 'fdb0608259',
    label: getLabel('proposal.investment.strategy.yield.focused'),
    bodyText: getLabel('proposal.investment.strategy.yield.focused.body'),
  },
  {
    id: '2',
    videoId: '684320162',
    h: '489adc2850',
    label: getLabel('proposal.investment.strategy.tax.optimized'),
    bodyText: getLabel('proposal.investment.strategy.tax.optimized.body'),
  },
  {
    id: '3',
    videoId: '684320015',
    h: 'fa6276ff59',
    label: getLabel('proposal.investment.strategy.low.cost'),
    bodyText: getLabel('proposal.investment.strategy.low.cost.body'),
  },
  {
    id: '4',
    videoId: '684319814',
    h: 'a120b02e3a',
    label: getLabel('proposal.investment.strategy.esg'),
    bodyText: getLabel('proposal.investment.strategy.esg.body'),
  },
];

type Props = {
  needConfirmation: boolean;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

const InvestmentStrategies: FC<Props> = ({
  needConfirmation,
  showModal,
  setShowModal,
}): ReactElement => {
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState('0');
  const [selectedRecord, setSelectedRecord] = useState(navItems[0]);

  useEffect(() => {
    setSelectedRecord(navItems[parseInt(selectedId)]);
  }, [selectedId, navItems]);

  const selectStrategy = () => {
    if (needConfirmation) {
      setShowConfirmation(true);
    } else {
      addModelBasedOnSelectedIS();
      closeModal();
    }
  };

  // add model based on selected IS
  const addModelBasedOnSelectedIS = () => {
    dispatch(setDefaultInvestmentStrategy(selectedRecord.label));
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const onClose = () => setShowConfirmation(false);

  const confirmationAction = () => {
    addModelBasedOnSelectedIS();
    onClose();
    closeModal();
  };

  return (
    <>
      <Confirmation
        showConfirmation={showConfirmation}
        title={getLabel('proposal.investment.strategy.override.confirmation.title')}
        body={getLabel('proposal.investment.strategy.override.confirmation.text')}
        setShowConfirmation={setShowConfirmation}
        confirmationAction={confirmationAction}
      />

      <Modal
        show={showModal}
        onClose={closeModal}
        title={getLabel('proposal.investment.strategy.select')}
        size="xl"
      >
        <Modal.Body>
          <Box>
            <Banner />
            <Card display="flex" flexDirection="column" gap="default">
              <TitlePrimary>{getLabel('proposal.investment.strategies')}</TitlePrimary>
              <TopNavigation
                selectedId={selectedId}
                navItems={navItems}
                onSelect={id => {
                  setSelectedId(id);
                }}
              />

              <Card
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                gap="default"
                marginTop="small"
                backgroundColor="gray.3"
              >
                <Box display="flex" flexDirection="column">
                  <StyledPreHeader>{getLabel('proposal.investment.strategy')}</StyledPreHeader>
                  <StyledHeader>{selectedRecord.label}</StyledHeader>
                  <StyledText>{selectedRecord.bodyText}</StyledText>
                </Box>
                <iframe
                  height="200"
                  src={`https://player.vimeo.com/video/${selectedRecord.videoId}?h=${selectedRecord.h}`}
                  frameBorder="0"
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                ></iframe>
              </Card>

              <StyledButton marginTop="small" width={100} onClick={selectStrategy}>
                {getLabel('proposal.investment.strategy.button')}
              </StyledButton>
            </Card>
          </Box>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvestmentStrategies;
