import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';
import { Contribution } from '../interfaces/AggregatedAnalyticsData';

const styles = StyleSheet.create({
  Title: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    fontWeight: 'bold',
  },
  Description: {
    fontSize: 10,
    marginTop: 20,
    lineHeight: '150%',
  },
  HeaderCell: {
    display: 'flex',
    flexDirection: 'row',
    height: 30,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    flex: 1,
  },
  ValueCell: {
    fontSize: 10,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    flex: 1,
  },
  SectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    height: 35,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 10,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
  },
});

const crateChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets'],
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets,
  },
});

interface Props {
  tickersValid?: object;
  riskCont?: Contribution;
  retCont?: Contribution;
}

const ReportRiskScatter = ({ tickersValid, riskCont, retCont }: Props) => {
  const labels = riskCont && Object.keys(riskCont.asset);
  const values = riskCont && Object.values(riskCont.asset).map(value => value * 100);

  const data = {
    labels: labels,
    datasets: [
      {
        axis: 'y',
        label: 'Asset Allocation',
        data: values,
        fill: false,
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        borderColor: [
          'rgb(255, 99, 132)',
          'rgb(255, 159, 64)',
          'rgb(255, 205, 86)',
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)',
          'rgb(153, 102, 255)',
          'rgb(201, 203, 207)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>RISK CONTRIBUTION ANALYSIS</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={[commonStyles.Content, { paddingHorizontal: 60 }]}>
        <View style={{ height: 175, paddingHorizontal: 150 }}>
          {/* @ts-ignore */}
          {useChartJsAsImage(crateChartJsConfig('bar', data.labels, data.datasets), {
            indexAxis: 'y',
            plugins: {
              legend: {
                labels: {
                  font: {
                    size: 48,
                  },
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  font: {
                    size: 24,
                  },
                },
              },
              y: {
                ticks: {
                  font: {
                    size: 24,
                  },
                },
              },
            },
          })}
        </View>

        <View style={[commonStyles.Width100, { marginTop: 15 }]}>
          <View style={[commonStyles.Row, commonStyles.Width100, { marginTop: 5 }]}>
            <View style={[styles.HeaderCell, { alignItems: 'center', flex: 3 }]}>
              <Text>Asset</Text>
            </View>

            <View style={[styles.HeaderCell, { alignItems: 'center' }]}>
              <Text>Asset Allocation</Text>
            </View>

            <View style={[styles.HeaderCell, { alignItems: 'center' }]}>
              <Text>Risk Contribution</Text>
            </View>

            <View style={[styles.HeaderCell, { alignItems: 'center' }]}>
              <Text>Return Contribution</Text>
            </View>
          </View>

          {tickersValid &&
            Object.keys(tickersValid).map((asset, index) => (
              <View
                key={`score-${index}`}
                style={[commonStyles.Row, commonStyles.Width100, { backgroundColor: COLORS.WHITE }]}
              >
                <View style={[styles.ValueCell, { flex: 3, justifyContent: 'flex-start' }]}>
                  {/*@ts-ignore*/}
                  <Text>{tickersValid[asset]['name']}</Text>
                </View>
                <View style={[styles.ValueCell, { flex: 1, justifyContent: 'center' }]}>
                  {/*@ts-ignore*/}
                  <Text>{(tickersValid[asset]['weight'] * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { flex: 1, justifyContent: 'center' }]}>
                  {/*@ts-ignore*/}
                  <Text>{(retCont.asset[asset] * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { flex: 1, justifyContent: 'center' }]}>
                  {/*@ts-ignore*/}
                  <Text>{(riskCont.asset[asset] * 100).toFixed(2)}%</Text>
                </View>
              </View>
            ))}
        </View>
      </View>
    </Page>
  );
};

export default ReportRiskScatter;
