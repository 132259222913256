import React, { ReactElement, useCallback } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { Box, styled } from '@qwealth/qcore';

import { RecordType } from './AssetRanges';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const Target = styled.div`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 14px solid;
  margin-bottom: 18px;
`;

const BaseContainer = styled(Box)`
  flex: 1;
  font-weight: bold;
  padding: 16px;
  text-align: center;
`;

const RedContainer = styled(BaseContainer)`
  background-color: ${COLORS.redBackground};
  color: ${COLORS.red};
`;

const GreenContainer = styled(BaseContainer)`
  background-color: ${COLORS.greenBackground};
  color: ${COLORS.green};
`;

const OrangeContainer = styled(BaseContainer)`
  background-color: ${COLORS.primaryBackground};
  color: ${COLORS.primary};
`;

const railStyle = { backgroundColor: '#AAAAAA' };

const dotStyle = { backgroundColor: 'transparent', borderColor: 'transparent' };

const trackStyle = [
  { backgroundColor: COLORS.primary },
  { backgroundColor: COLORS.green },
  { backgroundColor: COLORS.primary },
];

const handleStyle = [
  { borderColor: COLORS.primary, opacity: 1 },
  { backgroundColor: 'transparent', borderColor: 'transparent' },
  { backgroundColor: 'transparent', borderColor: 'transparent' },
  { borderColor: COLORS.primary, opacity: 1 },
];

interface Props {
  assetRange: RecordType;
  displayActual?: boolean;
  index: number;
}

const AssetRangeRecord: React.FC<Props> = ({ assetRange, index, displayActual }): ReactElement => {
  const generateMarks = useCallback(
    (values, actual, target) => {
      const marks = {};
      const labelStyle = { fontWeight: 700, color: COLORS.lightGray, fontSize: 14 };

      if (values.length === 4) {
        marks[values[0]] = { style: labelStyle, label: Math.round(values[0]) };
        marks[values[3]] = { style: labelStyle, label: Math.round(values[3]) };

        const roundedTarget = Math.round(target);

        if (displayActual) {
          marks[target] = { style: labelStyle, label: roundedTarget };

          if (actual <= 100) {
            marks[actual] = {
              style: labelStyle,
              label: (
                <div style={{ marginTop: -31.5 }}>
                  <Target style={{borderTopColor: assetRange.anchorColor}}/>
                  {actual === 0 && <span>{roundedTarget}</span>}
                </div>
              ),
            };
          }
        } else {
          marks[target] = {
            style: labelStyle,
            label: (
              <div style={{ marginTop: -31.5 }}>
                <Target style={{borderTopColor: assetRange.anchorColor}}/>
                <span>{roundedTarget}</span>
              </div>
            ),
          };
        }
      }

      return marks;
    },
    [displayActual, assetRange],
  );

  const getFormattedActualValue = useCallback(() => {
    let Wrapper = BaseContainer;
    const actualNumber = assetRange.actual ?? 0;
    const actual = isNaN(actualNumber) ? 0 : actualNumber;

    if (assetRange.value.length === 4) {
      const min = assetRange.value[0];
      const max = assetRange.value[3];

      const targetMin = assetRange.value[1];
      const targetMax = assetRange.value[2];

      if (actual < min || actual > max) {
        Wrapper = RedContainer;
      } else if ((actual < targetMin && actual > min) || (actual > targetMax && actual < max)) {
        Wrapper = OrangeContainer;
      } else if (actual >= targetMin && actual <= targetMax) {
        Wrapper = GreenContainer;
      }
    }

    return <Wrapper>{actual}</Wrapper>;
  }, [assetRange.value]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      borderRadius="large"
      paddingX={'large'}
      backgroundColor={index % 2 === 0 ? 'background' : 'transparent'}
    >
      <Box
        flex={1.5}
        textStyle="sectionTitle"
        fontSize="large"
        paddingTop="large"
        paddingBottom="small"
      >
        {assetRange.title}
      </Box>

      {displayActual && getFormattedActualValue()}

      <Box flex={3.5} paddingLeft="small" marginTop="small" marginBottom="small" padding="large">
        <Slider
          range
          disabled
          style={{ backgroundColor: 'transparent' }}
          count={3}
          defaultValue={assetRange.value}
          pushable
          dotStyle={dotStyle}
          trackStyle={trackStyle}
          handleStyle={handleStyle}
          railStyle={railStyle}
          marks={generateMarks(assetRange.value, assetRange.actual, assetRange.target)}
          draggableTrack={false}
        />
      </Box>
    </Box>
  );
};

export default AssetRangeRecord;
