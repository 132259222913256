import React, { ReactElement } from 'react';
import { Tooltip } from 'react-tooltip';
import { Box, styled } from '@qwealth/qcore';

import { RecordType } from './Accounts';
import { COLORS } from 'theme/colors';
import { SMALL_ACCOUNT_THRESHOLD } from 'data/refData';
import { getLabel } from 'data/translations/defaultLabels';

export const AccountContainer = styled(Box)`
  background-color: white !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: ${COLORS.gray} !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

type Props = {
  data: RecordType;
  displayValue: string;
};

const AccountItem: React.FC<Props> = ({ data, displayValue }): ReactElement => {
  const isSmallAccount = data.value < SMALL_ACCOUNT_THRESHOLD;
  return (
    <AccountContainer
      borderRadius="default"
      textStyle="sectionTitle"
      px="large"
      py="default"
      marginBottom="large"
    >
      <Box flex={1}>
        {data.name}
        {isSmallAccount ? (
          <p
            data-tooltip-content={getLabel('proposal.security.small.account.text')}
            data-tooltip-id="accountItemTooltip"
          >
            *
          </p>
        ) : null}
      </Box>
      <Box flex={0.55}>{data.account.type}</Box>
      <Box flex={0.75}>{displayValue}</Box>
      <Box flex={0.1} paddingRight="large" />
      <Box flex={0.1} paddingRight="large" />
      <Tooltip id="accountItemTooltip" />
    </AccountContainer>
  );
};

export default AccountItem;
