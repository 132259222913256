import React, { FC, ReactElement } from 'react';
import { Box, Card, styled } from '@qwealth/qcore';
import { getLabel } from 'data/translations/defaultLabels';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const StyledCard = styled(Card)`
  background-color: ${COLORS.primaryBackgroundLight};
`;

const StyledQuote = styled.span`
  font-size: 80px;
  font-weight: 700;
  color: ${COLORS.primary};
  margin-top: -20px;
`;

const StyledQuoteClose = styled(StyledQuote)`
  transform: matrix(-1, 0, 0, 1, 0, 0);
  display: flex;
  align-items: flex-end;
`;

const StyledText = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const StyledSigned = styled(StyledText)`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
`;

const StyledBold = styled(StyledText)`
  font-weight: 700;
`;

const Banner: FC = (): ReactElement => {
  return (
    <StyledCard display="flex" flexDirection="row" gap="default" marginBottom="large">
      <img height="200" src={'/images/larry.png'} />
      <StyledQuote>“</StyledQuote>
      <Box paddingTop="large">
        <StyledText>
          {getLabel('proposal.investment.strategy.intro')} <StyledBold>Larry Berman</StyledBold>,{' '}
          {getLabel('proposal.investment.strategy.body')}
        </StyledText>
        <Box paddingTop="large">
          <StyledBold>Larry Berman</StyledBold>
          <br />
          <StyledSigned>CIO, QWealth</StyledSigned>
        </Box>
      </Box>
      <StyledQuoteClose>“</StyledQuoteClose>
    </StyledCard>
  );
};

export default Banner;
