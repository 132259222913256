import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';

import { toPercent } from '@qwealth/qcore';

import { ProposalRtq } from 'data/types';
import { getLabel } from 'data/translations/defaultLabels';

Chart.register([ArcElement, Tooltip, Legend]);

type Props = {
  rtq: ProposalRtq;
};

const RtqDoughnut: FC<Props> = ({ rtq }): ReactElement => {
  const [labels, setLabels] = useState<string[]>();
  const [values, setValues] = useState<number[]>();

  useEffect(() => {
    const localLabels: string[] = [];
    const localValues: number[] = [];

    for (const key in rtq.weights) {
      localLabels.push(getLabel('common.' + key));
      localValues.push(rtq.weights[key]);
    }

    setLabels(localLabels);
    setValues(localValues);
  }, [rtq.weights]);

  return (
    <Doughnut
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: true,
          },
          tooltip: {
            callbacks: {
              label: function (tti) {
                return ` ${tti.label}: ${toPercent(tti.parsed)}`;
              },
            },
          },
        },
      }}
      data={{
        labels: labels,
        datasets: [
          {
            label: getLabel('proposal.summary.account.allocation'),
            data: values,
            backgroundColor: [
              'rgb(240, 252, 126)',
              'rgb(192, 212, 126)',
              'rgb(255, 205, 86)',
              'rgb(202, 212, 226)',
            ],
            hoverOffset: 4,
          },
        ],
      }}
    />
  );
};

export default RtqDoughnut;
