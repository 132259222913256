import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { BsTrash } from 'react-icons/bs';

import { Box, styled, Button, SelectInput2, CurrencyInput, PercentInput } from '@qwealth/qcore';

import { ModelSecurityMappedRecord, ProposalAccount } from 'data/types';
import {
  removeProposalAccountSecurity,
  updateProposalAccountSecurityAllocation,
  updateProposalAccountSecuritySelection,
} from 'data/actions/creators/proposalInfo';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const RecordContainer = styled(Box)`
  margin-bottom: 5px !important;
  border-radius: 3px !important;
`;

const Record = styled(Box)`
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 0.5rem 1rem;
`;

type Props = {
  security: ModelSecurityMappedRecord;
  securities: string[];
  account: ProposalAccount;
  displayPercent?: boolean;
};

const AccountItemRecord: React.FC<Props> = ({
  security,
  securities,
  account,
  displayPercent,
}): ReactElement => {
  const dispatch = useDispatch();

  return (
    <RecordContainer display="flex" flexDirection="row">
      <Box flex={3}>
        <SelectInput2
          data={securities}
          onChange={value => {
            dispatch(
              updateProposalAccountSecuritySelection({
                accountNumber: account.accountNumber,
                ticker: security.ticker,
                newTicker: value,
              }),
            );
          }}
          value={security.ticker}
        />
      </Box>
      <Box flex={3}>
        {displayPercent ? (
          <PercentInput
            value={security.allocatedPercent}
            allowNegative={false}
            onChange={val => {
              dispatch(
                updateProposalAccountSecurityAllocation({
                  accountNumber: account.accountNumber,
                  ticker: security.ticker,
                  allocatedPercent: val,
                }),
              );
            }}
          />
        ) : (
          <CurrencyInput
            value={security.allocated}
            allowNegative={false}
            onChange={val => {
              dispatch(
                updateProposalAccountSecurityAllocation({
                  accountNumber: account.accountNumber,
                  ticker: security.ticker,
                  allocatedPercent: val / account.value,
                }),
              );
            }}
          />
        )}
      </Box>
      <Record flex={1} display="flex" justifyContent="flex-end">
        <Button
          color="primary"
          size="small"
          variant="icon"
          onClick={() => {
            dispatch(
              removeProposalAccountSecurity({
                accountNumber: account.accountNumber,
                ticker: security.ticker,
              }),
            );
          }}
        >
          <BsTrash />
        </Button>
      </Record>
    </RecordContainer>
  );
};

export default AccountItemRecord;
