import { ISecurityExceptionState } from '../reducers/securityException';
import { createSelector } from 'reselect';

type SecurityExceptionState = {
  securityException: ISecurityExceptionState;
};

const selectSecurityException = (state: SecurityExceptionState) => state.securityException;

export const SecurityExceptionSelectors = {
  securityException: createSelector(
    selectSecurityException,
    securityException => securityException.securityException,
  ),
  isGettingSecurityException: createSelector(
    selectSecurityException,
    securityException => securityException.isGettingSecurityException,
  ),
  isGetSecurityExceptionError: createSelector(
    selectSecurityException,
    securityException => securityException.isGetSecurityExceptionError,
  ),
  isCreatingSecurityException: createSelector(
    selectSecurityException,
    securityException => securityException.isCreatingSecurityException,
  ),
  isCreateSecurityExceptionError: createSelector(
    selectSecurityException,
    securityException => securityException.isCreateSecurityExceptionError,
  ),
  isUpdatingSecurityException: createSelector(
    selectSecurityException,
    securityException => securityException.isUpdatingSecurityException,
  ),
  isUpdateSecurityExceptionError: createSelector(
    selectSecurityException,
    securityException => securityException.isUpdateSecurityExceptionError,
  ),
  isDeletingSecurityException: createSelector(
    selectSecurityException,
    securityException => securityException.isDeletingSecurityException,
  ),
  isDeleteSecurityExceptionError: createSelector(
    selectSecurityException,
    securityException => securityException.isDeleteSecurityExceptionError,
  ),
};
