import React, { Dispatch, FC, ReactElement, SetStateAction } from 'react';
import { useSelector } from 'react-redux';

import { Box, Button, styled } from '@qwealth/qcore';

import { ProposalInfoSelectors } from 'data/selectors';
import { getLabel } from 'data/translations/defaultLabels';
import { COLORS } from 'theme/colors';

const StyledButton = styled(Button)`
  background-color: ${COLORS.primary};
  min-width: 50px;
  max-height: 40px;
`;

type Props = {
  setSetupProposal: Dispatch<SetStateAction<boolean>>;
};

const Header: FC<Props> = ({ setSetupProposal }): ReactElement => {
  const summary = useSelector(ProposalInfoSelectors.summary);

  const hasRtqs = (summary?.rtqs || []).length > 0;

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="end"
      marginBottom="small"
      marginX="large"
    >
      {hasRtqs
        ? (
          <StyledButton
            color="primary"
            onClick={() => {
              setSetupProposal(true);
            }}
          >
            {getLabel('proposal.summary.create.proposal')}
          </StyledButton>
        )
        : getLabel('proposal.summary.noRtq')}
    </Box>
  );
};

export default Header;
