import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { VscGripper } from 'react-icons/vsc';

import { Box, styled } from '@qwealth/qcore';
import { toCurrency, toPercent } from '@qwealth/qcore/utils';

import { ModelRecord } from 'data/types';
import { ProposalInfoSelectors } from 'data/selectors';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const ModelContainer = styled(Box)`
  background-color: ${COLORS.background};
  color: ${COLORS.primary};
`;

const AccountContainer = styled(Box)`
  background-color: ${COLORS.primaryBackground};
  color: ${COLORS.primary};
  padding: 5px;
  border-radius: 4px;
`;

type Props = {
  index: number;
  model: ModelRecord;
  displayPercent: boolean;
};

const ModelSetupItemDraggable: React.FC<Props> = ({ index, model, displayPercent }) => {
  const data = useSelector(ProposalInfoSelectors.accounts);
  const totalRtqValue = useMemo(() => {
    return data.reduce((n, { value }) => n + value, 0)
  }, [data]);

  const totalValue = useMemo(() => {
    return model.accounts.reduce((prev, current) => prev + current.value, 0);
  }, [model.accounts]);

  const displayValue = useMemo(() => {
    return displayPercent ? toPercent(totalValue/totalRtqValue): toCurrency(totalValue);
  }, [displayPercent, totalValue, totalRtqValue])
  

  return (
    <ModelContainer
      width="190px"
      minHeight="185px"
      padding="large"
      textStyle="default"
      fontWeight="semiBold"
      textAlign="center"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Droppable droppableId={'model|' + model.id} type="ACCOUNT">
        {provided => {
          return (
            <Box paddingBottom="large" ref={provided.innerRef} {...provided.droppableProps}>
              {model.accounts?.map(account => (
                <Draggable
                  key={account.accountNumber}
                  draggableId={model.modelId + '|' + account.accountNumber}
                  index={index}
                >
                  {provided => {
                    return (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <AccountContainer
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="flex-start"
                          marginBottom="small"
                        >
                          <VscGripper size="18" color="gray" />
                          <Box marginLeft="small" fontWeight="bold" overflowX="hidden">
                            <Box>{account.name}</Box>
                            <Box>({account.type})</Box>
                          </Box>
                        </AccountContainer>
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          );
        }}
      </Droppable>
      <Box paddingBottom="small" fontWeight="semiBold" color="gray.1">
        {displayValue}
      </Box>
    </ModelContainer>
  );
};

export default ModelSetupItemDraggable;
