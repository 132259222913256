import * as Sentry from '@sentry/react';
import { ValidationError } from 'data/helpers/validationError';
import { putNotification } from '../data/actions/creators/notifications';

export const { REACT_APP_QWEALTH_API } = process.env;
export const { REACT_APP_QWEALTH_QC_API } = process.env;

export const getHouseholdId = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('household');
};

export const responseMapper = response => {
  const { data } = response.data;
  return data;
};

const isIgnorableError = error => {
  // We can place any errors that can be silently ignored here
  if (error === 'duplicate_request_intercepted') {
    return true;
  }
  return false;
};

export const errorHandler = (dispatch, errorText) => error => {
  Sentry.captureException(error);

  if (!isIgnorableError(error)) {
    console.error(error);
    return dispatch(putNotification(errorText || 'API Error. Please contact support', 'Error'));
  }
  console.warn(error);
  return null;
};

export const notifyError = error => dispatch => {
  const message = error instanceof ValidationError && error.message;

  errorHandler(dispatch, message)(error);
};
