import axios from 'axios';
import { apiUrl } from '@qwealth/qdata';
import { responseEntityMapper } from '@qwealth/qcore';
import { EntitySecurityException, BaseEntitySecurityException } from 'data/types';

export const getSecurityExceptionCall = async (exceptionId: string) =>
  await axios
    .get(`${apiUrl}/securityException/${exceptionId}`)
    .then(response => responseEntityMapper<EntitySecurityException>(response));

export const createSecurityExceptionCall = async (exception: BaseEntitySecurityException) =>
  await axios
    .post(`${apiUrl}/securityException`, exception)
    .then(response => responseEntityMapper<EntitySecurityException>(response));

export const updateSecurityExceptionCall = async (exception: BaseEntitySecurityException) =>
  await axios
    .patch(`${apiUrl}/securityException/${exception.exceptionId}`, exception)
    .then(response => responseEntityMapper<EntitySecurityException>(response));

export const deleteSecurityExceptionCall = async (exceptionId: string) =>
  await axios.delete(`${apiUrl}/securityException/${exceptionId}`);
