import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes } from 'react-router';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { SilentRequest } from '@azure/msal-browser';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import {
  AppLayout,
  Box,
  Button,
  Loader,
  getAccessAndIdToken,
  useAcquireToken,
} from '@qwealth/qcore';
import { QContext, selectUser } from '@qwealth/qdata';

import { loadUserInfo } from 'data/actions/creators/auth';
import { loadUser } from 'data/actions/creators/microsoft/user';
import LoginPage from 'components/Login';
import Sidebar from './components/common/Sidebar';
import { configureAxios } from './data/actions/creators/axios';
import Notifications from './components/common/Notifications';
import Header from './components/common/Header';
import './App.css';
import { Route } from 'react-router-dom';
import Proposal from 'components/Proposal';
import ProposalDetails from './components/Proposal/Details';
import DigitExport from './components/DigitExport';
import QfolioReport from './components/QFolioReport';

function App(): ReactElement {
  const { idToken, account, isInProgress } = useAcquireToken();
  const user = useSelector(selectUser);

  const [isAxiosInitialized, setAxiosInitialized] = useState(false);

  const dispatch = useDispatch();

  const refreshToken = useCallback(() => {
    return getAccessAndIdToken({ forceRefresh: true } as SilentRequest)
      .then(auth => {
        if (auth) {
          QContext.setIdToken(auth.idToken);
        }
      })
      .then(() => Auth.currentSession().then(() => console.log('Cognito connected!')))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (idToken && account) {
      Sentry.configureScope(scope => scope.setUser({ email: account.username }));

      QContext.setIdToken(idToken);
      dispatch(configureAxios());
      dispatch(loadUserInfo(account.username));
      dispatch(loadUser());

      setAxiosInitialized(true);

      // refresh the token every 10 minutes
      setInterval(refreshToken, 10 * 60 * 1000);
    }
  }, [idToken, account, dispatch]);

  if (!account) {
    return <LoginPage />;
  }

  if (isInProgress || !isAxiosInitialized || !user) {
    return (
      <AppLayout alignItems="center">
        <Box display="flex" flexDirection="column" margin="auto">
          <Loader />
          <Button
            onClick={() => {
              refreshToken().then(() => {
                history.go(0);
              });
            }}
          >
            Login
          </Button>
        </Box>
      </AppLayout>
    );
  }

  return (
    <AppLayout>
      <AuthenticatedTemplate>
        <Sidebar />
        <AppLayout.Main p="large" display="flex" flexDirection="column">
          <Header />
          <Routes>
            <Route index element={<Proposal />} />
            <Route path="/proposal/:householdQID" element={<ProposalDetails />} />
            <Route path="/digit-export/:householdQID" element={<DigitExport />} />
            <Route path="/report/:householdQID" element={<QfolioReport />} />
          </Routes>
          <Notifications />
        </AppLayout.Main>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage />
      </UnauthenticatedTemplate>
    </AppLayout>
  );
}

export default App;
