import { errorHandler } from '../../../services/axiosService';

import {
  SET_PROPOSAL_ACTIVE_RTQ,
  GET_PROPOSAL_SUMMARY_REQUEST,
  GET_PROPOSAL_SUMMARY_SUCCESS,
  GET_PROPOSAL_SUMMARY_FAILURE,
  GET_PROPOSAL_OWNERS_REQUEST,
  GET_PROPOSAL_OWNERS_SUCCESS,
  GET_PROPOSAL_OWNERS_FAILURE,
  GET_PROPOSAL_ACCOUNTS_REQUEST,
  GET_PROPOSAL_ACCOUNTS_SUCCESS,
  GET_PROPOSAL_ACCOUNTS_FAILURE,
  GET_PROPOSAL_MANDATE_REQUEST,
  GET_PROPOSAL_MANDATE_SUCCESS,
  GET_PROPOSAL_MANDATE_FAILURE,
  GET_PROPOSAL_INCONSISTENCIES_REQUEST,
  GET_PROPOSAL_INCONSISTENCIES_SUCCESS,
  GET_PROPOSAL_INCONSISTENCIES_FAILURE,
  GET_PROPOSAL_ASSET_RANGES_REQUEST,
  GET_PROPOSAL_ASSET_RANGES_SUCCESS,
  GET_PROPOSAL_ASSET_RANGES_FAILURE,
  SET_DEFAULT_INVESTMENT_STRATEGY,
  ADD_MODEL_TO_PROPOSAL,
  REMOVE_MODEL_FROM_PROPOSAL,
  RESET_PROPOSAL_MODEL_ACCOUNTS_SETUP,
  UPDATE_PROPOSAL_MODELS,
  AUTO_COMPLETE_PROPOSAL_ALLOCATION_MODEL,
  ADD_PROPOSAL_ACCOUNT_SECURITY,
  UPDATE_PROPOSAL_ACCOUNT_SECURITY_SELECTION,
  UPDATE_PROPOSAL_ACCOUNT_SECURITY_ALLOCATION,
  REMOVE_PROPOSAL_ACCOUNT_SECURITY,
} from '../types';

import {
  loadProposalSummary,
  loadProposalOwners,
  loadProposalAccounts,
  loadProposalMandate,
  loadProposalInconsistencies,
  loadProposalAssetRanges,
  saveProposalModels,
  saveProposalAllocations,
} from '../../data-layer';

import { AccountSecurityParam, ModelRecord } from 'data/types';
import { SMALL_ACCOUNT_THRESHOLD } from '../../refData';

const getProposalSummary = () => ({ type: GET_PROPOSAL_SUMMARY_REQUEST });
const getProposalSummarySuccess = data => ({ type: GET_PROPOSAL_SUMMARY_SUCCESS, payload: data });
const getProposalSummaryFailure = () => ({ type: GET_PROPOSAL_SUMMARY_FAILURE });

const getProposalOwners = () => ({ type: GET_PROPOSAL_OWNERS_REQUEST });
const getProposalOwnersSuccess = data => ({ type: GET_PROPOSAL_OWNERS_SUCCESS, payload: data });
const getProposalOwnersFailure = () => ({ type: GET_PROPOSAL_OWNERS_FAILURE });

const getProposalAccounts = () => ({ type: GET_PROPOSAL_ACCOUNTS_REQUEST });
const getProposalAccountsSuccess = data => ({ type: GET_PROPOSAL_ACCOUNTS_SUCCESS, payload: data });
const getProposalAccountsFailure = () => ({ type: GET_PROPOSAL_ACCOUNTS_FAILURE });

const getProposalMandate = () => ({ type: GET_PROPOSAL_MANDATE_REQUEST });
const getProposalMandateSuccess = data => ({ type: GET_PROPOSAL_MANDATE_SUCCESS, payload: data });
const getProposalMandateFailure = () => ({ type: GET_PROPOSAL_MANDATE_FAILURE });

const getProposalInconsistencies = () => ({ type: GET_PROPOSAL_INCONSISTENCIES_REQUEST });
const getProposalInconsistenciesSuccess = data => ({
  type: GET_PROPOSAL_INCONSISTENCIES_SUCCESS,
  payload: data,
});
const getProposalInconsistenciesFailure = () => ({ type: GET_PROPOSAL_INCONSISTENCIES_FAILURE });

const getProposalAssetRanges = () => ({ type: GET_PROPOSAL_ASSET_RANGES_REQUEST });
const getProposalAssetRangesSuccess = data => ({
  type: GET_PROPOSAL_ASSET_RANGES_SUCCESS,
  payload: data,
});
const getProposalAssetRangesFailure = () => ({ type: GET_PROPOSAL_ASSET_RANGES_FAILURE });

const updateProposalModels = models => ({ type: UPDATE_PROPOSAL_MODELS, payload: models });

export const setProposalActiveRtq = rtqId => ({ type: SET_PROPOSAL_ACTIVE_RTQ, payload: rtqId });

export const autoCompleteProposalAllocationModel = data => ({
  type: AUTO_COMPLETE_PROPOSAL_ALLOCATION_MODEL,
  payload: data,
});
export const addProposalAccountSecurity = data => ({
  type: ADD_PROPOSAL_ACCOUNT_SECURITY,
  payload: data,
});
export const updateProposalAccountSecuritySelection = data => ({
  type: UPDATE_PROPOSAL_ACCOUNT_SECURITY_SELECTION,
  payload: data,
});
export const updateProposalAccountSecurityAllocation = data => ({
  type: UPDATE_PROPOSAL_ACCOUNT_SECURITY_ALLOCATION,
  payload: data,
});
export const removeProposalAccountSecurity = data => ({
  type: REMOVE_PROPOSAL_ACCOUNT_SECURITY,
  payload: data,
});

export const setDefaultInvestmentStrategy = strategy => ({
  type: SET_DEFAULT_INVESTMENT_STRATEGY,
  payload: strategy,
});
export const addModelToProposal = () => ({ type: ADD_MODEL_TO_PROPOSAL });
export const removeModelFromProposal = modelId => ({
  type: REMOVE_MODEL_FROM_PROPOSAL,
  payload: modelId,
});
export const resetProposalModelAccountsSetup = () => ({
  type: RESET_PROPOSAL_MODEL_ACCOUNTS_SETUP,
});

export const loadSummary = householdId => async dispatch => {
  try {
    dispatch(getProposalSummary());

    const data = await loadProposalSummary(householdId);
    if (data) {
      dispatch(getProposalSummarySuccess(data));
    } else {
      dispatch(getProposalSummaryFailure());
    }
  } catch (e) {
    dispatch(getProposalSummaryFailure());
    errorHandler(dispatch, 'Loading proposal summary failed')(e);
  }
};

export const loadOwners = () => async (dispatch, getState) => {
  try {
    dispatch(getProposalOwners());

    const { rtqId } = getState().proposalInfo;
    const data = await loadProposalOwners(rtqId);
    if (data) {
      dispatch(getProposalOwnersSuccess(data));
    } else {
      dispatch(getProposalOwnersFailure());
    }
  } catch (e) {
    dispatch(getProposalOwnersFailure());
    errorHandler(dispatch, 'Loading owner information failed')(e);
  }
};

export const loadAccounts = () => async (dispatch, getState) => {
  try {
    dispatch(getProposalAccounts());

    const { rtqId } = getState().proposalInfo;
    const data = await loadProposalAccounts(rtqId);
    if (data) {
      dispatch(getProposalAccountsSuccess(data));
    } else {
      dispatch(getProposalAccountsFailure());
    }
  } catch (e) {
    dispatch(getProposalAccountsFailure());
    errorHandler(dispatch, 'Loading account information failed')(e);
  }
};

export const loadMandate = () => async (dispatch, getState) => {
  try {
    dispatch(getProposalMandate());

    const { rtqId } = getState().proposalInfo;
    const data = await loadProposalMandate(rtqId);
    if (data) {
      dispatch(getProposalMandateSuccess(data));
    } else {
      dispatch(getProposalMandateFailure());
    }
  } catch (e) {
    dispatch(getProposalMandateFailure());
    errorHandler(dispatch, 'Loading mandate details failed')(e);
  }
};

export const loadInconsistencies = () => async (dispatch, getState) => {
  try {
    dispatch(getProposalInconsistencies());

    const { rtqId } = getState().proposalInfo;
    const data = await loadProposalInconsistencies(rtqId);
    if (data) {
      dispatch(getProposalInconsistenciesSuccess(data));
    } else {
      dispatch(getProposalInconsistenciesFailure());
    }
  } catch (e) {
    dispatch(getProposalInconsistenciesFailure());
    errorHandler(dispatch, 'Loading inconsistencies failed')(e);
  }
};

export const loadAssetRanges = () => async (dispatch, getState) => {
  try {
    dispatch(getProposalAssetRanges());

    const { rtqId } = getState().proposalInfo;
    const data = await loadProposalAssetRanges(rtqId);
    if (data) {
      dispatch(getProposalAssetRangesSuccess(data));
    } else {
      dispatch(getProposalAssetRangesFailure());
    }
  } catch (e) {
    dispatch(getProposalAssetRangesFailure());
    errorHandler(dispatch, 'Loading asset ranges failed')(e);
  }
};

export const performAccountToModelMapping =
  (source, destination, account) => async (dispatch, getState) => {
    const isAccount = 'accounts';
    const isModel = 'model';

    const { accounts, models } = getState().proposalInfo;

    const sourceArray = source.split('|');
    const destinationArray = destination.split('|');
    const accountArray = account.split('|');

    if (sourceArray?.length && destinationArray?.length && accountArray?.length === 2) {
      // get Account record
      const accountNumber = accountArray[1];
      const targetAccount = accounts.find(a => a.accountNumber === accountNumber);
      const isSmallAccount = (targetAccount?.value ?? 0) < SMALL_ACCOUNT_THRESHOLD;

      if (
        sourceArray[0] === isAccount &&
        destinationArray[0] === isModel &&
        destinationArray.length === 2 &&
        targetAccount
      ) {
        // if source isAccount and destination isModel
        const modelRecordId = destinationArray[1];

        const updatedModels = models.map(m => {
          if (modelRecordId === m.id && (!isSmallAccount || m.smallAccountModel)) {
            return {
              ...m,
              accounts: [...m.accounts, targetAccount],
            };
          } else {
            return {
              ...m,
            };
          }
        });

        dispatch(updateProposalModels(updatedModels));
      } else if (sourceArray[0] === isModel && destinationArray[0] === isModel) {
        // if source isModel and destination isModel
        const sourceModelRecordId = sourceArray[1];
        const destinationModelRecordId = destinationArray[1];

        // move account from source model to destination model
        const updatedModels = models.map(m => {
          if (sourceModelRecordId === m.id) {
            return {
              ...m,
              accounts: m.accounts.filter(a => a.accountNumber !== accountNumber),
            };
          } else if (
            destinationModelRecordId === m.id &&
            (!isSmallAccount || m.smallAccountModel)
          ) {
            return {
              ...m,
              accounts: [...m.accounts, targetAccount],
            };
          } else {
            return {
              ...m,
            };
          }
        });

        dispatch(updateProposalModels(updatedModels));
      } else if (sourceArray[0] === isModel && destinationArray[0] === isAccount) {
        // if source isModel and destination isAccount
        const sourceModelRecordId = sourceArray[1];

        // then remove the assignment from model result.source.droppableId = "model|5"
        const updatedModels = models.map(m => {
          if (sourceModelRecordId === m.id) {
            return {
              ...m,
              accounts: m.accounts.filter(a => a.accountNumber !== accountNumber),
            };
          } else {
            return {
              ...m,
            };
          }
        });

        dispatch(updateProposalModels(updatedModels));
      }
    }
  };

export const updateProposalModel = (model: ModelRecord) => async (dispatch, getState) => {
  const { models } = getState().proposalInfo;

  const updatedModels = models.map(m => {
    if (m.id === model.id) {
      return {
        ...m,
        ...model,
      };
    } else {
      return m;
    }
  });

  dispatch(updateProposalModels(updatedModels));
};

const getModelsWithWeights = (models, accounts) => {
  const accountsTotal = accounts.reduce((n, { value }) => n + value, 0);

  return models.map(m => {
    const modelAccountsTotal = m.accounts.reduce((n, { value }) => n + value, 0);

    const accountArray = m.accounts.map(a => a.accountNumber);

    return {
      modelID: m.modelId,
      accounts: accountArray,
      weight: accountsTotal ? modelAccountsTotal / accountsTotal : 0,
    };
  });
};

export const saveProposalModelsSetup = () => async (dispatch, getState) => {
  try {
    const { accounts, models, rtqId } = getState().proposalInfo;

    const modelsWithWeights = getModelsWithWeights(models, accounts);

    await saveProposalModels(rtqId, modelsWithWeights);

    // on save if successful reset any potential allocations based on old model
    let accountRecords: AccountSecurityParam[] = [];
    models.forEach(m => {
      const accounts = m.accounts.map(a => {
        return {
          accountNumber: a.accountNumber,
          securities: [],
        };
      });

      accountRecords = [...accountRecords, ...accounts];
    });

    await saveProposalAllocations(accountRecords);

    dispatch(loadAccounts());
  } catch (e) {
    errorHandler(dispatch, 'Failed to save account to model mapping')(e);
  }
};

export const saveProposalAllocationsSetup = modelModified => async (dispatch, getState) => {
  try {
    const { models, accounts, rtqId } = getState().proposalInfo;

    let accountRecords: AccountSecurityParam[] = [];

    models.forEach(m => {
      const accounts = m.accounts.map(a => {
        const securities = a.securities.map(s => {
          return {
            ticker: s.ticker,
            weight: s.allocatedPercent,
          };
        });

        return {
          accountNumber: a.accountNumber,
          securities,
        };
      });

      accountRecords = [...accountRecords, ...accounts];
    });

    // If models need save first
    if (modelModified) {
      const modelsWithWeights = getModelsWithWeights(models, accounts);
      await saveProposalModels(rtqId, modelsWithWeights);
    }

    await saveProposalAllocations(accountRecords);

    dispatch(loadAccounts());
  } catch (e) {
    errorHandler(dispatch, 'Failed to save allocations')(e);
  }
};

export const autoCompleteProposalAllocation = (partnerID: number) => async (dispatch, getState) => {
  // For each model fetch securities and dispatch autoCompleteProposalAllocationModel
  const { models } = getState().proposalInfo;
  const { financialModels } = getState().financialModels;

  models.forEach(model => {
    if (partnerID && model.strategy && model.assetAllocation) {
      const modelIdentifier = partnerID + model.strategy + model.assetAllocation;

      if (financialModels[modelIdentifier]?.length) {
        const fModels = financialModels[modelIdentifier].filter(f => f.modelId === model.modelId);
        if (fModels.length) {
          dispatch(
            autoCompleteProposalAllocationModel({
              securities: fModels[0].securities,
              modelId: model.modelId,
            }),
          );
        }
      }
    }
  });
};
