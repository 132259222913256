import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';

const styles = StyleSheet.create({
  MainText1: {
    fontSize: 14,
    color: COLORS.PRIMARY_LIGHT,
    padding: 20,
    textAlign: 'center',
    fontWeight: 'medium',
    lineHeight: '110%',
  },
  SubText1: {
    fontSize: 14,
    marginLeft: 100,
    marginTop: 10,
    color: COLORS.PRIMARY_LIGHT,
  },
  Description: {
    paddingHorizontal: 30,
    fontSize: 12,
    textAlign: 'center',
    marginTop: 20,
  },
  MainText2: {
    fontSize: 20,
    color: COLORS.PRIMARY_LIGHT,
    paddingVertical: 8,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  SubText2: {
    fontSize: 10,
    paddingVertical: 8,
    textAlign: 'center',
  },
  HighLight1: {
    color: COLORS.PRIMARY_LIGHT,
    fontWeight: 'bold',
  },
  Content: {
    diplay: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    marginVertical: 20,
    paddingHorizontal: 10,
  },
  BannerImage: {
    height: 200,
  },
  TextPanel: {
    display: 'flex',
    justifyContent: 'center',
  },
  Row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 32,
  },
  NumberColumn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 32,
    color: COLORS.WHITE,
    fontWeight: 'bold',
  },
  TextColumn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    height: 32,
    paddingHorizontal: 15,
    fontSize: 12,
    fontWeight: 'bold',
  },
  BoldFont: {
    fontWeight: 'bold',
  },
});

const list = [
  {
    text: 'Collate your RTQs, your QBias results, and your QScore for expedient analysis',
  },
  {
    text: 'Cross-check for inconsistencies between these various data points',
  },
  {
    text: 'Determine the appropriate investment strategy for each of your RTQs',
  },
  {
    text: 'Present the holistic solution for your acceptance',
  },
];

type Props = {
  rtqCount: number;
}

const ReportOverview = ({rtqCount} : Props) => (
  <Page size="A4" orientation="landscape" style={commonStyles.Root}>
    <View style={commonStyles.Header} fixed>
      <Text style={commonStyles.HeaderTitle}>Your QFolio</Text>
      <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
    </View>

    <View style={{ paddingHorizontal: 20 }}>
      <Text style={[styles.MainText1, { paddingBottom: 0 }]}>
        Your QFolio proposal is designed to ensure that the investment decisions made by your
        Portfolio Manager:
      </Text>
      <View>
        <Text style={styles.SubText1}>• accurately represent you as an investor</Text>
        <Text style={styles.SubText1}>
          • are consistent with behaviors you have displayed in other discovery exercises
        </Text>
        <Text style={styles.SubText1}>
          • will allow you to accomplish your Journey-Based financial plan.
        </Text>
      </View>

      <Text style={styles.Description}>
        {`Your household has ${rtqCount} Risk Tolerance Questionnaires (RTQ). Each RTQ requires a specific
        solution, whose investments have been curated by your Portfolio Manager to ensure they will
        meet the desired outcomes you have articulated to yourWealth Manager.`}
      </Text>

      <View style={styles.Content}>
        <View style={styles.TextPanel}>
          <Text style={[styles.MainText1, styles.BoldFont, { marginTop: -30 }]}>
            The QFolio process is designed to:
          </Text>

          {list.map((rowData, index) => (
            <View style={styles.Row} key={`no-${index}`}>
              <View
                style={[
                  styles.NumberColumn,
                  { backgroundColor: index % 2 === 0 ? COLORS.PRIMARY_LIGHT : COLORS.PRIMARY },
                ]}
              >
                <Text>{index + 1}</Text>
              </View>
              <View
                style={[
                  styles.TextColumn,
                  { backgroundColor: index % 2 === 0 ? COLORS.GRAY1 : COLORS.GRAY2 },
                ]}
              >
                <Text>{rowData.text}</Text>
              </View>
            </View>
          ))}
        </View>
        <Image style={styles.BannerImage} src="/images/qw-qscore-pdf-img1.jpeg" />
      </View>

      <Text style={styles.SubText2}>
        This document is <Text style={styles.HighLight1}>Step Three</Text> in the creation of your{' '}
        <Text style={styles.HighLight1}>Journey Based Plan</Text>. By the end of this document, you
        will have a fundamental understanding of the question:
      </Text>
      <Text style={styles.MainText2}>
        Can my investment portfolio achieve my Journey-Based financial plan?
      </Text>
    </View>
  </Page>
);

export default ReportOverview;
