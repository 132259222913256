import React, { ReactElement, useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Box, styled } from '@qwealth/qcore';
import { toCurrency, toPercent } from '@qwealth/qcore/utils';

import { ProposalInfoSelectors } from 'data/selectors';
import { getLabel } from 'data/translations/defaultLabels';
import { COLORS } from 'theme/colors';

const TotalContainer = styled(Box)`
  background-color: ${COLORS.grayBackground};
`;

type Props = {
  displayPercent: boolean;
};

const ModelSetupTotal: React.FC<Props> = ({displayPercent}): ReactElement => {
  const accounts = useSelector(ProposalInfoSelectors.accounts);
  const models = useSelector(ProposalInfoSelectors.models);

  const totalValue = useMemo(() => {
    return accounts.reduce((n, { value }) => n + value, 0)
  }, [accounts]);

  const [totalValueAssigned, setTotalValueAssigned] = useState(0);
  const [totalValueLeft, setTotalValueLeft] = useState(0);

  // For all accounts that have model assignment
  useEffect(() => {
    if (models) {
      const assignedValue = models?.reduce((n, { accounts: accts }) => {
        const accountsNet = accts?.reduce((n, { value }) => n + value, 0);
        return n + (accountsNet ?? 0);
      }, 0);

      setTotalValueAssigned(assignedValue);
    } else {
      setTotalValueAssigned(0);
    }
  }, [models]);

  useEffect(() => {
    setTotalValueLeft(totalValue - totalValueAssigned);
  }, [totalValue, totalValueAssigned]);

  return (
    <TotalContainer
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="large"
      textStyle="default"
      borderRadius="large"
    >
      <Box color="gray.0" textTransform="uppercase" fontWeight="bolder">
        {getLabel('common.total')}
      </Box>
      <Box color="gray.0" fontSize="sectionTitle" fontWeight="bolder" marginRight="small">
        {displayPercent ? toPercent(totalValueAssigned/totalValue) : toCurrency(totalValueAssigned)}
      </Box>
      <Box color="gray.1" textStyle="default" fontWeight="normal" marginRight="small">
        {displayPercent ? toPercent(totalValueLeft/totalValue) : toCurrency(totalValueLeft)} {getLabel('common.left')}
      </Box>
    </TotalContainer>
  );
};

export default ModelSetupTotal;
