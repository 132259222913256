import React from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import type { ChartConfiguration, ChartData } from 'chart.js';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { useChartJsAsImage } from '../../hooks/useChartJsAsImage';

const styles = StyleSheet.create({
  Title: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    fontWeight: 'bold',
  },
  Description: {
    fontSize: 10,
    marginTop: 20,
    lineHeight: '150%',
  },
  HeaderCell: {
    display: 'flex',
    flexDirection: 'row',
    height: 35,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 10,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    flex: 1,
  },
  ValueCell: {
    fontSize: 10,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    flex: 1,
  },
});

const createChartJsConfig = (
  type: ChartConfiguration['type'],
  labels: ChartData['labels'],
  datasets: ChartData['datasets'],
): ChartConfiguration => ({
  type,
  data: {
    labels,
    datasets,
  },
});

interface Props {
  data?: object;
}

const ReportProprietary = ({ data }: Props) => {
  // @ts-ignore
  const chartData = data && data['F'];
  const values = chartData && Object.values(chartData);
  const datasets = [
    {
      data: values,
      backgroundColor: [
        COLORS.PRIMARY,
        '#c0d8d3',
        '#2371bb',
        'rgb(255, 99, 132)',
        'rgb(54, 162, 235)',
        'rgb(255, 205, 86)',
        '#769343',
        COLORS.PRIMARY_LIGHT,
      ],
    },
  ];

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>Macroeconomic</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={[commonStyles.Content, { paddingHorizontal: 60 }]}>
        <Text style={styles.Title}>Risk Exposure Analysis</Text>
        <Text style={[styles.Description, { marginTop: 3 }]}>By Macroeconomic Factor</Text>
        <Text style={styles.Description}>
          This section shows your portfolio’s risk attribution to seven macroeconomic factors.
        </Text>

        <Text style={[styles.Title, { marginTop: 25, marginBottom: 10, fontSize: 12 }]}>
          Macroeconomic Risk Factor
        </Text>
        <View style={[commonStyles.Row, commonStyles.Width100]}>
          <View
            style={[
              { width: '40%' },
              commonStyles.JustifyContentCenter,
              commonStyles.AlignItemsCenter,
            ]}
          >
            {/* @ts-ignore */}
            {useChartJsAsImage(
              createChartJsConfig(
                'doughnut',
                values ? Object.keys(chartData) : [],
                values ? datasets : [],
              ),
              {
                plugins: {
                  legend: {
                    position: 'bottom',
                    labels: {
                      padding: 40,
                      font: {
                        size: 48,
                      },
                    },
                  },
                },
              },
            )}
          </View>
          <View style={{ flex: 1 }}>
            <View style={[commonStyles.Row, commonStyles.Width100]}>
              <View style={styles.HeaderCell}>
                <Text>ASSET</Text>
              </View>
              <View style={styles.HeaderCell}>
                <Text>MACROECONOMIC RISK EXPOSURE</Text>
              </View>
            </View>

            {chartData &&
              Object.keys(chartData).map(key => (
                <View key={key} style={[commonStyles.Row, commonStyles.Width100]}>
                  <View style={styles.ValueCell}>
                    <Text>{key}</Text>
                  </View>
                  <View style={styles.ValueCell}>
                    <Text>{`${chartData[key].toFixed(2) * 100}%`}</Text>
                  </View>
                </View>
              ))}

            <View style={[commonStyles.Row, commonStyles.Width100, { borderBottom: 'none' }]}>
              <View style={[styles.ValueCell, commonStyles.BoldFont]}>
                <Text>Total</Text>
              </View>
              <View style={[styles.ValueCell, commonStyles.BoldFont]}>
                <Text>100%</Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Page>
  );
};

export default ReportProprietary;
