import React from 'react';
import { Page, View, Image, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';

const styles = StyleSheet.create({
  Root: {
    position: 'relative',
  },
  BackImage: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  MainTitle: {
    fontSize: 32,
    color: COLORS.WHITE,
  },
  SubTitle: {
    fontSize: 18,
    marginTop: 100,
    marginBottom: 15,
    color: COLORS.WHITE,
  },
  ClientName: {
    fontSize: 24,
    color: COLORS.WHITE,
    fontWeight: 700,
  },
});

const ReportCover = ({ primary, secondary }: { primary?: string; secondary?: string }) => {
  return (
    <Page size="A4" orientation="landscape" style={styles.Root}>
      <Image style={styles.BackImage} src="/images/qw-cover.jpg" />
      <View style={styles.wrapper}>
        <Text style={styles.MainTitle}>
          YOUR{'  '}QFOLIO
        </Text>
        <Text style={styles.SubTitle}>PREPARED FOR:</Text>
        {primary && <Text style={styles.ClientName}>{primary}</Text>}
        {secondary && <Text style={styles.ClientName}>{secondary}</Text>}
      </View>
    </Page>
  );
};

export default ReportCover;
