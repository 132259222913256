import { EntitySecurityException } from 'data/types';
import {
  GET_SECURITY_EXCPETION_REQUEST,
  GET_SECURITY_EXCPETION_SUCCESS,
  GET_SECURITY_EXCPETION_FAILURE,
  CREATE_SECURITY_EXCPETION_REQUEST,
  CREATE_SECURITY_EXCPETION_SUCCESS,
  CREATE_SECURITY_EXCPETION_FAILURE,
  UPDATE_SECURITY_EXCPETION_REQUEST,
  UPDATE_SECURITY_EXCPETION_SUCCESS,
  UPDATE_SECURITY_EXCPETION_FAILURE,
  DELETE_SECURITY_EXCPETION_REQUEST,
  DELETE_SECURITY_EXCPETION_SUCCESS,
  DELETE_SECURITY_EXCPETION_FAILURE,
} from '../actions/types';

export interface ISecurityExceptionState {
  securityException?: EntitySecurityException;
  isGettingSecurityException: boolean;
  isGetSecurityExceptionError: boolean;
  isCreatingSecurityException: boolean;
  isCreateSecurityExceptionError: boolean;
  isUpdatingSecurityException: boolean;
  isUpdateSecurityExceptionError: boolean;
  isDeletingSecurityException: boolean;
  isDeleteSecurityExceptionError: boolean;
}

const DEFAULT_STATE: ISecurityExceptionState = {
  securityException: undefined,
  isGettingSecurityException: false,
  isGetSecurityExceptionError: false,
  isCreatingSecurityException: false,
  isCreateSecurityExceptionError: false,
  isUpdatingSecurityException: false,
  isUpdateSecurityExceptionError: false,
  isDeletingSecurityException: false,
  isDeleteSecurityExceptionError: false,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case GET_SECURITY_EXCPETION_REQUEST: {
      return {
        ...state,
        securityException: undefined,
        isGettingSecurityException: true,
        isGetSecurityExceptionError: false,
      };
    }
    case GET_SECURITY_EXCPETION_SUCCESS: {
      return {
        ...state,
        securityException: action.payload,
        isGettingSecurityException: false,
        isGetSecurityExceptionError: false,
      };
    }
    case GET_SECURITY_EXCPETION_FAILURE: {
      return {
        ...state,
        securityException: undefined,
        isGettingSecurityException: false,
        isGetSecurityExceptionError: true,
      };
    }

    case CREATE_SECURITY_EXCPETION_REQUEST: {
      return {
        ...state,
        isCreatingSecurityException: true,
        isCreateSecurityExceptionError: false,
      };
    }
    case CREATE_SECURITY_EXCPETION_SUCCESS: {
      return {
        ...state,
        isCreatingSecurityException: false,
        isCreateSecurityExceptionError: false,
      };
    }
    case CREATE_SECURITY_EXCPETION_FAILURE: {
      return {
        ...state,
        isCreatingSecurityException: false,
        isCreateSecurityExceptionError: true,
      };
    }

    case UPDATE_SECURITY_EXCPETION_REQUEST: {
      return {
        ...state,
        isUpdatingSecurityException: true,
        isUpdateSecurityExceptionError: false,
      };
    }
    case UPDATE_SECURITY_EXCPETION_SUCCESS: {
      return {
        ...state,
        isUpdatingSecurityException: false,
        isUpdateSecurityExceptionError: false,
      };
    }
    case UPDATE_SECURITY_EXCPETION_FAILURE: {
      return {
        ...state,
        isUpdatingSecurityException: false,
        isUpdateSecurityExceptionError: true,
      };
    }

    case DELETE_SECURITY_EXCPETION_REQUEST: {
      return {
        ...state,
        isDeletingSecurityException: true,
        isDeleteSecurityExceptionError: false,
      };
    }
    case DELETE_SECURITY_EXCPETION_SUCCESS: {
      return {
        ...state,
        isDeletingSecurityException: false,
        isDeleteSecurityExceptionError: false,
      };
    }
    case DELETE_SECURITY_EXCPETION_FAILURE: {
      return {
        ...state,
        isDeletingSecurityException: false,
        isDeleteSecurityExceptionError: true,
      };
    }

    default:
      return state;
  }
}
