import React, { ReactNode } from 'react';
import { styled } from '@qwealth/qcore';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const Container = styled.div`
  table {
    tbody {
      display: block !important;
      margin-top: 0 !important;
      overflow: auto !important;

      tr {
        background-color: white !important;
        margin-bottom: 10px !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        color: ${COLORS.gray} !important;
        border-radius: 10px !important;
        display: table !important;
        width: 100% !important;
        table-layout: fixed !important;
      }
    }

    thead {
      display: table !important;
      table-layout: fixed !important;
      background-color: ${COLORS.primaryBackgroundLight} !important;
      color: ${COLORS.primary} !important;
      margin-bottom: 10px !important;
      width: 100% !important;
      border-radius: 10px !important;
      font-size: 14px !important;
    }

    th {
      color: ${COLORS.primary} !important;
      padding: 1rem 1rem !important;
      font-size: 14px !important;
    }

    td {
      padding: 1rem !important;
    }
  }
`;

const LightContainer = styled(Container)`
  table {
    tbody {
      tr {
        margin-bottom: 5px !important;
        border-radius: 3px !important;
      }
      tr:not(:last-child) {
        border-bottom: 0px solid !important;
      }
      tr:nth-child(odd) {
        background-color: ${COLORS.background} !important;
      }
    }

    td {
      padding: 0.5rem 1rem !important;
    }
    td:last-child {
      padding: 0rem !important;
    }
  }
`;

type Props = {
  light?: boolean;
  children: ReactNode;
};

const StyledTableContainer: React.FC<Props> = ({ children, light }) => {
  const Wrapper = light ? LightContainer : Container;

  return <Wrapper>{children}</Wrapper>;
};

export default StyledTableContainer;
