import React, { useMemo } from 'react';
import { Page, View, Text, StyleSheet } from '@react-pdf/renderer';

import { COLORS } from '../../constant';
import commonStyles from '../../commonStyles';
import { ScoreTuple } from '../interfaces/AggregatedAnalyticsData';
import { getScoreValueLabel } from '../utils';

const styles = StyleSheet.create({
  Title: {
    fontSize: 18,
    color: COLORS.PRIMARY_LIGHT,
    fontWeight: 'bold',
  },
  Description: {
    fontSize: 10,
    marginTop: 20,
    lineHeight: '150%',
  },
  HeaderCell: {
    display: 'flex',
    height: 35,
    backgroundColor: COLORS.PRIMARY_LIGHT,
    color: COLORS.WHITE,
    fontSize: 8,
    borderRightWidth: 1,
    borderRightColor: COLORS.WHITE,
    justifyContent: 'center',
    alignItems: 'flex-end',
    fontWeight: 'bold',
    flex: 1,
    paddingHorizontal: 5,
  },
  ValueCell: {
    fontSize: 8,
    borderBottomWidth: 2,
    borderBottomColor: COLORS.GRAY2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: 30,
    flex: 1,
    paddingHorizontal: 5,
  },
});

interface Props {
  score?: ScoreTuple;
  fratios?: object;
  crisisRets?: object;
}

const ReportEquityStats = ({ score, fratios, crisisRets }: Props) => {
  const { scoreValue, scoreLabel } = useMemo(() => getScoreValueLabel(score), [score]);

  return (
    <Page size="A4" orientation="landscape" style={commonStyles.Root}>
      <View style={commonStyles.Header} fixed>
        <Text style={commonStyles.HeaderTitle}>Performance</Text>
        <Text style={commonStyles.PageNumber} render={({ pageNumber, totalPages }) => pageNumber} />
      </View>

      <View style={[commonStyles.Content, { paddingHorizontal: 60 }]}>
        <Text style={styles.Title}>
          {`Performance Snapshot: ${scoreValue} `}
          <Text style={commonStyles.Score}>{scoreLabel}</Text>
        </Text>
        <Text style={styles.Description}>
          The information below provides a snapshot of how well your portfolio is doing. You will
          find the traditional measures, such as annualized returns for different investment
          horizons. Most importantly, we show you your portfolio’s risk adjusted ratios, which tell
          you how much risk was taken to generate the returns.
        </Text>
        <Text style={styles.Description}>
          As long as you invest in securities that involve some level of risk - however high or low
          – the risk adjusted ratios are more appropriate measures of performance, because the
          returns are not guaranteed. Needless to say, the higher the ratios, especially relative to
          your portfolio's benchmark, the better.
        </Text>

        {fratios && crisisRets && (
          <View style={[commonStyles.Row, commonStyles.Width100, { marginTop: 20 }]}>
            <View style={{ flex: 1 }}>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={[styles.HeaderCell, { flex: 2, alignItems: 'flex-start' }]}>
                  <Text>Key Statistics</Text>
                </View>
                <View style={styles.HeaderCell}>
                  <Text>Portfolio</Text>
                </View>
                <View style={styles.HeaderCell}>
                  <Text>Benchmark</Text>
                </View>
              </View>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={[styles.ValueCell, { flex: 2, justifyContent: 'flex-start' }]}>
                  <Text>Annualized Return</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.portfolio.annualizedRet * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.global.annualizedRet * 100).toFixed(2)}%</Text>
                </View>
              </View>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={[styles.ValueCell, { flex: 2, justifyContent: 'flex-start' }]}>
                  <Text>Sharpe Ratio</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.portfolio.sharpe * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.global.sharpe * 100).toFixed(2)}%</Text>
                </View>
              </View>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={[styles.ValueCell, { flex: 2, justifyContent: 'flex-start' }]}>
                  <Text>Roy's Safety First Criterion</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.portfolio.roys * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.global.roys * 100).toFixed(2)}%</Text>
                </View>
              </View>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={[styles.ValueCell, { flex: 2, justifyContent: 'flex-start' }]}>
                  <Text>Sortino Ratio</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.portfolio.sortino * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.global.sortino * 100).toFixed(2)}%</Text>
                </View>
              </View>
            </View>

            <View style={{ flex: 1, marginHorizontal: 20 }}>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={[styles.HeaderCell, { flex: 1.5, alignItems: 'flex-start' }]}>
                  <Text>Annualized Returns</Text>
                  <Text style={{ fontWeight: 'semibold' }}>% of Stocks</Text>
                </View>
                <View style={styles.HeaderCell}>
                  <Text>Portfolio</Text>
                  <Text style={{ fontWeight: 'semibold' }}>2022-08</Text>
                </View>
                <View style={styles.HeaderCell}>
                  <Text>Benchmark</Text>
                  <Text style={{ fontWeight: 'semibold' }}>2022-08</Text>
                </View>
              </View>

              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={[styles.ValueCell, { flex: 2, justifyContent: 'flex-start' }]}>
                  <Text>YTD</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.portfolio.retYTD * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.global.retYTD * 100).toFixed(2)}%</Text>
                </View>
              </View>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={[styles.ValueCell, { flex: 2, justifyContent: 'flex-start' }]}>
                  <Text>1yr</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.portfolio.ret1 * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.global.ret1 * 100).toFixed(2)}%</Text>
                </View>
              </View>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={[styles.ValueCell, { flex: 2, justifyContent: 'flex-start' }]}>
                  <Text>3yr</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.portfolio.ret3 * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.global.ret3 * 100).toFixed(2)}%</Text>
                </View>
              </View>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={[styles.ValueCell, { flex: 2, justifyContent: 'flex-start' }]}>
                  <Text>5yr</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.portfolio.ret5 * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.global.ret5 * 100).toFixed(2)}%</Text>
                </View>
              </View>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={[styles.ValueCell, { flex: 2, justifyContent: 'flex-start' }]}>
                  <Text>7yr</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.portfolio.ret7 * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.global.ret7 * 100).toFixed(2)}%</Text>
                </View>
              </View>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View style={[styles.ValueCell, { flex: 2, justifyContent: 'flex-start' }]}>
                  <Text>10yr</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.portfolio.ret10 * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(fratios?.global.ret10 * 100).toFixed(2)}%</Text>
                </View>
              </View>
            </View>

            <View style={{ flex: 1, marginHorizontal: 20 }}>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View
                  style={[
                    styles.HeaderCell,
                    { flexDirection: 'column', flex: 1.5, alignItems: 'flex-start' },
                  ]}
                >
                  <Text>Crisis-Period Returns</Text>
                  <Text style={{ fontWeight: 'semibold' }}>% of Stocks</Text>
                </View>
                <View style={styles.HeaderCell}>
                  <Text>Portfolio</Text>
                  <Text style={{ fontWeight: 'semibold' }}>2022-08</Text>
                </View>
                <View style={styles.HeaderCell}>
                  <Text>Benchmark</Text>
                  <Text style={{ fontWeight: 'semibold' }}>2022-08</Text>
                </View>
              </View>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View
                  style={[
                    styles.ValueCell,
                    {
                      flexDirection: 'column',
                      flex: 2,
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                    },
                  ]}
                >
                  <Text>Covid-19 Pandemic</Text>
                  <Text>(2020-02 to 2021-06)</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(crisisRets['Covid-19 Pandemic'].rets.portfolio * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  <Text>
                    {/* @ts-ignore */}
                    {(crisisRets['Covid-19 Pandemic'].rets.benchmark.global * 100).toFixed(2)}%
                  </Text>
                </View>
              </View>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View
                  style={[
                    styles.ValueCell,
                    {
                      flexDirection: 'column',
                      flex: 2,
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                    },
                  ]}
                >
                  <Text>Oil Price Shock</Text>
                  <Text>(2014-06 to 2016-01)</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(crisisRets['Oil Price Shock'].rets.portfolio * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  <Text>
                    {/* @ts-ignore */}
                    {(crisisRets['Oil Price Shock'].rets.benchmark.global * 100).toFixed(2)}%
                  </Text>
                </View>
              </View>
              <View style={[commonStyles.Row, commonStyles.Width100]}>
                <View
                  style={[
                    styles.ValueCell,
                    {
                      flexDirection: 'column',
                      flex: 2,
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                    },
                  ]}
                >
                  <Text>Financial Crisis</Text>
                  <Text>(2007-12 to 2009-05)</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  {/* @ts-ignore */}
                  <Text>{(crisisRets['Financial Crisis'].rets.portfolio * 100).toFixed(2)}%</Text>
                </View>
                <View style={[styles.ValueCell, { justifyContent: 'flex-end' }]}>
                  <Text>
                    {/* @ts-ignore */}
                    {(crisisRets['Financial Crisis'].rets.benchmark.global * 100).toFixed(2)}%
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}
      </View>
    </Page>
  );
};

export default ReportEquityStats;
