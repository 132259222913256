import { v4 as uuidv4 } from 'uuid';
import { PUT_NOTIFICATION, DISMISS_NOTIFICATION} from '../types';

export function putNotification(text, level, notificationUuid) {
  return {
    type: PUT_NOTIFICATION,
    notificationUuid: notificationUuid || uuidv4(),
    timeStamp: new Date().toLocaleString(),
    text,
    level,
  };
}

export function dismissNotification(notificationUuid) {
  return { type: DISMISS_NOTIFICATION, notificationUuid };
}
