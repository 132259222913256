import { v4 as uuidv4 } from 'uuid';

import {
  SET_PROPOSAL_ACTIVE_RTQ,
  GET_PROPOSAL_SUMMARY_REQUEST,
  GET_PROPOSAL_SUMMARY_SUCCESS,
  GET_PROPOSAL_SUMMARY_FAILURE,
  GET_PROPOSAL_OWNERS_REQUEST,
  GET_PROPOSAL_OWNERS_SUCCESS,
  GET_PROPOSAL_OWNERS_FAILURE,
  GET_PROPOSAL_ACCOUNTS_REQUEST,
  GET_PROPOSAL_ACCOUNTS_SUCCESS,
  GET_PROPOSAL_ACCOUNTS_FAILURE,
  GET_PROPOSAL_MANDATE_REQUEST,
  GET_PROPOSAL_MANDATE_SUCCESS,
  GET_PROPOSAL_MANDATE_FAILURE,
  GET_PROPOSAL_INCONSISTENCIES_REQUEST,
  GET_PROPOSAL_INCONSISTENCIES_SUCCESS,
  GET_PROPOSAL_INCONSISTENCIES_FAILURE,
  GET_PROPOSAL_ASSET_RANGES_REQUEST,
  GET_PROPOSAL_ASSET_RANGES_SUCCESS,
  GET_PROPOSAL_ASSET_RANGES_FAILURE,
  SET_DEFAULT_INVESTMENT_STRATEGY,
  ADD_MODEL_TO_PROPOSAL,
  REMOVE_MODEL_FROM_PROPOSAL,
  RESET_PROPOSAL_MODEL_ACCOUNTS_SETUP,
  UPDATE_PROPOSAL_MODELS,
  AUTO_COMPLETE_PROPOSAL_ALLOCATION_MODEL,
  ADD_PROPOSAL_ACCOUNT_SECURITY,
  UPDATE_PROPOSAL_ACCOUNT_SECURITY_SELECTION,
  UPDATE_PROPOSAL_ACCOUNT_SECURITY_ALLOCATION,
  REMOVE_PROPOSAL_ACCOUNT_SECURITY,
} from '../actions/types';

import {
  ProposalSummary,
  ProposalOwner,
  ProposalAccount,
  ProposalMandate,
  ProposalAssetRange,
  ModelRecord,
  AccountSecurityRecord,
  EntityInconsistencies,
} from '../types';

import {
  getDefaultInvestmentStrategy,
  mapModelsFromAccounts,
  mapSummaryData,
  mapAssetRanges,
  mapAccounts,
  mapMandateInformation,
} from '../mappers';
import { SMALL_ACCOUNT_THRESHOLD } from '../refData';

export interface IProposalInfoState {
  rtqId: number;
  isGettingSummary: boolean;
  summary: ProposalSummary | undefined;
  isSummaryError: boolean;

  isGettingOwners: boolean;
  owners: ProposalOwner[];
  isOwnersError: boolean;

  isGettingAccounts: boolean;
  accounts: ProposalAccount[];
  isAccountsError: boolean;

  isGettingMandate: boolean;
  mandate: ProposalMandate | undefined;
  isMandateError: boolean;

  isGettingInconsistencies: boolean;
  inconsistencies: EntityInconsistencies | undefined;
  isInconsistenciesError: boolean;

  isGettingAssetRanges: boolean;
  assetRanges: ProposalAssetRange[];
  isAssetRangesError: boolean;

  models: ModelRecord[];
  defaultInvestmentStrategy: string | undefined;
}

const DEFAULT_STATE: IProposalInfoState = {
  rtqId: 0,

  isGettingSummary: false,
  summary: undefined,
  isSummaryError: false,

  isGettingOwners: false,
  owners: [],
  isOwnersError: false,

  isGettingAccounts: false,
  accounts: [],
  models: [],
  isAccountsError: false,

  isGettingMandate: false,
  mandate: undefined,
  isMandateError: false,

  isGettingInconsistencies: false,
  inconsistencies: undefined,
  isInconsistenciesError: false,

  isGettingAssetRanges: false,
  assetRanges: [],
  isAssetRangesError: false,
  defaultInvestmentStrategy: undefined,
};

export default function (state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_PROPOSAL_ACTIVE_RTQ: {
      return {
        ...state,
        rtqId: action.payload,
      };
    }
    case GET_PROPOSAL_SUMMARY_REQUEST: {
      return {
        ...state,
        ...DEFAULT_STATE,
      };
    }
    case GET_PROPOSAL_SUMMARY_SUCCESS: {
      return {
        ...state,
        isGettingSummary: false,
        summary: mapSummaryData(action.payload),
        isSummaryError: false,
      };
    }
    case GET_PROPOSAL_SUMMARY_FAILURE: {
      return {
        ...state,
        isGettingSummary: false,
        summary: undefined,
        isSummaryError: true,
      };
    }

    case GET_PROPOSAL_OWNERS_REQUEST: {
      return {
        ...state,
        isGettingOwners: true,
        owners: [],
        isOwnersError: false,
      };
    }
    case GET_PROPOSAL_OWNERS_SUCCESS: {
      return {
        ...state,
        isGettingOwners: false,
        owners: action.payload,
        isOwnersError: false,
      };
    }
    case GET_PROPOSAL_OWNERS_FAILURE: {
      return {
        ...state,
        isGettingOwners: false,
        owners: [],
        isOwnersError: true,
      };
    }

    case GET_PROPOSAL_ACCOUNTS_REQUEST: {
      return {
        ...state,
        isGettingAccounts: true,
        accounts: [],
        isAccountsError: false,
      };
    }
    case GET_PROPOSAL_ACCOUNTS_SUCCESS: {
      const accounts = mapAccounts(action.payload) ?? [];

      return {
        ...state,
        isGettingAccounts: false,
        accounts,
        models: mapModelsFromAccounts(action.payload),
        defaultInvestmentStrategy: getDefaultInvestmentStrategy(action.payload),
        isAccountsError: false,
      };
    }
    case GET_PROPOSAL_ACCOUNTS_FAILURE: {
      return {
        ...state,
        isGettingAccounts: false,
        accounts: [],
        models: [],
        defaultInvestmentStrategy: getDefaultInvestmentStrategy([]),
        isAccountsError: true,
      };
    }

    case GET_PROPOSAL_MANDATE_REQUEST: {
      return {
        ...state,
        isGettingMandate: true,
        mandate: undefined,
        isMandateError: false,
      };
    }
    case GET_PROPOSAL_MANDATE_SUCCESS: {
      return {
        ...state,
        isGettingMandate: false,
        mandate: mapMandateInformation(action.payload),
        isMandateError: false,
      };
    }
    case GET_PROPOSAL_MANDATE_FAILURE: {
      return {
        ...state,
        isGettingMandate: false,
        mandate: undefined,
        isMandateError: true,
      };
    }

    case GET_PROPOSAL_INCONSISTENCIES_REQUEST: {
      return {
        ...state,
        isGettingInconsistencies: true,
        inconsistencies: undefined,
        isInconsistenciesError: false,
      };
    }
    case GET_PROPOSAL_INCONSISTENCIES_SUCCESS: {
      return {
        ...state,
        isGettingInconsistencies: false,
        inconsistencies: action.payload,
        isInconsistenciesError: false,
      };
    }
    case GET_PROPOSAL_INCONSISTENCIES_FAILURE: {
      return {
        ...state,
        isGettingInconsistencies: false,
        inconsistencies: undefined,
        isInconsistenciesError: true,
      };
    }

    case GET_PROPOSAL_ASSET_RANGES_REQUEST: {
      return {
        ...state,
        isGettingAssetRanges: true,
        assetRanges: [],
        isAssetRangesError: false,
      };
    }
    case GET_PROPOSAL_ASSET_RANGES_SUCCESS: {
      return {
        ...state,
        isGettingAssetRanges: false,
        assetRanges: mapAssetRanges(action.payload),
        isAssetRangesError: false,
      };
    }
    case GET_PROPOSAL_ASSET_RANGES_FAILURE: {
      return {
        ...state,
        isGettingAssetRanges: false,
        assetRanges: [],
        isAssetRangesError: true,
      };
    }

    case SET_DEFAULT_INVESTMENT_STRATEGY: {
      const smallAccounts = state.accounts.filter(
        account => account.value < SMALL_ACCOUNT_THRESHOLD,
      );
      const models: ModelRecord[] = [];
      if (smallAccounts.length > 0) {
        // @ts-ignore
        models.push({
          id: uuidv4(),
          strategy: action.payload,
          assetAllocation: state.mandate?.mandate,
          smallAccountModel: true,
          accounts: smallAccounts,
        });
        const smallAccountNumbers = smallAccounts.map(a => a.accountNumber);
        // @ts-ignore
        models.push({
          id: uuidv4(),
          strategy: action.payload,
          assetAllocation: state.mandate?.mandate,
          accounts: state.accounts.filter(
            account => !smallAccountNumbers.includes(account.accountNumber),
          ),
        });
      } else {
        // @ts-ignore
        models.push({
          id: uuidv4(),
          strategy: action.payload,
          assetAllocation: state.mandate?.mandate,
          accounts: state.accounts,
        });
      }

      return {
        ...state,
        defaultInvestmentStrategy: action.payload,
        models,
      };
    }
    case ADD_MODEL_TO_PROPOSAL: {
      return {
        ...state,
        models: [
          ...state.models,
          { id: uuidv4(), strategy: state.defaultInvestmentStrategy, accounts: [] },
        ],
      };
    }

    case REMOVE_MODEL_FROM_PROPOSAL: {
      const models = state.models.filter(model => model.id !== action.payload);
      let defaultInvestmentStrategy = state.defaultInvestmentStrategy;

      if (models.length === 0) {
        defaultInvestmentStrategy = undefined;
      }

      return {
        ...state,
        models,
        defaultInvestmentStrategy,
      };
    }
    case RESET_PROPOSAL_MODEL_ACCOUNTS_SETUP: {
      return {
        ...state,
        models: state.models.map(model => {
          return { ...model, accounts: [] };
        }),
      };
    }
    case UPDATE_PROPOSAL_MODELS: {
      return {
        ...state,
        models: action.payload,
      };
    }

    case UPDATE_PROPOSAL_ACCOUNT_SECURITY_ALLOCATION: {
      const newModels = state.models.map(model => {
        const newAccounts = model.accounts.map(account => {
          if (account.accountNumber === action.payload?.accountNumber) {
            const newSecurities =
              account.securities?.map(security => {
                if (security.ticker === action.payload?.ticker) {
                  return {
                    ...security,
                    allocatedPercent: action.payload?.allocatedPercent,
                    allocated: action.payload?.allocatedPercent * account.value,
                  };
                } else {
                  return security;
                }
              }) ?? [];

            return {
              ...account,
              securities: [...newSecurities],
            };
          } else {
            return account;
          }
        });

        return {
          ...model,
          accounts: [...newAccounts],
        };
      });

      return {
        ...state,
        models: [...newModels],
      };
    }

    case REMOVE_PROPOSAL_ACCOUNT_SECURITY: {
      const newModels = state.models.map(model => {
        const newAccounts = model.accounts.map(account => {
          if (account.accountNumber === action.payload?.accountNumber) {
            const newSecurities =
              account.securities?.filter(security => security.ticker !== action.payload?.ticker) ??
              [];

            return {
              ...account,
              securities: [...newSecurities],
            };
          } else {
            return account;
          }
        });

        return {
          ...model,
          accounts: [...newAccounts],
        };
      });

      return {
        ...state,
        models: [...newModels],
      };
    }

    case ADD_PROPOSAL_ACCOUNT_SECURITY: {
      const newModels = state.models.map(model => {
        const newAccounts = model.accounts.map(account => {
          if (account.accountNumber === action.payload?.accountNumber) {
            const newSecurity = { ticker: '', allocated: 0.0, allocatedPercent: 0.0 };
            const newSecurities = account.securities
              ? [...account.securities, newSecurity]
              : [newSecurity];

            return {
              ...account,
              securities: newSecurities,
            };
          } else {
            return account;
          }
        });

        return {
          ...model,
          accounts: [...newAccounts],
        };
      });

      return {
        ...state,
        models: [...newModels],
      };
    }

    case UPDATE_PROPOSAL_ACCOUNT_SECURITY_SELECTION: {
      const newModels = state.models.map(model => {
        const newAccounts = model.accounts.map(account => {
          if (account.accountNumber === action.payload?.accountNumber) {
            const newSecuritiesObj = {};

            account.securities?.forEach(security => {
              let ticker = security.ticker;

              if (security.ticker === action.payload?.ticker) {
                ticker = action.payload?.newTicker;
              }

              const existingSecurity = newSecuritiesObj[ticker];

              newSecuritiesObj[ticker] = existingSecurity
                ? {
                    ...security,
                    ticker,
                    allocated: existingSecurity.allocated + security.allocated,
                    allocatedPercent: existingSecurity.allocatedPercent + security.allocatedPercent,
                  }
                : {
                    ...security,
                    ticker,
                  };
            });

            const newSecurities: AccountSecurityRecord[] = [];
            for (const key in newSecuritiesObj) {
              newSecurities.push(newSecuritiesObj[key]);
            }

            return {
              ...account,
              securities: newSecurities,
            };
          } else {
            return account;
          }
        });

        return {
          ...model,
          accounts: [...newAccounts],
        };
      });

      return {
        ...state,
        models: [...newModels],
      };
    }

    case AUTO_COMPLETE_PROPOSAL_ALLOCATION_MODEL: {
      const newModels = state.models.map(model => {
        if (model.modelId === action.payload?.modelId) {
          const newAccounts = model.accounts.map(account => {
            const newSecurities: AccountSecurityRecord[] = [];

            action.payload.securities?.forEach(security => {
              let ticker = security.ticker;

              if (security.ticker === action.payload?.ticker) {
                ticker = action.payload?.newTicker;
              }

              const remaining = account.value - account.securityAllocated;
              newSecurities.push({
                ...security,
                ticker,
                allocated: security.totalPercent * remaining,
                allocatedPercent: (security.totalPercent * remaining) / (account.value || 1),
              });
            });

            return {
              ...account,
              securities: newSecurities,
            };
          });

          return {
            ...model,
            accounts: [...newAccounts],
          };
        } else {
          return model;
        }
      });

      return {
        ...state,
        models: [...newModels],
      };
    }

    default:
      return state;
  }
}
