import React from 'react';

import { Header } from '@qwealth/qcore';

type Props = {
  children: JSX.Element | JSX.Element[] | string;
}

const HeaderSecondary: React.FC<Props> = ({children}) => {
  return (
    <Header>
      <Header.Title>
        {children && children}
      </Header.Title>
    </Header>
  );
};

export default HeaderSecondary;
