export const SET_PROPOSAL_ACTIVE_RTQ = 'SET_PROPOSAL_ACTIVE_RTQ';

export const GET_PROPOSAL_SUMMARY_REQUEST = 'GET_PROPOSAL_SUMMARY_REQUEST';
export const GET_PROPOSAL_SUMMARY_SUCCESS = 'GET_PROPOSAL_SUMMARY_SUCCESS';
export const GET_PROPOSAL_SUMMARY_FAILURE = 'GET_PROPOSAL_SUMMARY_FAILURE';

export const GET_PROPOSAL_OWNERS_REQUEST = 'GET_PROPOSAL_OWNERS_REQUEST';
export const GET_PROPOSAL_OWNERS_SUCCESS = 'GET_PROPOSAL_OWNERS_SUCCESS';
export const GET_PROPOSAL_OWNERS_FAILURE = 'GET_PROPOSAL_OWNERS_FAILURE';

export const GET_PROPOSAL_ACCOUNTS_REQUEST = 'GET_PROPOSAL_ACCOUNTS_REQUEST';
export const GET_PROPOSAL_ACCOUNTS_SUCCESS = 'GET_PROPOSAL_ACCOUNTS_SUCCESS';
export const GET_PROPOSAL_ACCOUNTS_FAILURE = 'GET_PROPOSAL_ACCOUNTS_FAILURE';

export const GET_PROPOSAL_MANDATE_REQUEST = 'GET_PROPOSAL_MANDATE_REQUEST';
export const GET_PROPOSAL_MANDATE_SUCCESS = 'GET_PROPOSAL_MANDATE_SUCCESS';
export const GET_PROPOSAL_MANDATE_FAILURE = 'GET_PROPOSAL_MANDATE_FAILURE';

export const GET_PROPOSAL_INCONSISTENCIES_REQUEST = 'GET_PROPOSAL_INCONSISTENCIES_REQUEST';
export const GET_PROPOSAL_INCONSISTENCIES_SUCCESS = 'GET_PROPOSAL_INCONSISTENCIES_SUCCESS';
export const GET_PROPOSAL_INCONSISTENCIES_FAILURE = 'GET_PROPOSAL_INCONSISTENCIES_FAILURE';

export const GET_PROPOSAL_ASSET_RANGES_REQUEST = 'GET_PROPOSAL_ASSET_RANGES_REQUEST';
export const GET_PROPOSAL_ASSET_RANGES_SUCCESS = 'GET_PROPOSAL_ASSET_RANGES_SUCCESS';
export const GET_PROPOSAL_ASSET_RANGES_FAILURE = 'GET_PROPOSAL_ASSET_RANGES_FAILURE';

export const AUTO_COMPLETE_PROPOSAL_ALLOCATION_MODEL = 'AUTO_COMPLETE_PROPOSAL_ALLOCATION_MODEL';
export const ADD_PROPOSAL_ACCOUNT_SECURITY = 'ADD_PROPOSAL_ACCOUNT_SECURITY';
export const UPDATE_PROPOSAL_ACCOUNT_SECURITY_SELECTION =
  'UPDATE_PROPOSAL_ACCOUNT_SECURITY_SELECTION';
export const UPDATE_PROPOSAL_ACCOUNT_SECURITY_ALLOCATION =
  'UPDATE_PROPOSAL_ACCOUNT_SECURITY_ALLOCATION';
export const REMOVE_PROPOSAL_ACCOUNT_SECURITY = 'REMOVE_PROPOSAL_ACCOUNT_SECURITY';

export const SET_DEFAULT_INVESTMENT_STRATEGY = 'SET_DEFAULT_INVESTMENT_STRATEGY';
export const ADD_MODEL_TO_PROPOSAL = 'ADD_MODEL_TO_PROPOSAL';
export const REMOVE_MODEL_FROM_PROPOSAL = 'REMOVE_MODEL_FROM_PROPOSAL';
export const RESET_PROPOSAL_MODEL_ACCOUNTS_SETUP = 'RESET_PROPOSAL_MODEL_ACCOUNTS_SETUP';
export const UPDATE_PROPOSAL_MODELS = 'UPDATE_PROPOSAL_MODELS';
