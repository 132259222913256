import React, { ReactElement } from 'react';

import { Box, styled, toCurrency, toPercent } from '@qwealth/qcore';

import { getLabel } from 'data/translations/defaultLabels';
import { COLORS } from 'theme/colors';

/*
 * TODO: REFACTOR
 * EXTEND QCORE TO SUPPORT QFOLIO STYLES
 * WAITING ON A FEW MORE SCCREEN DEVELOPMENT TO INFER QFOLIO COMMON STYLES FOR EFFICIENT REFACTOR
 */
const HeaderContainer = styled(Box)`
  flex: 1;
  background-color: ${COLORS.grayBackground};
  color: ${COLORS.gray};
  margin-bottom: 10px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  padding: 1rem 1rem;
`;

const ValueContainer = styled(HeaderContainer)`
  background-color: ${COLORS.background};
  color: ${COLORS.gray};
  text-align: center;
`;

const RedContainer = styled(ValueContainer)`
  background-color: ${COLORS.redBackground};
  color: ${COLORS.red};
`;

const YellowContainer = styled(ValueContainer)`
  background-color: ${COLORS.primaryBackground};
  color: ${COLORS.primary};
`;

const GreenContainer = styled(HeaderContainer)`
  background-color: ${COLORS.greenBackground};
  text-align: center;
`;

type Props = {
  securityAllocated: number;
  total: number;
  accountValue: number;
  allocated: number;
  displayPercent?: boolean;
};

const AccountItemSummary: React.FC<Props> = ({
  securityAllocated,
  total,
  accountValue,
  allocated,
  displayPercent,
}): ReactElement => {
  const sign = displayPercent ? '%' : '$';
  const allocatedValue = displayPercent
    ? toPercent((allocated + securityAllocated) / total)
    : toCurrency(allocated + securityAllocated);
  const totalValue = displayPercent ? toPercent(accountValue / total) : toCurrency(accountValue);
  const remainingValue = accountValue - (allocated + securityAllocated);
  const remainingPercent =
    (remainingValue / (displayPercent ? accountValue / total : accountValue)) * 100;
  const magnitudeRemainingPercent = Math.abs(remainingPercent);
  const remainingValueFormatted = displayPercent
    ? toPercent(remainingValue / total)
    : toCurrency(remainingValue);

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <HeaderContainer>
          {getLabel('proposal.allocation.allocated.header')} {sign}
        </HeaderContainer>
        <ValueContainer>{allocatedValue}</ValueContainer>
      </Box>

      <Box display="flex" flexDirection="row">
        <HeaderContainer>
          {getLabel('proposal.allocation.remaining.header')} {sign}
        </HeaderContainer>
        {magnitudeRemainingPercent < 0.1 && <GreenContainer>-</GreenContainer>}
        {magnitudeRemainingPercent > 0.1 && magnitudeRemainingPercent <= 1 && (
          <YellowContainer>{remainingValueFormatted}</YellowContainer>
        )}
        {magnitudeRemainingPercent > 1 && <RedContainer>{remainingValueFormatted}</RedContainer>}
      </Box>

      <Box display="flex" flexDirection="row">
        <HeaderContainer>
          {getLabel('proposal.allocation.account.value.header')} {sign}
        </HeaderContainer>
        <ValueContainer>{totalValue}</ValueContainer>
      </Box>
    </Box>
  );
};

export default AccountItemSummary;
